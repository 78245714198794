import React, { useCallback, useEffect, useState } from 'react';

import { Position } from '@blueprintjs/core';
import { CircleFilled, ArrowRight, CheckmarkFilled, InformationFilled, WarningFilled } from '@carbon/icons-react';
import { IconOnlyButton, AdvancedProgressBar, Tag, Button, Intent, HTMLHeading } from '@varicent/components';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';
import DropdownMenuHeading from 'components/DropdownMenu/DropdownMenuHeading';
import MessageTooltip from 'components/MessageTooltip/MessageTooltip';
import Timer from 'components/Timer/Timer';

import { useCoinsort } from 'app/contexts/coinsortProvider';
import { useJobs } from 'app/contexts/jobsProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import useShowToast from 'app/hooks/useShowToast';

import { JobStatus } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import taskSvg from 'assets/svgs/task-optimization.svg';

import style from './StatusMenu.module.pcss';
import { getJobItemDetail, sortJobItems } from './statusMenuUtils';

const b = block(style);

const ArrowRightIcon = () => <ArrowRight size={24} />;

class StatusInfoHelper {
  static getDisplayedInfoBasedOnStatus(item) {
    const battleCardName = item.metadata ? JSON.parse(item.metadata)?.battlecardName : '';
    if (item.status === JobStatus.COMPLETED) {
      return {
        intent: Intent.SUCCESS,
        icon: <CheckmarkFilled />,
        header: formatMessage('STATUS_MENU_COINSORT_COMPLETED', { battleCardName })
      };
    } else if (item.status === JobStatus.FAILED) {
      return {
        intent: Intent.DANGER,
        icon: <WarningFilled />,
        header: formatMessage('STATUS_MENU_COINSORT_FAILED', { battleCardName })
      };
    } else {
      return {
        intent: Intent.PRIMARY,
        icon: <InformationFilled />,
        header: formatMessage('STATUS_MENU_COINSORT_IN_PROGRESS', { battleCardName })
      };
    }
  }
}

const StatusMenu: React.FC = () => {
  const [showHighlightDot, setShowHighlightDot] = useState<boolean>(false);
  const [menuOpen, setMenuOpenRaw] = useState<boolean>(false);

  const { pollingJobs, jobItems } = useJobs();
  const { coinsortRunningLookupMap, updateCoinsortRunningLookupMap, updateCoinsortProgressLookupMap } = useCoinsort();
  const { getTDR } = useTerritoryDefineAndRefine();
  const showToast = useShowToast();

  const setMenuOpen = useCallback((isOpen: boolean) => {
    setMenuOpenRaw(isOpen);
    if (isOpen) setShowHighlightDot(false);
  }, []);

  const handleCoinsortCompleted = (jobStatus, jobMetaData, battleCardId) => {
    if (!jobMetaData) return;

    const jobProperties = JSON.parse(jobMetaData);
    const isSuccess = jobStatus === JobStatus.COMPLETED;
    const battleCardName = jobProperties.battlecardName;

    updateCoinsortRunningLookupMap(battleCardId, false);
    updateCoinsortProgressLookupMap(battleCardId, { coinsortStatus: jobStatus });
    setMenuOpen(false);
    if (!isSuccess) {
      setShowHighlightDot(true);
    }

    // eslint-disable-next-line deprecation/deprecation
    showToast(
      <div data-testid="coinsort-complete-toast">
        <div style={{ paddingBottom: 6 }}>
          <HTMLHeading styleLevel="h6" tagLevel="h2">
            {isSuccess ? formatMessage('COIN_SORTING_COMPLETED') : formatMessage('COIN_SORTING_FAILED')}
          </HTMLHeading>
          <p>
            {isSuccess
              ? formatMessage('COINSORT_COMPLETED_TOAST_MESSAGE', { battleCardName })
              : formatMessage('COINSORT_FAILED_TOAST_MESSAGE', {
                  quotaComponentName: jobProperties.quotaComponentName,
                  battleCardName
                })}
          </p>
          <Button
            style={{ padding: 0 }}
            text={formatMessage('VIEW_STATUS')}
            minimal
            rightIcon={ArrowRightIcon}
            onClick={() => setMenuOpen(true)}
          />
        </div>
      </div>,
      isSuccess ? Intent.SUCCESS : Intent.DANGER,
      Position.TOP_RIGHT
    );

    getTDR(battleCardId, jobProperties.quotaComponentId);
  };

  const checkCoinsortCompleteStatus = (jobItems) => {
    const completedJobs = jobItems.filter((job) => job.status === JobStatus.COMPLETED).map((job) => job.id);
    const failedJobs = jobItems.filter((job) => job.status === JobStatus.FAILED).map((job) => job.id);
    Object.entries(coinsortRunningLookupMap).forEach(([bcId, jobId]) => {
      const curItem = jobItems.find((job) => job.id === jobId);
      if (!curItem) return;

      if (!!jobId && completedJobs.includes(jobId)) {
        return handleCoinsortCompleted(JobStatus.COMPLETED, curItem.metadata, bcId);
      } else if (!!jobId && failedJobs.includes(jobId)) {
        handleCoinsortCompleted(JobStatus.FAILED, curItem.metadata, bcId);
      }
    });
  };

  useEffect(() => {
    if (jobItems.length > 0) {
      // load coinsortRunningLookupMap with running jobs when the map is empty
      if (Object.keys(coinsortRunningLookupMap).length === 0) {
        jobItems
          .filter((job) => job.isActive)
          .forEach((job) => {
            updateCoinsortRunningLookupMap(JSON.parse(job.metadata || null)?.battlecardId.toString(), job.id);
          });
      }

      // when jobItem changes, check the complete status for coinsort jobs
      checkCoinsortCompleteStatus(jobItems);
    }
  }, [jobItems]);

  return (
    <div className={b()} data-testid="status-menu">
      <DropdownMenu
        onOpenChange={(newState) => {
          setShowHighlightDot(false);
          setMenuOpen(newState);
        }}
        open={menuOpen}
        placement="bottom-end"
        maxWidth="large"
        content={
          <>
            <DropdownMenuHeading data-testid="status-menu-heading">
              <div className={b('header')}>{formatMessage('STATUS')}</div>
            </DropdownMenuHeading>
            <DropdownMenuDivider />
            <div className={b('content')}>
              {jobItems.length === 0 ? (
                <div className={b('container')} data-testid="status-menu-empty-task">
                  <div className={b('imageContainer')}>
                    <img src={taskSvg} alt="all-task-completed" />
                  </div>
                  <div className={b('textContainer')}>
                    <HTMLHeading bold tagLevel="h6" text={formatMessage('ALL_DONE')} />
                    {formatMessage('ALL_TASKS_COMPLETED')}
                  </div>
                </div>
              ) : (
                <div className={b('menu')}>
                  {sortJobItems(jobItems).map((item) => {
                    const { intent, header, icon } = StatusInfoHelper.getDisplayedInfoBasedOnStatus(item);
                    const { startTime, endTime, isInProgress, metaData, actorFullName } = getJobItemDetail(item);

                    return (
                      <div key={item.id}>
                        <div className={b('menuItem')}>
                          <div className={b('menuItemHeader')}>
                            <Tag minimal icon={icon} intent={intent} />
                            <HTMLHeading tagLevel={'h6'} text={header} data-testid="item-header" />
                          </div>
                          <div className={b('menuItemContent')}>
                            {isInProgress && (
                              <div className={b('progressBar')}>
                                <AdvancedProgressBar
                                  value={item.progress / 100}
                                  showPercentage
                                  intent={intent}
                                  formatNumber={(progress) => {
                                    return `${Math.round(progress * 100)}%`;
                                  }}
                                />
                              </div>
                            )}

                            <div className={b('timeDescription')} data-testid="status-menu-time-description">
                              {isInProgress && `${formatMessage('STARTED')}`}

                              <div className={b('timer')} data-testid="status-menu-timer">
                                <Timer time={isInProgress ? startTime : endTime} />
                              </div>
                            </div>

                            <div className={b('tags')}>
                              <MessageTooltip
                                content={formatMessage('STARTED_BY', {
                                  userName: actorFullName
                                })}
                                usePortal={false}
                                target={
                                  <div className={b('tag')} data-testid="status-menu-user-tag">
                                    <Tag className={'vds-highlight-10'}>{item.actorEmail}</Tag>
                                  </div>
                                }
                                placement={'top'}
                              />
                              <MessageTooltip
                                content={formatMessage('QUOTA_COMPONENT')}
                                usePortal={false}
                                target={
                                  <div className={b('tag')} data-testid="status-menu-quota-component-tag">
                                    <Tag className={'vds-highlight-03'}>{metaData?.quotaComponentName}</Tag>
                                  </div>
                                }
                                placement={'top'}
                              />

                              <MessageTooltip
                                content={formatMessage('BATTLE_CARD')}
                                usePortal={false}
                                target={
                                  <div className={b('tag')} data-testid="status-menu-battle-card-tag">
                                    <Tag className={'vds-highlight-04'}>{metaData?.battlecardName}</Tag>
                                  </div>
                                }
                                placement={'top'}
                              />
                            </div>
                          </div>
                        </div>
                        <DropdownMenuDivider />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        }
        target={
          pollingJobs ? (
            <Button className={b('progressBarTag')} minimal data-testid="status-menu-in-progress-btn">
              <Tag rightIcon={<CircleFilled size={24} />}>
                {jobItems.filter((job) => job.isActive || job.status === JobStatus.PENDING).length === 1 ? (
                  <AdvancedProgressBar
                    value={jobItems.find((job) => job.isActive).progress / 100}
                    showPercentage
                    intent="primary"
                    formatNumber={(progress) => {
                      return `${Math.round(progress * 100)}%`;
                    }}
                  />
                ) : (
                  <span className={b('multiTaskMsg')}>
                    {formatMessage('TASKS_IN_PROGRESS', { numOfTasks: jobItems.filter((job) => job.isActive).length })}
                  </span>
                )}
              </Tag>
              {showHighlightDot && <div className={b('dot')} />}
            </Button>
          ) : (
            <IconOnlyButton
              className={b('icon')}
              icon={CircleFilled}
              text=""
              minimal
              outlined={true}
              tooltipProps={{ disabled: true }}
              intent={'success'}
              data-testid="status-menu-btn"
            />
          )
        }
      />
    </div>
  );
};

export default StatusMenu;

import { useCallback, useMemo } from 'react';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapVariant } from 'app/contexts/mapVariantProvider';

import { SplitFeatures } from 'app/global/features';

import useCheckCanUser from 'app/hooks/useCheckCanUser';
import useTreatment from 'app/hooks/useTreatment';

import { BattlecardType, CustomerVisualization, MchQuantity } from 'app/models';

import { MapCapability, MapCapabilityRegistry } from 'utils/maps/mapCapabilityRegistry';
import { UserAction } from 'utils/permissions/userActions';

import { useCanSeeAllRulesOnMap } from './useCanSeeAllRulesOnMap';
import { useTerritoryLockingTreatment } from './useTerritoryLockingSplit';

export const useIsMapCapable = (capability: MapCapability): boolean => {
  const checkMapCapable = useCheckMapCapable();
  return useMemo(() => checkMapCapable(capability), [checkMapCapable, capability]);
};

export const useCheckMapCapable = (): ((capability: MapCapability) => boolean) => {
  const permissions = useMapPermissions();
  const situation = useMapSituation();
  const flags = useMapFlags();

  const checkData = useMemo(
    () => ({
      permissions,
      situation,
      flags
    }),
    [permissions, situation, flags]
  );

  return useCallback((capability: MapCapability) => !!MapCapabilityRegistry[capability]?.(checkData), [checkData]);
};

const useMapFlags = () => {
  const [isContributorMapPinsOn] = useTreatment(SplitFeatures.CONTRIBUTOR_VIEW_MAPS_PINS);
  const [isUndoEnabled] = useTreatment(SplitFeatures.MAP_UNDO);
  const [isMapEditCustomHierarchyRulesOn] = useTreatment(SplitFeatures.MAP_EDIT_CUSTOM_HIER_RULES);
  const { isEverythingOn } = useTerritoryLockingTreatment();

  return useMemo(
    () => ({
      isContributorMapPinsOn,
      isUndoEnabled,
      isTerritoryLockingEnabled: isEverythingOn,
      isMapEditCustomHierarchyRulesOn
    }),
    [isContributorMapPinsOn, isUndoEnabled, isEverythingOn, isMapEditCustomHierarchyRulesOn]
  );
};
type MapFlags = ReturnType<typeof useMapFlags>;

const useMapSituation = () => {
  const { selectedBattleCard, isEmbedded } = useMapContextRedistributor();
  const { isPreviewMapOpen } = useMapVariant();
  const { customerVisuals, chosenCustomHierarchy, isExactCustomHierarchyFilterActive, isColoringByTerritoryGroup } =
    useDedicatedMapProvider();
  const canSeeAllRules = useCanSeeAllRulesOnMap();
  const isOverlayBattleCard =
    selectedBattleCard?.battlecardType === BattlecardType.DirectOverlay ||
    selectedBattleCard?.battlecardType === BattlecardType.IndirectOverlay;

  const areCustomHierarchyRulesVisible = chosenCustomHierarchy.quantity !== MchQuantity.NONE;

  const isAccountsVisible = customerVisuals !== CustomerVisualization.HIDE;

  return useMemo(
    () => ({
      isOverlayBattleCard,
      isEmbedded,
      canSeeAllRules,
      isAccountsVisible,
      isPreview: isPreviewMapOpen,
      areCustomHierarchyRulesVisible,
      isExactCustomHierarchyFilterActive,
      isColoringByTerritoryGroup
    }),
    [
      isOverlayBattleCard,
      isEmbedded,
      canSeeAllRules,
      isAccountsVisible,
      isPreviewMapOpen,
      areCustomHierarchyRulesVisible,
      isExactCustomHierarchyFilterActive,
      isColoringByTerritoryGroup
    ]
  );
};
type MapSituation = ReturnType<typeof useMapSituation>;

const useMapPermissions = () => {
  const checkCanUser = useCheckCanUser();
  return useMemo(
    () => ({
      pinsView: checkCanUser(UserAction.MAP_PINS_VIEW),
      pinsEdit: checkCanUser(UserAction.MAP_PINS_EDIT),
      unassignedView: checkCanUser(UserAction.MAP_UNASSIGNED_VIEW),
      ruleEdit: checkCanUser(UserAction.MAP_RULE_EDIT),
      recolor: checkCanUser(UserAction.MAP_RECOLOR)
    }),
    [checkCanUser]
  );
};
type MapPermissions = ReturnType<typeof useMapPermissions>;

export interface MapCapabilityContext {
  permissions: MapPermissions;
  situation: MapSituation;
  flags: MapFlags;
}

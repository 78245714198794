// All env vars must also be listed in the EnvironmentPlugin in webpack.config.js
const mandatoryConfigVars = {
  AWS_REGION: process.env.AWS_REGION,
  APPSYNC_GRAPHQL_ENDPOINT: process.env.APPSYNC_GRAPHQL_ENDPOINT,
  AG_GRID_KEY: process.env.AG_GRID_KEY,
  APPSYNC_GRAPHQL_APP_ID: process.env.APPSYNC_GRAPHQL_APP_ID,
  DOCS_SITE_URL: process.env.DOCS_SITE_URL,
  MAPBOX_STYLE_URL: process.env.MAPBOX_STYLE_URL,
  MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN,
  SPLITIO_API_KEY_CLIENT: process.env.SPLITIO_API_KEY_CLIENT,
  API_GATEWAY_ENDPOINT: process.env.API_GATEWAY_ENDPOINT,
  NODE_ENV: process.env.NODE_ENV,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  AUTH0_SPA_CLIENT_ID: process.env.AUTH0_SPA_CLIENT_ID,
  AUTH0_CONNECTION_NAME: process.env.AUTH0_CONNECTION_NAME,
  TARGET_ENV: process.env.TARGET_ENV,
  SYMON_UPLOAD_REGION: process.env.SYMON_UPLOAD_REGION,
  EXAMPLE_MISSING_ENV_VAR: process.env.EXAMPLE_MISSING_ENV_VAR
};

const optionalConfigVars = {
  APPSYNC_GRAPHQL_SUBDOMAIN: process.env.APPSYNC_GRAPHQL_SUBDOMAIN,
  SUPPRESS_TEST_ERRORS: process.env.SUPPRESS_TEST_ERRORS,
  USE_AUTH0_IN_IFRAME: process.env.USE_AUTH0_IN_IFRAME,
  DATADOG_RUM_APPLICATION_ID: process.env.DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN: process.env.DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_RUM_SESSION_SAMPLE_RATE: process.env.DATADOG_RUM_SESSION_SAMPLE_RATE,
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE: process.env.DATADOG_RUM_SESSION_SAMPLE_RATE,
  DATADOG_RUM_TRACE_SAMPLE_RATE: process.env.DATADOG_RUM_TRACE_SAMPLE_RATE
};

export const config = {
  ...mandatoryConfigVars,
  ...optionalConfigVars
};

const missingEnvVars = Object.entries(mandatoryConfigVars)
  .filter(([, value]) => !value?.trim())
  .map(([key]) => key);

// "%c" tells browsers to style the console message
const errorWithJumboFontSize = (message: string) => console.error(`%c${message}`, 'font-size: 36px;');

if (missingEnvVars.length > 0 && config.NODE_ENV === 'development') {
  errorWithJumboFontSize(`Missing env vars ${missingEnvVars}`);
}

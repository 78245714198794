import React, { FC } from 'react';

import { Classes, Menu, MenuItem, Radio, Spinner } from '@blueprintjs/core';
import { AddAlt } from '@carbon/icons-react';

import CheckableMenuItem from 'components/CheckableMenuItem/CheckableMenuItem';

import { useIsMapCapable } from 'app/components/TerritoryMap/hooks/useMapCapability';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { CustomerVisualization, PinSet } from 'app/models';

import block from 'utils/bem-css-modules';
import { setInArray } from 'utils/helpers/collectionUtils';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import style from './PinMenuV2.module.pcss';

const b = block(style);

export interface PinMenuV2Props {
  pinSets: PinSet[];
  isPinSetsLoading: boolean;
  isLoadingCustomFeatures: boolean;
  isLoadingCustomerFeatures: boolean;
  onAddPinSet: () => void;
}

const PinMenuV2: FC<PinMenuV2Props> = ({
  pinSets,
  isPinSetsLoading,
  isLoadingCustomFeatures,
  isLoadingCustomerFeatures,
  onAddPinSet
}) => {
  const isEditCustomPinsEnabled = useIsMapCapable(MapCapability.EDIT_CUSTOM_PINS);
  const isViewCustomPinsEnabled = useIsMapCapable(MapCapability.VIEW_CUSTOM_PINS);
  const isViewCustomerPinsEnabled = useIsMapCapable(MapCapability.VIEW_CUSTOMER_PINS);
  const { selectedPinSetIds, setSelectedPinSetIds, customerVisuals, setCustomerVisuals } = useDedicatedMapProvider();

  const shouldShowCustomPinsSection = isEditCustomPinsEnabled || (isViewCustomPinsEnabled && pinSets.length > 0);

  const selectedPinSetIdsSet = new Set(selectedPinSetIds);
  const setIsPinSetSelected = (pinSetId: number, isSelected: boolean) =>
    setSelectedPinSetIds((pinSetsIds) => setInArray(pinSetsIds, pinSetId, isSelected));

  const CUSTOMER_PINS = [
    { key: CustomerVisualization.HIDE, label: formatMessage('HIDE_ACCOUNTS'), name: 'hide' },
    { key: CustomerVisualization.CLUSTER, label: formatMessage('CLUSTERS'), name: 'clusters' },
    { key: CustomerVisualization.HEAT, label: formatMessage('HEAT_AND_PINS'), name: 'heat' }
  ];

  return (
    <Menu className={b()} data-testid="pin-menu">
      {isViewCustomerPinsEnabled && (
        <>
          <MenuHeader text={formatMessage('PIN_TYPE_ACCOUNT')} data-testid="customer-pins-header" />

          {CUSTOMER_PINS.map((pin) => (
            <MenuItem
              key={pin.key}
              className={b('menuItem')}
              tagName="label"
              textClassName={b('radioLabel')}
              shouldDismissPopover={false}
              text={
                <>
                  <Radio
                    data-testid={`customer-${pin.name}-radio`}
                    checked={customerVisuals === pin.key}
                    onChange={() => setCustomerVisuals(pin.key)}
                    label={pin.label}
                    tagName="span"
                  />
                  {pin.key !== CustomerVisualization.HIDE &&
                    isLoadingCustomerFeatures &&
                    customerVisuals === pin.key && (
                      <Spinner
                        data-testid={`customer-${pin.name}-spinner`}
                        intent="primary"
                        size={18}
                        className={b('spinner')}
                      />
                    )}
                </>
              }
            />
          ))}
        </>
      )}
      {shouldShowCustomPinsSection && (
        <>
          <MenuHeader text={formatMessage('PIN_TYPE_CUSTOM')} data-testid="custom-pins-header" />

          {pinSets.map((pinSet) => (
            <CheckableMenuItem
              key={pinSet.pinSetId}
              data-testid={`pin-set-checkbox-${pinSet.pinSetId}`}
              text={pinSet.pinSetName}
              checked={selectedPinSetIdsSet.has(pinSet.pinSetId)}
              onChange={(checked) => setIsPinSetSelected(pinSet.pinSetId, checked)}
              isLoading={selectedPinSetIdsSet.has(pinSet.pinSetId) && isLoadingCustomFeatures}
            />
          ))}

          {isPinSetsLoading && (
            <>
              <MenuItemSkeleton />
              <MenuItemSkeleton />
            </>
          )}

          {isEditCustomPinsEnabled && (
            <MenuItem
              text={formatMessage('ADD_PIN_SET')}
              labelElement={<AddAlt />}
              onClick={onAddPinSet}
              data-testid="add-pin-set"
            />
          )}
        </>
      )}
    </Menu>
  );
};

const MenuItemSkeleton: FC = () => (
  <MenuItem textClassName={Classes.SKELETON} text={formatMessage('LOADING')} data-testid="pin-set-skeleton" />
);

const MenuHeader: FC<{ text: string; 'data-testid': string }> = ({ text, 'data-testid': testId }) => (
  <li className={Classes.MENU_HEADER} data-testid={testId}>
    <h6 className={`${Classes.HEADING} ${b('menuHeading')}`}>{text}</h6>
  </li>
);

export default PinMenuV2;

import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { ColDef, ColGroupDef, GridApi, GridOptions } from '@ag-grid-community/core';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';
import { ColumnMappingCellRenderer } from 'app/components/DataMappingDrillIn/ColumnMappingCellRenderer';
import { useGetSymonPipeConfigurationData } from 'app/components/DataMappingDrillIn/hooks/useGetSymonPipeConfigurationData';
import { useMappingFields } from 'app/components/DataMappingDrillIn/hooks/useMappingFields';

import { GRID_COLUMN_MIN_WIDTH, TABLE_ROW_SIZE } from 'app/constants/DataPanelConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { FileTypeEnum } from 'app/graphql/generated/apolloTypes';

import { DataMappingType } from 'app/models';

import block from 'utils/bem-css-modules';
import { buildRowDataFromFile } from 'utils/helpers/buildRowDataFromFile';
import { formatMessage } from 'utils/messages/utils';

import style from './DataMappingDrillIn.module.pcss';
import { useS3File } from './hooks/useS3File';
import { useSymonPipeDataPreview } from './hooks/useSymonPipeDataPreview';

const b = block(style);

interface DataMappingDrillInProps {
  setGridApi?: Dispatch<SetStateAction<GridApi>>;
  dataMappingType?: DataMappingType;
  withSpacing?: boolean;
  fileType?: FileTypeEnum;
  rootHierarchyId?: number | null;
}

const DataMappingDrillIn: React.FC<DataMappingDrillInProps> = ({
  setGridApi,
  dataMappingType = DataMappingType.DATA_TABLE,
  withSpacing = true,
  fileType = FileTypeEnum.Activity,
  rootHierarchyId = null
}: DataMappingDrillInProps) => {
  const mappingFieldRef = useRef(null);
  const fileContainerRef = useRef(null);
  const { selectedPlanningCycle } = useScope();
  const {
    selectedTable,
    columnParamsLookupMap,
    setColumnParamsLookupMap,
    setMappedFields,
    pollingTokenId,
    selectedConfiguration
  } = useData();
  const { selectedBattleCardId } = useBattleCard();
  const { mappingFields, mappingFieldsLoading } = useMappingFields(
    selectedPlanningCycle.id,
    fileType,
    rootHierarchyId,
    +selectedBattleCardId
  );
  const { symonPipeConfig } = useGetSymonPipeConfigurationData(selectedConfiguration?.symonPipeConfigurationId);
  const { fileRows, headersData, s3FileLoading } = useS3File(
    selectedTable?.tableId,
    0,
    TABLE_ROW_SIZE,
    dataMappingType === DataMappingType.CONFIGURATION
  );
  const { symonPipeFileRows, symonPipeFileHeadersData, symonPipeFileLoading } = useSymonPipeDataPreview(
    selectedPlanningCycle.id,
    pollingTokenId,
    dataMappingType === DataMappingType.DATA_TABLE
  );

  const symonPipeConfigMapping = symonPipeConfig?.getSymonPipeConfiguration?.mapping;

  useEffect(() => {
    setMappedFields([]);
    setColumnParamsLookupMap({});
  }, [selectedTable, selectedConfiguration]);

  const fileGridOptions: GridOptions = {
    alignedGrids: [],
    suppressMovableColumns: true
  };

  const mappingFieldGridOptions: GridOptions = {
    headerHeight: 0,
    alignedGrids: [],
    suppressClickEdit: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    suppressRowHoverHighlight: true,
    rowStyle: {
      background: 'rgb(var(--color-light-gray-4))'
    }
  };

  fileGridOptions.alignedGrids.push(mappingFieldGridOptions);
  mappingFieldGridOptions.alignedGrids.push(fileGridOptions);

  const onGridReady = (params) => {
    setGridApi?.(params?.api);
  };

  const buildFileContentColumnDefs = (rows) => {
    const fileContentColumnDefs: Array<ColDef | ColGroupDef> = [];
    if (rows?.length > 0) {
      Object.keys(rows[0])?.forEach((column) => {
        fileContentColumnDefs.push({
          headerName:
            dataMappingType === DataMappingType.DATA_TABLE ? column : symonPipeFileHeadersData?.[column]?.name,
          field: column,
          flex: 1,
          minWidth: GRID_COLUMN_MIN_WIDTH
        });
      });
    }
    return fileContentColumnDefs;
  };

  const buildMappingFieldGridColumnDefs = (headers) => {
    const headersColumnDefs: Array<ColDef | ColGroupDef> = [];
    headers?.forEach((header) => {
      if (dataMappingType === DataMappingType.CONFIGURATION) {
        header = header.name;
      }
      headersColumnDefs.push({
        editable: true,
        field: header,
        flex: 1,
        width: 200,
        minWidth: GRID_COLUMN_MIN_WIDTH,
        cellRendererSelector: (params) => {
          // Each header can have 3 states.
          // 1. The header is not in the lookup map, and this is the initial state of all the original visible headers.
          // We add it with some ag-grid properties to the map.
          // 2. The header is in the lookup map, but it does not have the data field. This is the initial state of all the original invisible headers.
          // We have to replace the default empty value with the one stored there ib auto matching.
          // 3. The header is in the lookup map and it has the data field. This is the state of all the headers that have complete properties in the lookup map.
          // We don't need to do anything in this case.
          if (!columnParamsLookupMap[header]) {
            columnParamsLookupMap[header] = params;
            setColumnParamsLookupMap(columnParamsLookupMap);
          } else if (!columnParamsLookupMap[header]?.data) {
            const tempValue = columnParamsLookupMap[header];
            columnParamsLookupMap[header] = params;
            columnParamsLookupMap[header].value = tempValue;
            setColumnParamsLookupMap(columnParamsLookupMap);
          }
          return {
            frameworkComponent: ColumnMappingCellRenderer,
            params: {
              params,
              symonPipeConfigMapping,
              items: mappingFields,
              fileType
            }
          };
        }
      });
    });
    return headersColumnDefs;
  };

  const buildMappingFieldGridInitialData = (headers) => {
    const initialData = {};
    headers?.forEach((header) => {
      if (dataMappingType === DataMappingType.CONFIGURATION) {
        header = header.name;
      }
      initialData[header] = { key: null, value: null };
      // Add the header to the map if it's not there. This is used for the invisible headers.
      if (!columnParamsLookupMap[header]) {
        columnParamsLookupMap[header] = initialData[header];
        setColumnParamsLookupMap(columnParamsLookupMap);
      }
    });
    return [initialData];
  };

  const dataMappingFileHeaders =
    dataMappingType === DataMappingType.DATA_TABLE ? headersData : symonPipeFileHeadersData;
  const rowDataList =
    dataMappingType === DataMappingType.DATA_TABLE ? buildRowDataFromFile(fileRows) : symonPipeFileRows;
  const showGridLoading = mappingFieldsLoading || s3FileLoading || symonPipeFileLoading;
  const hasData = mappingFields?.length > 0 && dataMappingFileHeaders?.length > 0;

  return (
    <div className={b('', { withSpacing })}>
      <div className={b('mappingFieldGrid')} ref={mappingFieldRef}>
        <div className={`ag-theme-alpine ${b('fullWidthGrid')}`}>
          {/* Have to keep the old way of rendering the loading screen to prevent grid re-rendering after updating grid size in the AdvancedGrid component. */}
          {showGridLoading && (
            <div data-testid="mapping-field-grid-loading">
              <GridLoading
                gridHeight={mappingFieldRef?.current?.offsetHeight}
                gridWidth={mappingFieldRef?.current?.offsetWidth}
              />
            </div>
          )}
          {!showGridLoading && hasData && (
            <AdvancedGrid
              gridOptions={mappingFieldGridOptions}
              columnDefs={buildMappingFieldGridColumnDefs(dataMappingFileHeaders)}
              rowData={buildMappingFieldGridInitialData(dataMappingFileHeaders)}
              data-testid="mapping-field-grid"
              onGridReady={onGridReady}
            />
          )}
          {!showGridLoading && !hasData && (
            <div className={b('noData')} data-testid="mapping-field-grid-empty">
              {formatMessage('EMPTY_GRID')}
            </div>
          )}
        </div>
      </div>
      <div className={b('fileContentGrid', { withSpacing })} ref={fileContainerRef}>
        <div className={`ag-theme-alpine ${b('fullWidthGrid')}`}>
          {showGridLoading || hasData ? (
            <AdvancedGrid
              gridOptions={fileGridOptions}
              columnDefs={buildFileContentColumnDefs(rowDataList)}
              rowData={rowDataList}
              data-testid="file-content-grid"
              gridWidth={fileContainerRef?.current?.offsetWidth}
              gridHeight={fileContainerRef?.current?.offsetHeight}
              showGridLoading={showGridLoading}
            />
          ) : (
            <div className={b('noData')} data-testid="file-content-grid-empty">
              {formatMessage('EMPTY_GRID')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataMappingDrillIn;

import React, { useEffect, useMemo } from 'react';

import { Intent } from '@blueprintjs/core';

import Dialog from 'components/Dialog/Dialog';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';

import { RULE_PREVIEW_COUNT } from 'app/global/variables';

import { TargetRuleComponent } from 'app/graphql/generated/apolloTypes';
import { useUnassignRuleHierarchies } from 'app/graphql/mutations/unassignRuleHierarchies';

import useShowToast from 'app/hooks/useShowToast';

import { HierarchyType } from 'app/models';

import { formatListSummary, formatMessage } from 'utils/messages/utils';

import { useMapQuestion } from './hooks/useMapQuestion';

export interface UnassignFromTerritoryDialogProps {
  hierarchyIds: number[];
  onTerritoryUpdated: () => void;
  onClose: () => void;
  hierarchyType: HierarchyType.CustomerAccountHierarchy | HierarchyType.GeographicTerritoryHierarchy;
  editableRuleIds: number[];
}

const UnassignFromTerritoryDialog: React.FC<UnassignFromTerritoryDialogProps> = ({
  hierarchyIds,
  onTerritoryUpdated,
  onClose,
  hierarchyType,
  editableRuleIds
}: UnassignFromTerritoryDialogProps) => {
  const { selectedTerritoryGroupId } = useMapContextRedistributor();
  const [unassignedRuleHierarchies, { loading: unassignLoading }] = useUnassignRuleHierarchies();
  const showToast = useShowToast();

  const {
    answer: namesForBeingRemoved,
    ask: getNamesForBeingRemoved,
    error: getNameError
  } = useMapQuestion('get-names-for-unassign');

  useEffect(() => {
    getNamesForBeingRemoved();
  }, []);

  const nameInfo = useMemo(
    () => ({
      hierarchyCount: namesForBeingRemoved?.hierarchiesBeingRemovedCount || 0,
      ruleNamesTruncated: namesForBeingRemoved
        ? formatListSummary([...namesForBeingRemoved.ruleNames], RULE_PREVIEW_COUNT)
        : ''
    }),
    [namesForBeingRemoved]
  );

  const dynamicCopy = useMemo(() => {
    switch (hierarchyType) {
      case HierarchyType.CustomerAccountHierarchy:
        return {
          title: formatMessage('UNASSIGN_ACCOUNT_TITLE'),
          body: formatMessage('UNASSIGN_ACCOUNT_BODY', nameInfo),
          successToast: formatMessage('UNASSIGN_ACCOUNT_SUCCESS_TOAST'),
          errorToast: formatMessage('UNASSIGN_ACCOUNT_ERROR_TOAST')
        };
      case HierarchyType.GeographicTerritoryHierarchy:
        return {
          title: formatMessage('UNASSIGN_GEO_TITLE'),
          body: formatMessage('UNASSIGN_GEO_BODY', nameInfo),
          successToast: formatMessage('UNASSIGN_GEO_SUCCESS_TOAST'),
          errorToast: formatMessage('UNASSIGN_GEO_ERROR_TOAST')
        };
      default:
        throw new Error(`Unsupported hierarchy type ${hierarchyType}`);
    }
  }, [hierarchyType, nameInfo]);

  useEffect(() => {
    if (getNameError) {
      onClose();
      showToast(dynamicCopy.errorToast, 'danger');
    }
  }, [getNameError]);

  const handleSubmit = async () => {
    try {
      await unassignedRuleHierarchies({
        variables: {
          input: {
            scopingTerritoryGroupId: selectedTerritoryGroupId,
            sourceHierarchyIds: hierarchyIds,
            ruleIdsFilter: editableRuleIds,
            targetRuleComponent:
              hierarchyType === HierarchyType.CustomerAccountHierarchy
                ? TargetRuleComponent.Modifier
                : TargetRuleComponent.Dimension
          }
        }
      });

      showToast(dynamicCopy.successToast, 'success');
      onTerritoryUpdated();
      onClose();
    } catch (error) {
      showToast(dynamicCopy.errorToast, 'danger');
      throw error;
    }
  };

  return (
    <Dialog
      isLoading={!namesForBeingRemoved && hierarchyType === HierarchyType.GeographicTerritoryHierarchy}
      isOpen
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmButtonText={formatMessage('UNASSIGN')}
      confirmButtonIntent={Intent.DANGER}
      title={dynamicCopy.title}
      bodyMinHeight={0}
      size="small"
      confirmButtonLoading={unassignLoading}
      children={<div data-testid="unassign-from-territory-dialog">{dynamicCopy.body}</div>}
    />
  );
};

export default UnassignFromTerritoryDialog;

import React, { FC, useState } from 'react';

import { Classes, Menu, MenuItem } from '@blueprintjs/core';
import { Edit, FolderMoveTo, TrashCan, WarningAlt, IncompleteCancel, CubeView } from '@carbon/icons-react';

import MessageTooltip from 'components/MessageTooltip/MessageTooltip';

import { MapActions, MapSelectionTarget, PossibleMapActions } from 'app/models';

import block from 'utils/bem-css-modules';
import { MapCapability } from 'utils/maps/mapCapabilityRegistry';
import { formatMessage } from 'utils/messages/utils';

import { useLockAndIgnoreActions } from './hooks/useLockAndIgnoreActions';
import { useCheckMapCapable } from './hooks/useMapCapability';
import style from './MapActionMenu.module.pcss';

const b = block(style);

export interface MapActionMenuProps {
  onClickAction: (action: MapActions) => void;
  possibleMapActions: PossibleMapActions;
  isMapLoading: boolean;
  selectionTarget: MapSelectionTarget;
  isMultipleRulesSelected: boolean;
  isLoading: boolean;
}

const MapActionMenu: FC<MapActionMenuProps> = ({
  onClickAction,
  possibleMapActions,
  isMapLoading,
  selectionTarget,
  isMultipleRulesSelected,
  isLoading
}: MapActionMenuProps) => {
  const { isolateRules } = useLockAndIgnoreActions();

  const checkMapCapable = useCheckMapCapable();
  switch (selectionTarget) {
    case MapSelectionTarget.territories:
      return (
        <Menu>
          {checkMapCapable(MapCapability.ISOLATE_RULES) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('ISOLATE_SELECTED_TERRITORIES')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Isolate}
              icon={<CubeView />}
              action={MapActions.Isolate}
              onClickAction={isolateRules}
            />
          )}
          {checkMapCapable(MapCapability.FIX_OVERLAP) && possibleMapActions?.FixConflict && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip={formatMessage('REMOVE_CONFLICT_ACTION_TOOLTIP')}
              text={formatMessage('REMOVE_CONFLICT_BUTTON')}
              isMenuItemDisabled={isMapLoading}
              icon={<WarningAlt />}
              action={MapActions.FixConflict}
              onClickAction={onClickAction}
            />
          )}
          {checkMapCapable(MapCapability.DELETE_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={
                isMultipleRulesSelected
                  ? formatMessage('DELETE_MULTIPLE_TERRITORIES_TEXT')
                  : formatMessage('DELETE_TERRITORY_TEXT')
              }
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Delete}
              icon={<TrashCan />}
              action={MapActions.Delete}
              onClickAction={onClickAction}
            />
          )}
        </Menu>
      );
    case MapSelectionTarget.polygons:
      return (
        <Menu>
          {checkMapCapable(MapCapability.ISOLATE_RULES) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('ISOLATE_TERRITORIES')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Isolate}
              icon={<CubeView />}
              action={MapActions.Isolate}
              onClickAction={isolateRules}
            />
          )}
          {checkMapCapable(MapCapability.CREATE_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip={formatMessage('CREATE_TERRITORY_TOOLTIP_GEO')}
              text={formatMessage('CREATE_NEW_TERRITORY_DIALOG_TITLE')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Create}
              icon={<Edit />}
              action={MapActions.Create}
              onClickAction={onClickAction}
            />
          )}
          {checkMapCapable(MapCapability.ASSIGN_TO_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('MAP_ACTION_MENU_REASSIGN_GEO')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Reassign}
              icon={<FolderMoveTo />}
              action={MapActions.Reassign}
              onClickAction={onClickAction}
            />
          )}
          {checkMapCapable(MapCapability.UNASSIGN_FROM_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('UNASSIGN_GEO_TITLE')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Unassign}
              icon={<TrashCan />}
              action={MapActions.Unassign}
              onClickAction={onClickAction}
            />
          )}
        </Menu>
      );
    case MapSelectionTarget.accounts:
      return (
        <Menu>
          {checkMapCapable(MapCapability.ISOLATE_RULES) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('ISOLATE_TERRITORIES')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Isolate}
              icon={<CubeView />}
              action={MapActions.Isolate}
              onClickAction={isolateRules}
            />
          )}
          {checkMapCapable(MapCapability.CREATE_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip={formatMessage('CREATE_TERRITORY_TOOLTIP_ACCOUNT')}
              text={formatMessage('CREATE_NEW_TERRITORY_DIALOG_TITLE')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Create}
              icon={<Edit />}
              action={MapActions.Create}
              onClickAction={onClickAction}
            />
          )}
          {checkMapCapable(MapCapability.ASSIGN_TO_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip=""
              text={formatMessage('MAP_ACTION_MENU_REASSIGN_ACCOUNT')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Reassign}
              icon={<FolderMoveTo />}
              action={MapActions.Reassign}
              onClickAction={onClickAction}
            />
          )}
          {checkMapCapable(MapCapability.UNASSIGN_FROM_RULE) && (
            <MenuItemWithTooltip
              isLoading={isLoading}
              tooltip={formatMessage('UNASSIGN_ACCOUNT_TOOLTIP')}
              text={formatMessage('UNASSIGN_ACCOUNT_TITLE')}
              isMenuItemDisabled={isMapLoading || !possibleMapActions?.Unassign}
              icon={<IncompleteCancel />}
              action={MapActions.Unassign}
              onClickAction={onClickAction}
            />
          )}
        </Menu>
      );
  }
};

const MenuItemWithTooltip: FC<{
  tooltip: string;
  isMenuItemDisabled: boolean;
  icon: JSX.Element;
  action: MapActions;
  text: string;
  onClickAction: (action: MapActions) => void;
  isLoading: boolean;
}> = ({ tooltip, isMenuItemDisabled, icon, action, text, onClickAction, isLoading }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleShowTooltip = () => {
    setShowTooltip(true);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <MessageTooltip
      content={tooltip}
      disabled={!tooltip}
      placement="right"
      isOpen={showTooltip}
      target={
        <div className={b('menuItemWrapper')} onPointerOver={handleShowTooltip} onPointerLeave={handleHideTooltip}>
          <MenuItem
            onClick={() => onClickAction(action)}
            data-testid={`map-action-item-${action.toLowerCase()}`}
            text={text}
            disabled={isMenuItemDisabled}
            icon={icon}
            textClassName={isLoading ? Classes.SKELETON : ''}
          />
        </div>
      }
    />
  );
};

export default React.memo(MapActionMenu);

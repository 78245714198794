import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { Spinner, Tag } from '@blueprintjs/core';
import { Close } from '@carbon/icons-react';
import { Formik } from 'formik';

import { BasicSelectField } from 'components/BasicSelect/BasicSelectField';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog from 'components/Dialog/Dialog';
import EllipsisText from 'components/EllipsisText/EllipsisText';
import LazyContainer from 'components/LazyContainer/LazyContainer';
import { KeyValue } from 'components/models';
import MultiSelectMenu from 'components/MultiSelectMenu/MultiSelectMenu';

import DocsLink from 'app/components/DocsLink/DocsLink';

import { ExternalRoutePaths } from 'app/containers/App/Router/routePaths';

import { useGeoCatalogAndSelection } from 'app/graphql/hooks/useGeoCatalogAndSelection';
import { useUpsertGeoSelection } from 'app/graphql/hooks/useUpsertGeoSelection';

import { GetCatalogItem, GridHeaders } from 'app/models';

import block from 'utils/bem-css-modules';
import {
  catalogItemComparator,
  getDeepestItemsByCountry,
  getShallowestItemsByCountry
} from 'utils/helpers/countrySourceCatalogHelpers';
import { formatListSummary, formatMessage } from 'utils/messages/utils';
import { MultiKeyMap } from 'utils/MultiKeyMap';

import style from './StandardGeoCatalogModal.module.pcss';

const b = block(style);

interface StandardGeoCatalogModalProps {
  showMapMessage: boolean;
  onClose: () => void;
}

// Maps CountryCode -> { value: SourceKey, key: NameOfSource }
type CountrySourceItemRecord = Record<string, string>;

interface SgcmFormValues {
  countrySourceRecord: CountrySourceItemRecord;
}

export const StandardGeoCatalogModal: FC<StandardGeoCatalogModalProps> = ({ showMapMessage, onClose }) => {
  const { availableItems, inUseItems, loading: isCatalogLoading } = useGeoCatalogAndSelection();
  const [upsertGeoSelection, { error: upsertError }] = useUpsertGeoSelection({ onCompleted: onClose });

  const catalogRootMap = useMemo(() => getShallowestItemsByCountry(availableItems), [availableItems]);

  const initialFormValues = useMemo(() => {
    const formValues: SgcmFormValues = { countrySourceRecord: {} };
    [...getDeepestItemsByCountry(inUseItems).values()]
      .sort(catalogItemComparator)
      .forEach(({ country, sourceKey }) => (formValues.countrySourceRecord[country] = sourceKey));
    return formValues;
  }, [inUseItems]);

  const handleSubmit = useCallback(
    async (formValues: SgcmFormValues) => {
      const countrySourceKeys = [...Object.entries(formValues.countrySourceRecord)].map(([country, sourceKey]) => ({
        country,
        sourceKey
      }));
      await upsertGeoSelection({ selectionBySourceKey: countrySourceKeys });
    },
    [upsertGeoSelection]
  );

  const errorMessage = useMemo(() => {
    if (!upsertError) return null;
    if (upsertError.knownError?.errorCode === 'REMOVE_GEO_HIER_HAS_DEPENDENCY') {
      return formatMessage('REMOVE_GEO_HIER_HAS_DEPENDENCY', {
        territoryIds: formatListSummary(upsertError.knownError.errorContext?.dependantTerritoryIds ?? [], 10)
      });
    }
    return formatMessage('GEO_HIERARCHY_UPDATED_ERROR');
  }, [upsertError]);

  return (
    <Formik<SgcmFormValues> enableReinitialize initialValues={initialFormValues} onSubmit={handleSubmit}>
      {({ values, isValid, isSubmitting, submitForm, setFieldValue }) => {
        const hasSomeCountries = Object.keys(values.countrySourceRecord).length > 0;

        const handleRemoveCountry = (country: string) => {
          const newCountrySourceRecord: CountrySourceItemRecord = { ...values.countrySourceRecord };
          delete newCountrySourceRecord[country];
          setFieldValue('countrySourceRecord', newCountrySourceRecord);
        };

        return (
          <Dialog
            isOpen
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            portalClassName={b('dialog')}
            title={formatMessage('ADD_GEO_HIERARCHY_TITLE')}
            disableConfirm={!isValid || isCatalogLoading}
            confirmButtonLoading={isSubmitting}
            onSubmit={submitForm}
            onClose={onClose}
            bodyMinHeight={216}
          >
            {isCatalogLoading ? (
              <div data-testid="loading-countries-modal" className={b('loadingBlock')}>
                <Spinner intent="primary" size={60} />
              </div>
            ) : (
              <>
                <section data-testid="select-countries-section">
                  {showMapMessage && <p className={b('description')}>{formatMessage('NO_IN_USE_GEO_DESC')}</p>}
                  <p className={b('description')}>{formatMessage('ADD_GEO_HIERARCHY_DESC')}</p>

                  <CountryMultiSelect
                    countrySourceRecord={values.countrySourceRecord}
                    catalogRootMap={catalogRootMap}
                    onChange={(countrySourceRecord) => setFieldValue('countrySourceRecord', countrySourceRecord)}
                  />
                </section>
                {hasSomeCountries && (
                  <section data-testid="select-source-section">
                    <p>
                      {formatMessage('GEO_GRID_DESC')} <br />
                      <DocsLink
                        label={formatMessage('EXTERNAL_DOCS_LINK')}
                        relativeUrl={ExternalRoutePaths.MAPS_GEOGRAPHY_LEVEL_EXTENSION}
                      />
                    </p>
                    <CatalogTable
                      countrySourceRecord={values.countrySourceRecord}
                      availableCatalogItems={availableItems}
                      onRemoveCountry={handleRemoveCountry}
                    />
                  </section>
                )}
                {errorMessage && (
                  <div data-testid="catalog-error-message" className={b('errorMessage')}>
                    {errorMessage}
                  </div>
                )}
              </>
            )}
          </Dialog>
        );
      }}
    </Formik>
  );
};

const CountryMultiSelect: FC<{
  countrySourceRecord: CountrySourceItemRecord;
  catalogRootMap: Map<string, GetCatalogItem>;
  onChange: (countrySourceRecord: CountrySourceItemRecord) => void;
}> = ({ countrySourceRecord, catalogRootMap, onChange }) => {
  const portalRef = useRef<HTMLDivElement>(undefined!);

  const countryDropdownItems = useMemo(
    () =>
      [...catalogRootMap.values()]
        .sort(catalogItemComparator)
        .map((item) => ({ value: item.country, key: item.countryName })),
    [catalogRootMap]
  );

  const selectedCountries = useMemo(
    () =>
      [...Object.keys(countrySourceRecord)].map((country) => ({
        value: country,
        key: catalogRootMap.get(country).countryName
      })),
    [countrySourceRecord]
  );

  const handleCountriesChanged = (newSelection: KeyValue<string>[]) => {
    const newCountrySourceRecord: CountrySourceItemRecord = {};
    const selectedCountrySet = new Set(newSelection.map(({ value }) => value));

    // Place new items into record first, to appear at top of list
    for (const country of selectedCountrySet) {
      if (country in countrySourceRecord) continue;

      const { sourceKey } = catalogRootMap.get(country);
      newCountrySourceRecord[country] = sourceKey;
    }

    for (const [country, item] of Object.entries(countrySourceRecord)) {
      if (!selectedCountrySet.has(country)) continue;

      newCountrySourceRecord[country] = item;
    }
    onChange(newCountrySourceRecord);
  };

  return (
    <label className={b('countryDropdownLabel')}>
      <span>{formatMessage('ADD_GEO_HIERARCHY_DROP_DOWN_LABEL')}</span>
      <div className={b('countryDropdownWrapper')} ref={portalRef}>
        <MultiSelectMenu
          field={{ name: 'selectedCountries', value: selectedCountries }}
          onChange={handleCountriesChanged}
          items={countryDropdownItems}
          portalRef={portalRef}
          showErrors={false}
          tooltipEnabled={false}
          usePortal
          showFilter
          loadingComplete
          theme="default"
          small
        />
      </div>
    </label>
  );
};

const CatalogTable: FC<{
  countrySourceRecord: CountrySourceItemRecord;
  availableCatalogItems: GetCatalogItem[];
  onRemoveCountry: (country: string) => void;
}> = ({ countrySourceRecord, availableCatalogItems, onRemoveCountry }) => {
  const portalRef = useRef<HTMLDivElement>(undefined!);

  const catalogByCountrySource = useMemo(
    () =>
      new MultiKeyMap(
        availableCatalogItems.map((item): [string, string, GetCatalogItem] => [item.country, item.sourceKey, item])
      ),
    [availableCatalogItems]
  );

  const catalogRows = useMemo(
    () =>
      Object.entries(countrySourceRecord).map(([country, sourceKey]) => catalogByCountrySource.get(country, sourceKey)),
    [countrySourceRecord, catalogByCountrySource]
  );

  const sourceDropdownItemsByCountry = useMemo(() => {
    const sourcesByCountry = new Map<string, GetCatalogItem[]>();

    availableCatalogItems.forEach((catalogItem) => {
      const sources = sourcesByCountry.get(catalogItem.country) ?? [];
      sources.push(catalogItem);
      sourcesByCountry.set(catalogItem.country, sources);
    });

    sourcesByCountry.forEach((sources) => sources.sort((a, b) => a.depth - b.depth || a.name.localeCompare(b.name)));

    return new Map(
      [...sourcesByCountry.entries()].map(([country, sources]) => [
        country,
        sources.map(({ sourceKey, name }) => ({ value: sourceKey, text: name }))
      ])
    );
  }, [availableCatalogItems]);

  // Scroll back to top when a new item added
  const rowCount = catalogRows.length;
  const lastRowCountRef = useRef(rowCount);
  useEffect(() => {
    if (rowCount > lastRowCountRef.current) {
      portalRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    lastRowCountRef.current = rowCount;
  }, [rowCount]);

  return (
    <div data-testid="country-source-table-container" className={b('tableContainer')} ref={portalRef}>
      <table className={b('table')}>
        <thead>
          <tr className={b('tableHeaderRow')}>
            <th>{GridHeaders.GEO_NAME}</th>
            <th>{GridHeaders.GEO_LEVEL}</th>
            <th>{GridHeaders.GEO_NUMBER_REGIONS}</th>
            <th>
              <span className={b('visuallyHidden')}>{GridHeaders.REMOVE_GEO} </span>
            </th>
          </tr>
        </thead>
        <tbody className={b('tableBody')}>
          {catalogRows.map(({ country, sourceKey, countryName, polygonCount }) => (
            <tr key={`${country}::${sourceKey}`} data-testid={`catalog-country-row-${country}`}>
              <td>
                <EllipsisText text={countryName} />
              </td>
              <td className={b('dropdownCell')}>
                <LazyContainer className={b('dropdownContainer')}>
                  <BasicSelectField
                    name={`countrySourceRecord.${country}`}
                    label={`countrySourceRecord.${country}`}
                    items={sourceDropdownItemsByCountry.get(country)}
                    placement="bottom-start"
                    fill
                    showIconOnButton={false}
                  />
                </LazyContainer>
              </td>
              <td>
                <Tag>{polygonCount}</Tag>
              </td>
              <td className={b('removeCell')}>
                <TextButton
                  text={formatMessage('REMOVE')}
                  rightIcon={<Close />}
                  onClick={() => onRemoveCountry(country)}
                  minimal
                  large={false}
                  type="button"
                  testId={`remove-country-${country}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ColDef, GridApi, ServerSideStoreType } from '@ag-grid-community/core';
import { useMutation } from '@apollo/client';
import { Callout, Intent } from '@blueprintjs/core';
import { ArrowLeft } from '@carbon/icons-react';
import { HTMLHeading } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import EditRowCellRenderer from 'app/components/DataPanel/TablesPanel/LookupTableDetail/EditRowCellRenderer/EditRowCellRenderer';

import { useData } from 'app/contexts/dataProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { DEFAULT_PLACEHOLDER_COLOR, NUMBER_GREATER_THAN_ZERO, SCHEDULE_COLUMN_WIDTH } from 'app/global/variables';

import { LookupTypeEnum, UpsertLookupRow, UpsertLookupRowVariables } from 'app/graphql/generated/apolloTypes';
import { ScheduleData, getLookupTable } from 'app/graphql/hooks/useGetLookupTable';
import { UPSERT_LOOKUP_ROW } from 'app/graphql/mutations/upsertLookupRow';

import useShowToast from 'app/hooks/useShowToast';

import { DataPanelViews } from 'app/models/index';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './LookupTableDetail.module.pcss';

const b = block(style);

interface LookupTableDetailProps {
  showHeader?: boolean;
  showTopPinnedRow?: boolean;
  onRowUpserted?: () => void;
}

const LookupTableDetail: React.FC<LookupTableDetailProps> = ({
  showHeader = true,
  showTopPinnedRow = true,
  onRowUpserted
}: LookupTableDetailProps) => {
  const { selectedTable, setIsLookupRowSubmitting, setSelectedDataView } = useData();
  const { selectedPlanningCycle } = useScope();

  const containerRef = useRef(null);

  // value of percentage at max can have 5 decimal point
  const percentageValueFormatter = (num) => Math.round(num * 100000) / 100000;

  const [callout, setCallout] = useState<{ intent: Intent; message: string }>();
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [newScheduleName, setNewScheduleName] = useState<string | null>(null);
  const [shouldFlash, setShouldFlash] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<ScheduleData[]>(null);
  const showToast = useShowToast();

  const loadLookupTableData = useCallback(async () => {
    const result = await getLookupTable({
      planningCycleId: selectedPlanningCycle?.id,
      lookupInput: {
        lookupId: selectedTable?.tableId,
        lookupType: [selectedTable?.tableDataType as LookupTypeEnum]
      }
    });
    setInitialData(result);
    return result;
  }, [selectedTable]);

  useEffect(() => {
    if (!!selectedTable?.tableDataType) {
      loadLookupTableData();
    }
  }, [selectedTable]);

  const defaultCalloutMessage =
    selectedTable?.tableDataType === LookupTypeEnum.Seasonality
      ? `${formatMessage('SEASONALITY_SUM_MESSAGE')} ${formatMessage('PERCENTAGE_VALUE_MESSAGE')}`
      : formatMessage('RAMP_TABLE_MESSAGE');

  const [upsertLookupRow, { loading: upsertingLookupRow }] = useMutation<UpsertLookupRow, UpsertLookupRowVariables>(
    UPSERT_LOOKUP_ROW
  );

  useEffect(() => {
    setIsLookupRowSubmitting(upsertingLookupRow);
  }, [upsertingLookupRow]);

  const handleBackClicked = () => {
    setSelectedDataView(DataPanelViews.DATA_OVERVIEW);
  };

  const seasonalityTotal = (data: Record<string, string>) => {
    let currentTotal = 0;
    Object.entries(data).forEach((entry) => {
      if (entry[0] !== 'scheduleName') {
        currentTotal = currentTotal + +entry[1];
      }
      return currentTotal;
    });
    return currentTotal;
  };

  const seasonalityValidator = (params) => {
    const { data } = params;
    const currentTotal = seasonalityTotal(data);
    if (Math.round(currentTotal) !== 100) {
      setCallout({
        message: formatMessage('YOUR_MONTH_SEASONALITY_PLEASE_ADJUST', {
          value: percentageValueFormatter(currentTotal)
        }),
        intent: 'danger'
      });
      return false;
    }
    return true;
  };

  const valueValidator = (params) => {
    const { newValue } = params;
    const floatValue = parseFloat(newValue);
    if (isNaN(newValue)) return false;
    if (NUMBER_GREATER_THAN_ZERO.test(floatValue.toString())) return floatValue >= 0;
    return false;
  };

  const getRowJson = (data: ScheduleData) => {
    const input = {};
    const scheduleName = data.scheduleName;
    delete data.scheduleName;
    input[scheduleName] = data;

    // Fill in the default numbers if the cell is not edited
    Object.entries(data).forEach(([key, value]) => {
      data[key] = percentageValueFormatter(value);
    });

    // using initial data to only create column defs for month that exist
    // could be 3, 6, 12 months
    Object.keys(initialData[0]).forEach((key) => {
      // if the month is not set by user, assign the default value
      if (typeof data[key] === 'undefined' && key.includes('month')) {
        data[key] = selectedTable.tableDataType === LookupTypeEnum.Seasonality ? 0 : 100;
      }
    });
    return JSON.stringify(input);
  };

  const handleAddNewRow = async (params, type: string) => {
    const isValid = type === LookupTypeEnum.Seasonality ? seasonalityValidator(params) : rampValidator(params);
    if (isValid && !!params.data.scheduleName) {
      setCallout({ message: defaultCalloutMessage, intent: 'primary' });
      // Store the new schedule name. After the row is added, find the new row by the name.
      setNewScheduleName(params.data.scheduleName);
      upsertLookupRow({
        variables: {
          input: {
            lookupId: selectedTable?.tableId,
            rowJson: getRowJson(params.data)
          }
        },

        onCompleted() {
          setCallout({
            message: defaultCalloutMessage,
            intent: 'primary'
          });
          // fetch the latest data
          gridApi?.refreshServerSideStore({ purge: false });
          // reset top pinned row
          gridApi?.getPinnedTopRow(0)?.setData({});
          onRowUpserted?.();
        },
        onError() {
          showToast(formatMessage('UPSERT_LOOKUP_ROW_ERROR'), 'danger');
        }
      });
    } else if (isValid && !params.data.scheduleName) {
      setCallout({ message: formatMessage('SCHEDULE_NAME_EMPTY_ERROR'), intent: 'danger' });
    }
  };

  const rampValidator = (params) => {
    const { data } = params;
    const { scheduleName, ...months } = data;

    let isValid = true;

    Object.values(months).forEach((value) => {
      if (value > 100 || value < 0) {
        setCallout({ message: formatMessage('RAMP_VALUE_MESSAGE'), intent: 'danger' });
        isValid = false;
      }
    });

    return isValid;
  };

  const scheduleNameValidator = (params) => {
    const { api, newValue } = params;
    const scheduleNameList = [];
    api.forEachNode((node) => scheduleNameList.push(node.data.scheduleName));

    return !scheduleNameList.includes(newValue);
  };

  const onDuplicateNameFound = (params) => () => {
    const data = params.data;
    const field = params.colDef.field;
    const oldValue = params.oldValue;
    const newValue = params.newValue;

    data[field] = oldValue;
    if (newValue !== '') {
      setCallout({ message: formatMessage('SCHEDULE_NAME_EXIST_ERROR'), intent: 'danger' });
    }
    return false;
  };

  const onFail = (params) => () => {
    const data = params.data;
    const field = params.colDef.field;

    if (params.oldValue || params.oldValue === 0) {
      data[field] = params.oldValue;
    } else {
      data[field] = selectedTable?.tableDataType === LookupTypeEnum.Seasonality ? '0' : '100';
    }
    return false;
  };

  const onSuccess = (params) => () => {
    const data = params.data;
    const field = params.colDef.field;
    data[field] = field !== 'scheduleName' ? percentageValueFormatter(params.newValue) : params.newValue;
    params.node.setData(data);
    return true;
  };

  const syncValidator = (params, validateFn, onSuccess, onFail) => {
    if (validateFn(params)) {
      onSuccess();
    } else {
      onFail();
    }
    return true;
  };

  const syncValueSetter = (params, validateFn) => {
    syncValidator(params, validateFn, onSuccess(params), onFail(params));
    return true;
  };

  const buildLookupTableColumnDef = (type: string): ColDef[] => {
    const columnDefs: ColDef[] = [];
    const MIN_COLUMN_WIDTH = 81; // minimum width to avoid cutting off text

    columnDefs.push({
      headerName: formatMessage('SCHEDULE_NAME'),
      field: 'scheduleName',
      flex: 2,
      minWidth: SCHEDULE_COLUMN_WIDTH,
      rowDrag: false,
      valueFormatter: (params) => {
        return params.value || formatMessage('ENTER_NAME');
      },
      cellStyle: (params) => {
        if (params.node.rowPinned && !params.value) {
          return { color: DEFAULT_PLACEHOLDER_COLOR };
        }
        return { color: 'rgb(var(--color-gray-2))' };
      },
      valueGetter: (params) => {
        return params?.data?.scheduleName;
      },
      valueSetter: (params) =>
        syncValidator(params, scheduleNameValidator, onSuccess(params), onDuplicateNameFound(params))
    });

    // using initial data to only create column defs for month that exist
    // could be 3, 6, 12 months
    Object.keys(initialData[0]).forEach((key) => {
      if (key.includes('month')) {
        columnDefs.push({
          headerName: formatMessage('MONTH', { value: key.split('_')[1] }),
          field: key,
          flex: 1,
          minWidth: MIN_COLUMN_WIDTH,
          rowDrag: false,
          cellStyle: (params) => {
            if (params.node.rowPinned && isNaN(params.value)) {
              return { color: DEFAULT_PLACEHOLDER_COLOR };
            }
            return { color: 'rgb(var(--color-gray-2))' };
          },
          valueFormatter: (params) => {
            const defaultValue = type === LookupTypeEnum.Ramp ? 100 : 0;
            return `${(params.value >= 0 ? percentageValueFormatter(params.value) : defaultValue).toString()}%`;
          },

          valueSetter: (params) => syncValueSetter(params, valueValidator)
        });
      }
    });

    columnDefs.push({
      headerName: '',
      field: '',
      flex: 1,
      minWidth: MIN_COLUMN_WIDTH,
      rowDrag: false,
      editable: false,
      cellStyle: { border: 'none' },
      cellRendererSelector: (params) => {
        if (params?.node?.rowPinned) {
          return {
            frameworkComponent: EditRowCellRenderer,
            params: {
              onSaveButtonClicked: () => handleAddNewRow(params, type),
              onCancelButtonClicked: () => {
                params.node.setData({});
              }
            }
          };
        } else {
          return {
            frameworkComponent: ''
          };
        }
      }
    });

    return columnDefs;
  };

  const defaultColDef = {
    editable: (object) => object.node.isRowPinned()
  };

  const onGetRowNodeId = (row) => {
    return row.scheduleName;
  };

  useEffect(() => {
    const calloutMessage =
      selectedTable?.tableDataType === LookupTypeEnum.Seasonality
        ? `${formatMessage('SEASONALITY_SUM_MESSAGE')} ${formatMessage('PERCENTAGE_VALUE_MESSAGE')}`
        : formatMessage('RAMP_TABLE_MESSAGE');
    setCallout({
      message: calloutMessage,
      intent: 'primary'
    });
  }, [selectedTable]);

  const onGridReady = (gridEvent) => {
    setGridApi(gridEvent?.api);
  };

  useEffect(() => {
    if (shouldFlash) {
      gridApi?.forEachNode((node) => {
        if (node.data.scheduleName === newScheduleName) {
          // scroll to the position where the node is on the middle of the screen
          gridApi.ensureIndexVisible(node.rowIndex, 'middle');
          gridApi.flashCells({ rowNodes: [node], fadeDelay: 2000 });
        }
      });
      setShouldFlash(false);
    }
  }, [shouldFlash]);

  const lookupGridProps = {
    singleClickEdit: true,
    suppressCellSelection: true,
    stopEditingWhenCellsLoseFocus: true,
    serverSideStoreType: 'full' as ServerSideStoreType,
    rowModelType: 'serverSide',
    serverSideDatasource: {
      getRows: async (params) => {
        const data = await loadLookupTableData();
        params.success({ rowData: data, rowCount: data?.length });
        setShouldFlash(true);
      }
    },
    ...(showTopPinnedRow && { pinnedTopRowData: [{}] })
  };

  return (
    <div className={b()} data-testid="lookup-table-detail">
      {showHeader && (
        <div className={b('header')} data-testid="lookup-table-detail-header">
          <div className={b('leftActionItems')}>
            <IconButton
              className={b('backIcon')}
              type="button"
              icon={<ArrowLeft />}
              onClick={handleBackClicked}
              testId="lookup-table-detail-header-back-icon"
            />
            <span className={b('tableTitle')}>
              <HTMLHeading tagLevel={'h5'} text={selectedTable?.tableName} data-testid="lookup-table-title" />
            </span>
          </div>
        </div>
      )}
      <div className={b('tableContainer')}>
        {showHeader && (
          <div className={b('fileType')}>
            <span data-testid="table-type-label">{formatMessage('DATA_TABLE_TYPE_WITH_COLON')}</span>
            <span className={b('fileTypeText')} data-testid="table-type">
              {selectedTable?.tableDataType}
            </span>
          </div>
        )}
        {showTopPinnedRow && callout && (
          <Callout intent={callout.intent} className={b('calloutDanger')}>
            <p data-testid="lookup-table-callout-message" className={b('calloutMessage')}>
              {callout.message}
            </p>
          </Callout>
        )}
        <div className={`ag-theme-alpine ${b('grid')}`} ref={containerRef}>
          {!!selectedTable?.tableDataType && !!initialData && (
            <AdvancedGrid
              columnDefs={buildLookupTableColumnDef(selectedTable?.tableDataType)}
              defaultColDef={defaultColDef}
              getRowNodeId={onGetRowNodeId}
              gridProps={lookupGridProps}
              data-testid="lookup-tables-grid"
              onGridReady={onGridReady}
              animateRows={true}
              gridWidth={containerRef?.current?.offsetWidth}
              gridHeight={containerRef?.current?.offsetHeight}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LookupTableDetail;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ColumnApi, GridApi, GridOptions, ICellRendererParams, ServerSideStoreType } from '@ag-grid-community/core';
import { Search } from '@carbon/icons-react';
import debounce from 'lodash.debounce';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import { CommandCenterHierarchyPanelMultiSelectedToolbar } from 'app/components/CommandCenterHierarchyPanel/CommandCenterHierarchyPanelMultiSelectedToolbar';
import { CommandCenterHierarchyPanelToolbar } from 'app/components/CommandCenterHierarchyPanel/CommandCenterHierarchyPanelToolbar';
import ConfirmDeleteHierarchyModal from 'app/components/CommandCenterHierarchyPanel/ConfirmDeleteHierarchyModal';
import HierarchyNodeCellRenderer from 'app/components/CommandCenterHierarchyPanel/HierarchyNodeCellRenderer';
import CommandCenterHierarchyPanelContent from 'app/components/CommandCenterHierarchyPanelContent/CommandCenterHierarchyPanelContent';
import HierarchySearch from 'app/components/HierarchySearchDialog/HierarchySearch/HierarchySearch';

import { debounceDelay } from 'app/constants/DebounceConstants';

import { useCommandCenter } from 'app/contexts/commandCenterProvider';
import { useScope } from 'app/contexts/scopeProvider';

import { DEFAULT_BLOCK_SIZE, MENU_INFINITE_SCROLL_ITEM_HEIGHT } from 'app/global/variables';

import {
  GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies,
  GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies,
  GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
} from 'app/graphql/generated/apolloTypes';

import useShowToast from 'app/hooks/useShowToast';

import { CommandCenterDrawerState, HierarchySpec, HierarchyType, SelectedState } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import hierarchyEmpty from 'assets/pngs/hierarchy_empty.png';

import style from './CommandCenterHierarchyPanel.module.pcss';
import { getHierarchyData } from './hooks/useGetSubtreeHierarchy';
import { useUpdateHierarchyParent } from './hooks/useUpdateHierarchyParent';
import { StandardGeoCatalogModal } from './StandardGeoCatalogModal';

const b = block(style);

interface CommandCenterHierarchyPanelProps {
  selectedHierarchy: HierarchySpec;
}

export interface CommandCenterHierarchyGridContext {
  isReadOnly: boolean;
  hierarchyType: HierarchyType;
}

const CommandCenterHierarchyPanel: React.FC<CommandCenterHierarchyPanelProps> = ({
  selectedHierarchy
}: CommandCenterHierarchyPanelProps) => {
  const { selectedPlanningCycle } = useScope();
  const { setCommandCenterDrawerState, forceRefresh, setForceRefresh } = useCommandCenter();

  const [showDeleteHierarchyDialog, setShowDeleteHierarchyDialog] = useState<boolean>(false);
  // gridApi for the original tree
  const [gridApi, setGridApi] = useState<GridApi>(null);
  // gridApi for the filtered tree
  const [filteredGridApi, setFilteredGridApi] = useState<GridApi>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi>(null);
  // Need to keep track of the previous selected hierarchy to know if the active hierarchy is changed
  // in order to avoid rerendering the entire tree on node creation/deletion
  const [prevSelectedHierarchy, setPrevSelectedHierarchy] = useState(selectedHierarchy);
  const [initialBlock, setInitialBlock] = useState<
    | GetSubtreeCustomHierarchies_getSubtreeCustomHierarchies
    | GetSubtreeCustomerAccountHierarchies_getSubtreeCustomerAccountHierarchies
    | GetSubtreeGeographicTerritoryHierarchies_getSubtreeGeographicTerritoryHierarchies
  >(null);
  const [selectedNode, setSelectedNode] = useState<ICellRendererParams>(null);

  const [sourceNodes, setSourceNodes] = useState(null);
  const [destNode, setDestNode] = useState<ICellRendererParams['node']>(null);
  const [multiSelectedNodes, setMultiSelectedNodes] = useState<ICellRendererParams['node'][]>([]);

  // For passing to HierarchySearch and conditional renderer
  const [searchString, setSearchString] = useState<string>('');
  // For displaying the value in the input box
  const [inputString, setInputString] = useState<string>('');

  const [blockSize, setBlockSize] = useState(DEFAULT_BLOCK_SIZE);
  const [showAddStandardGeoDialog, setShowAddStandardGeoDialog] = useState(false);
  const hierarchyPanelGridContainerRef = React.useRef(null);

  const showToast = useShowToast();

  const hierarchyType = HierarchyType[selectedHierarchy.hierarchyType];
  const isReadOnly = hierarchyType === HierarchyType.GeographicTerritoryHierarchy;

  useEffect(() => {
    if (hierarchyPanelGridContainerRef?.current?.offsetHeight) {
      setBlockSize(
        Math.round((hierarchyPanelGridContainerRef?.current?.offsetHeight / MENU_INFINITE_SCROLL_ITEM_HEIGHT) * 2)
      );
    }
  }, [hierarchyPanelGridContainerRef]);

  useEffect(() => {
    if (forceRefresh) {
      setInitialBlock(null);
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  const [updateHierarchyParent] = useUpdateHierarchyParent(
    sourceNodes,
    destNode,
    gridApi,
    setInitialBlock,
    setMultiSelectedNodes
  );

  const loadHierarchyData = useCallback(
    async (hierarchyId: number, startRow: number, endRow: number, isFirstLevel = false) => {
      const result = await getHierarchyData(hierarchyType, {
        planningCycleId: selectedPlanningCycle?.id,
        hierarchyId,
        depth: 1,
        startRow,
        endRow
      });

      if (isFirstLevel) {
        setInitialBlock(result);
      }
      return result;
    },
    [selectedHierarchy]
  );

  useEffect(() => {
    // Only rerender the tree when the active hierarchy changes (eg. Customer Account -> Industry).
    // We don't want to rerender the tree on node creation/deletion
    if (selectedHierarchy?.rootHierarchyId !== prevSelectedHierarchy?.rootHierarchyId) {
      setInitialBlock(null);
      setPrevSelectedHierarchy(selectedHierarchy);
      setMultiSelectedNodes([]);
      setSearchString('');
      setInputString('');
    }
  }, [selectedHierarchy, blockSize, loadHierarchyData, prevSelectedHierarchy]);

  useEffect(() => {
    if (!initialBlock && blockSize) {
      loadHierarchyData(selectedHierarchy?.rootHierarchyId, 1, blockSize, true);
    }
  }, [initialBlock, blockSize, loadHierarchyData]);

  const handleOnGridReady = (gridEvent) => {
    setGridApi(gridEvent?.api);
    setColumnApi(gridEvent?.columnApi);

    const dataSource = {
      getRows: async (params) => {
        const startRow = params.request.startRow + 1;
        const endRow = params.request.startRow + blockSize;

        const parentNodeHierarchyId = params?.parentNode?.data?.hierarchyId;
        const isFirstLevelNode = !parentNodeHierarchyId;

        const setData = (newNodes) => {
          // Calculate the total items that have been loaded as the last block may not be full
          const totalCount = params.request.endRow - blockSize + newNodes.length;
          // If there is not enough nodes in the new block which means there is no more data, stop fetching
          if (newNodes.length < blockSize) {
            params.success({ rowData: newNodes, rowCount: totalCount });
          } else {
            // Else, set null as the total count so that ag grid will keep fetching
            params.success({ rowData: newNodes, rowCount: null });
          }
        };

        if (!isFirstLevelNode) {
          // handle nodes from the second level
          const { items: newNodes } = await loadHierarchyData(parentNodeHierarchyId, startRow, endRow);
          setData(newNodes);
        } else {
          // handle nodes on the first level

          // If the first block already contains the full data, we don't need to fetch more
          if (initialBlock.items.length === initialBlock.totalCount) {
            params?.success({ rowData: initialBlock.items, rowCount: initialBlock.items.length });
            return;
          }

          // Skip the first block since it has been loaded on the initial load
          if (startRow === 1) {
            params.success({ rowData: initialBlock.items, rowCount: null });
          } else {
            const { items: newNodes } = await loadHierarchyData(selectedHierarchy?.rootHierarchyId, startRow, endRow);
            setData(newNodes);
          }
        }
      }
    };
    gridEvent.api.setServerSideDatasource(dataSource);
  };

  const refreshRows = (api, rowsToRefresh) => {
    const params = {
      rowNodes: rowsToRefresh,
      force: true
    };
    api?.refreshCells(params);
  };

  let potentialParent;

  const updatePotentialParentForNode = (api, node, overNode) => {
    let newPotentialParent = null;
    const isSelf = overNode === node;
    const isOriginalParent = overNode === node?.parent;
    if (!isSelf && !isOriginalParent) {
      newPotentialParent = overNode;
    }

    const alreadySelected = potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }

    // we refresh the previous selection (if it exists) to clear
    // the highlighted and then the new selection.
    const rowsToRefresh = [];
    if (potentialParent) {
      rowsToRefresh.push(potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }

    potentialParent = newPotentialParent;

    refreshRows(api, rowsToRefresh);
  };

  // Keep only the parent node if all its children and itself are selected.
  const getFilteredParentNodes = (nodes) => {
    const filteredNodes = [];
    nodes.forEach((node) => {
      if (!node['parent']?.isSelected()) {
        filteredNodes.push(node);
      }
    });
    return filteredNodes;
  };

  const handleRowDragMove = (e) => {
    updatePotentialParentForNode(e.api, e.node, e.overNode);
  };

  const handleRowDragLeave = (e) => {
    updatePotentialParentForNode(e.api, e.node, null);
  };

  const handleRowDragEnd = (e) => {
    // 1. Get draggedNodes from ag grid -> this list contains all the selected nodes.
    // eg. If a node and all its 5 children are selected, it will have 6 nodes in it.
    const draggedNodes = Object.values(
      Object.values(e?.api?.selectionService?.selectedNodes).filter((node) => node !== undefined)
    );

    // 2. If the draggedNodes list is empty, it means it is single reparenting, then add the source node to the list
    if (draggedNodes?.length === 0) {
      draggedNodes.push(e.node);
    }

    // 3. Get the ids for the draggedNodes
    const draggedNodeIds = draggedNodes.map((node) => node?.['data']?.hierarchyId);

    let isValid = true;

    // 4. Check if the reparenting is valid
    // If the dragged node is not selected in multi-select view, do nothing
    // If nothing is selected, still able to do drag and drop
    if (draggedNodeIds?.length > 0 && !draggedNodeIds.includes(e.node?.data?.hierarchyId)) {
      isValid = false;
    }

    // If the target node is the source node's original parent or itself, do nothing
    draggedNodes.forEach((node) => {
      if (node === e.overNode || node['parent'] === e.overNode) {
        isValid = false;
      }
    });

    // 5. Get filteredNodes -> this contains only the parent node if all of itself and its children are in the draggedNodes list
    // eg. If a node and all its 5 children are in draggedNodes, filteredNodes list only keeps the parent node
    const filteredNodes = getFilteredParentNodes(draggedNodes);

    // 6. Get the ids for the filteredNodes
    const filteredNodeIds = filteredNodes.map((node) => node?.['data']?.hierarchyId);

    // 7. If the reparenting is valid, call API with the filteredNodeIds
    if (isValid && e.overNode) {
      setSourceNodes(filteredNodes);
      setDestNode(e.overNode);

      updateHierarchyParent({
        variables: {
          hierarchyIds: filteredNodeIds,
          newParentHierarchyId: e.overNode.data.hierarchyId,
          planningCycleId: selectedPlanningCycle?.id
        }
      });
      showToast(formatMessage('REPARENTING'), 'warning');
    }
    updatePotentialParentForNode(e.api, e.node, null);
  };

  const handleRowClass = (params) => {
    if (params.columnApi?.checkbox) {
      return '';
    }
    return style.CommandCenterHierarchyPanel_noCheckbox;
  };

  const gridContext: CommandCenterHierarchyGridContext = {
    isReadOnly,
    hierarchyType
  };

  const baseTreeGridProps: GridOptions = {
    rowSelection: null,
    suppressRowDrag: true,
    rowMultiSelectWithClick: false,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    treeData: true,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    headerHeight: 0,
    rowHeight: MENU_INFINITE_SCROLL_ITEM_HEIGHT,
    cacheBlockSize: blockSize,
    context: gridContext,
    getRowClass: handleRowClass,
    getRowStyle: () => ({ border: 'none', background: 'white' }),
    onGridReady: handleOnGridReady
  };

  const editableTreeGridProps: GridOptions = {
    rowSelection: 'multiple',
    rowDragMultiRow: true,
    suppressRowDrag: false,
    onRowDragMove: handleRowDragMove,
    onRowDragLeave: handleRowDragLeave,
    onRowDragEnd: handleRowDragEnd,
    onRowClicked: (rowEvent) => handleRowClicked(rowEvent)
  };

  const hierarchyTreeGridProps = isReadOnly ? baseTreeGridProps : { ...baseTreeGridProps, ...editableTreeGridProps };

  const handleRowClicked = (rowEvent) => {
    rowEvent.node.setSelected(true);
    if (!rowEvent.columnApi['checkbox']) {
      // If user click on a node to enable the multi-select mode when there is a node being selected for edit,
      // need to find the node and deselect it first.
      const prevSelectedNode = Object.values(rowEvent?.api?.['selectionService']?.selectedNodes)?.filter(
        (node) => node !== undefined && node['data'].hierarchyId !== rowEvent.node['data'].hierarchyId
      )?.[0];
      prevSelectedNode?.['setSelected'](false);
      setSelectedNode(null);

      rowEvent.columnApi['checkbox'] = true;
      rowEvent.api?.redrawRows();
    }
  };

  const handleAddChildUnderRoot = () => {
    const newNode = {
      data: {
        hierarchyId: null,
        name: '',
        key: '',
        parentKey: selectedHierarchy?.rootKey,
        address1: '',
        address2: '',
        city: '',
        country: '',
        industry: '',
        zipPostal: '',
        stateProvince: '',
        parent: null
      }
    } as ICellRendererParams;
    setSelectedNode(newNode);
    setCommandCenterDrawerState(CommandCenterDrawerState.EXPAND);
  };

  const handleAddStandardGeo = () => {
    setShowAddStandardGeoDialog(!showAddStandardGeoDialog);
  };

  const onDeselectAll = () => {
    const api = searchString === '' ? gridApi : filteredGridApi;
    api?.deselectAll();
    columnApi['checkbox'] = false;
    api?.redrawRows();
    setMultiSelectedNodes([]);
  };

  // Reset selected nodes when search string changes
  useEffect(() => {
    setMultiSelectedNodes([]);
  }, [searchString]);

  const onRowSelected = (rowSelectedEvent) => {
    const targetNode = rowSelectedEvent?.node;
    const targetNodeData = rowSelectedEvent?.data;

    // In single select mode
    if (!targetNode || !targetNodeData || !rowSelectedEvent?.columnApi?.['checkbox']) {
      return;
    }

    // In multi select mode
    if (targetNode.isSelected()) {
      targetNode['selectedState'] = SelectedState.SELECTED;

      // when selecting a node
      // loop through each node and if it is the children of current node
      // set it to selected
      rowSelectedEvent.api.forEachNode((eachNode) => {
        if (eachNode.parent?.data?.hierarchyId === targetNodeData.hierarchyId) {
          eachNode.setSelected(true);
        }
      });

      // will only add parent node to the multiSelectedNode state
      setMultiSelectedNodes((prevState) => {
        if (targetNode.parent?.isSelected()) {
          return prevState;
        }
        return [...prevState, rowSelectedEvent.node];
      });
    } else {
      // when de-selecting a node
      // loop through each node to deselect all its children if it is not in the intermediate state
      // and deselect its parent
      rowSelectedEvent.api.forEachNode((eachNode) => {
        // deselect all the children when the current node is not in an intermediate state
        if (
          targetNode.selectedState !== SelectedState.INTERMEDIATE &&
          eachNode?.parent?.data?.hierarchyId === targetNodeData.hierarchyId
        ) {
          eachNode.setSelected(false);
        }

        // deselect parents
        if (
          eachNode.data?.hierarchyId === targetNode.parent?.data?.hierarchyId &&
          targetNode.parent?.data?.hierarchyId !== null
        ) {
          eachNode['selectedState'] = SelectedState.INTERMEDIATE;
          eachNode.setSelected(false);
        }

        // when deselecting, sibling elements without a selected parent that is still checked should be added to the multiSelectedNodes
        if (eachNode?.isSelected() && !eachNode.parent.isSelected()) {
          setMultiSelectedNodes((prevState) => {
            const prevStateIds = prevState.map((node) => node.data.hierarchyId);
            return prevStateIds.includes(eachNode.data.hierarchyId) ? prevState : [...prevState, eachNode];
          });
        }
      });

      // on deselect removes node from multiSelectedNode if exist on state
      setMultiSelectedNodes((prevState) => {
        const newState = prevState.filter((node) => {
          return node.data.hierarchyId !== targetNodeData.hierarchyId;
        });
        if (newState.length === 0) {
          // redrawRows when checkbox was enabled before
          if (rowSelectedEvent.columnApi?.['checkbox']) {
            rowSelectedEvent.columnApi['checkbox'] = false;
            rowSelectedEvent.api.redrawRows();
          }
        }
        return newState;
      });
    }
  };

  const hierarchySearchGridStyles = {
    rowHeight: MENU_INFINITE_SCROLL_ITEM_HEIGHT,
    getRowStyle: hierarchyTreeGridProps?.getRowStyle
  };

  const handleSearch = (debounceHandlerResult) => {
    setSearchString(debounceHandlerResult);
  };

  const debounceHandler = useRef(debounce(handleSearch, debounceDelay));

  const getAllHierarchiesVariables = {
    planningCycleId: selectedPlanningCycle.id,
    searchHierarchyInput: {
      hierarchyType,
      searchString,
      rootHierarchyId: selectedHierarchy.rootHierarchyId,
      getTree: true,
      startRow: 0,
      endRow: blockSize,
      includeDetails: true
    }
  };

  const innerRendererSelector = (params) => {
    return {
      frameworkComponent: HierarchyNodeCellRenderer,
      params: {
        params,
        rootHierarchy: selectedHierarchy,
        setSelectedNode,
        setCommandCenterDrawerState,
        setInitialBlock,
        searchString,
        setSearchString,
        getAllHierarchiesVariables
      }
    };
  };

  return (
    <div className={b()} data-testid="command-center-hierarchy-panel">
      <div className={b('middlePanel')} data-testid="command-center-hierarchy-middle-panel">
        {multiSelectedNodes.length > 0 ? (
          <CommandCenterHierarchyPanelMultiSelectedToolbar
            multiSelectedNodes={multiSelectedNodes}
            onMultiDelete={() => setShowDeleteHierarchyDialog(true)}
            onDeselectAll={onDeselectAll}
          />
        ) : (
          <CommandCenterHierarchyPanelToolbar
            hierarchyType={hierarchyType}
            handleAddChildUnderRoot={handleAddChildUnderRoot}
            handleAddStandardGeo={handleAddStandardGeo}
          />
        )}
        <div className={b('searchBox')}>
          <Search size={20} />
          <input
            value={inputString}
            placeholder={formatMessage('SEARCH')}
            onChange={(event) => {
              setInputString(event.target.value);
              debounceHandler.current(event.target.value);
            }}
            data-testid="hierarchy-search-box"
          />
        </div>
        {searchString === '' && (
          <div className={b('middlePanelContent')}>
            {initialBlock?.items?.length === 0 && (
              <div className={b('validationMessage')} data-testid={'empty-hierarchy-tree-message'}>
                {formatMessage('NO_HIERARCHIES')}
              </div>
            )}
            <div className={b('fullWidthGrid')} ref={hierarchyPanelGridContainerRef}>
              <AdvancedGrid
                autoGroupColumnDef={{
                  field: 'name',
                  cellClassRules: {
                    'dnd-hover-over': (params) => params.node === potentialParent
                  },
                  cellRendererParams: {
                    innerRendererSelector
                  },
                  checkboxSelection: isReadOnly
                    ? false
                    : (params) => {
                        if (params.node.parent.isSelected()) {
                          params.node.setSelected(true);
                        }
                        return true;
                      },
                  rowDragText: (params, draggedNodeCount) => {
                    if (draggedNodeCount > 1) {
                      return formatMessage('MEMBERS_WITH_COUNT', { count: draggedNodeCount });
                    }
                    return params?.defaultTextValue;
                  }
                }}
                onRowSelected={onRowSelected}
                animateRows={true}
                gridProps={hierarchyTreeGridProps}
                isServerSideGroup={(dataItem) => dataItem?.hasChildren}
                getServerSideGroupKey={(dataItem) => dataItem?.name}
                gridWidth={200} // set the gridWidth to match the COLUMN_WIDTH in the loading cell renderer to show only one skeleton
                gridHeight={hierarchyPanelGridContainerRef?.current?.offsetHeight}
                showGridLoading={!initialBlock}
                data-testid="hierarchy-panel-grid"
              />
            </div>
          </div>
        )}
        {searchString !== '' && (
          <HierarchySearch
            searchString={searchString}
            hierarchyType={hierarchyType}
            rootHierarchyId={selectedHierarchy.rootHierarchyId}
            planningCycleId={selectedPlanningCycle?.id}
            onSelect={onRowSelected}
            styles={hierarchySearchGridStyles}
            blockSize={blockSize}
            enableCheckboxes
            setFilteredGridApi={setFilteredGridApi}
            onRowClicked={handleRowClicked}
            getRowClass={handleRowClass}
            innerRendererSelector={innerRendererSelector}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
      <div className={b('expandedPanel')} data-testid="command-center-hierarchy-expanded-panel">
        {selectedNode && (
          <CommandCenterHierarchyPanelContent
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            hierarchyType={hierarchyType}
            rootHierarchy={selectedHierarchy}
            setInitialBlock={setInitialBlock}
            searchString={searchString}
            getAllHierarchiesVariables={getAllHierarchiesVariables}
            setSearchString={setSearchString}
            isReadOnly={isReadOnly}
          />
        )}
        {!selectedNode && (
          <div className={b('hierarchyEmpty')} data-testid="hierarchy-empty">
            <img src={hierarchyEmpty} alt={formatMessage('HIERARCHY_EMPTY')} />
            <div className={b('hierarchyEmptyMessage')}>{formatMessage('EMPTY_HIERARCHY_MESSAGE')}</div>
          </div>
        )}
      </div>
      <ConfirmDeleteHierarchyModal
        isMultiDelete={true}
        selectedNode={null}
        rootHierarchy={selectedHierarchy}
        showDeleteHierarchyDialog={showDeleteHierarchyDialog}
        setShowDeleteHierarchyDialog={setShowDeleteHierarchyDialog}
        setSelectedNode={setSelectedNode}
        setInitialBlock={setInitialBlock}
        gridApi={gridApi}
        multiSelectedNodes={multiSelectedNodes}
        setMultiSelectedNodes={setMultiSelectedNodes}
        searchString={searchString}
        getAllHierarchiesVariables={getAllHierarchiesVariables}
        setSearchString={setSearchString}
      />
      {showAddStandardGeoDialog && <StandardGeoCatalogModal onClose={handleAddStandardGeo} showMapMessage={false} />}
    </div>
  );
};

export default CommandCenterHierarchyPanel;

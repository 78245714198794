import enUS from './en-US';

/* allow anonymous default export */
/* eslint-disable import/no-anonymous-default-export */

/* disallow template strings in messages file */
/* eslint quotes: ["error", "single", { "avoidEscape": true }] */

/* messages from this file should not be imported directly */

const ptBR: Partial<Record<keyof typeof enUS, string>> = {
  VARICENT_SP: 'Planejamento de vendas da Varicent',
  SALES_PLANNING: 'Planejamento de vendas',
  PLANNING_CYCLES: 'Ciclos de planejamento',
  TEXT_FILTERS: 'Filtros de texto',
  NEW_CYCLE: 'Novo ciclo',
  DATA: 'Dados',
  DELETE: 'Excluir',
  NEW: 'Novo',
  RUN: 'Executar',
  NEW_PLANNING_CYCLE: 'Novo ciclo de planejamento',
  PLANNING_CYCLE: 'Ciclo de planejamento',
  DONT_SAVE: 'Não salvar',
  SAVE: 'Salvar',
  CANCEL: 'Cancelar',
  BACK: 'Voltar',
  COMPLETE: 'Completo',
  UNTITLED: 'Sem título',
  START_DATE_REQUIRED_MARK: 'Data inicial*',
  START_DATE_MONTH_REQUIRED_MARK: 'Data inicial (mês) *',
  DATE_FORMAT_PLACEHOLDER: 'MM/DD/YYYY',
  INTERVAL_CYCLE_REQUIRED_MARK: 'Ciclo de intervalo *',
  PERIODICITY: 'Periodicidade',
  PLAN_DURATION: 'Duração do plano',
  CREATE: 'Criar',
  AN_ERROR_OCCURRED: 'Ocorreu um erro.',
  PLANNING_CYCLE_ERROR_EMPTY: 'Insira um título para seu ciclo de planejamento.',
  PLANNING_CYCLE_ERROR: 'Não é possível recuperar seus ciclos de planejamento do servidor.',
  PLANNING_CYCLE_COPY_PROGRESS_DESCRIPTION:
    'Estamos copiando seu ciclo de planejamento {name}. Não faça alterações em seu ciclo de planejamento até que este processo seja concluído',
  PLANNING_CYCLE_PROGRESS_HEADING: 'Cópia do ciclo de planejamento iniciada',
  PLANNING_CYCLE_COPIED: 'Ciclo de planejamento copiado',
  PLANNING_CYCLE_COPIED_DESCRIPTION: 'Copiamos seu ciclo de planejamento {pcName}.',
  PLANNING_CYCLE_COPIED_FAILED: 'Falha na cópia do ciclo de planejamento',
  CREATE_NEW_CYCLE_ERROR: 'Não é possível criar um novo ciclo de planejamento.',
  CREATE_NEW_CYCLE_SUCCESS: 'Criou seu novo ciclo de planejamento.',
  NUM_OF_MONTHS: 'Número de meses',
  BATTLECARD_CANVAS_ERROR: 'Não foi possível recuperar seus cartões de batalha do servidor.',
  BATTLECARD_ERROR: 'Não foi possível recuperar seu cartão de batalha do servidor.',
  CREATE_NEW_BATTLECARD_ERROR: 'Não foi possível criar um novo cartão de batalha.',
  CREATE_NEW_BATTLECARD_SUCCESS: 'Seu novo cartão de batalha foi criado.',
  UPDATE_BATTLECARD_SUCCESS: 'Seu cartão de batalha foi atualizado.',
  UPDATE_BATTLECARD_ERROR: 'Não foi possível atualizar seu cartão de batalha.',
  UPDATE_BATTLECARD_OWNER_ERROR: 'Não foi possível atualizar o proprietário do seu cartão de batalha.',
  UPDATE_ACTIVITY_FILES_ERROR: 'Não foi possível atualizar os arquivos de atividade do seu cartão de batalha.',
  UPDATE_QUOTA_COMPONENT_ERROR: 'Não foi possível atualizar o componente de cota do cartão de batalha.',
  REMOVE_QUOTA_COMPONENT_ERROR:
    'Você deve selecionar componente(s) de cota atribuído(s) ao pai deste cartão de batalha.',
  DISALLOW_REMOVE_QUOTA_COMPONENT:
    'Você deve remover os componentes da cota referenciados no cartão de batalha primário ou secundário acumulado antes que ele possa ser removida do cartão de batalha pai.',
  UPDATE_HIERARCHY_TOP_ERROR: 'Não foi possível atualizar a hierarquia superior.',
  UPDATE_NEW_BUSINESS_TARGET_SUCCESS: 'Nova meta de negócios atualizada.',
  UPDATE_NEW_BUSINESS_TARGET_ERROR: 'Não foi possível atualizar a nova meta de negócios no seu cartão de batalha.',
  BATTLECARD_MEASURES_ERROR: 'Não foi possível recuperar medidas para o cartão de batalha.',
  UNABLE_TO_RETRIEVE_HIERARCHIES: 'Não foi possível recuperar suas hierarquias do servidor.',
  PIN_TYPE_ACCOUNT: 'Pins de conta',
  PIN_TYPE_CUSTOM: 'Outros pins',
  CLUSTERS: 'Clusters',
  HEAT_AND_PINS: 'Calor e pins',
  DEPLOYMENT_MODEL_ERROR: 'Não é possível recuperar seu modelo de implantação do servidor.',
  CUSTOMER_PINS_ERROR: 'Não foi possível recuperar os pins do cliente.',
  MAP_PINS_ERROR: 'Não foi possível recuperar os pins do mapa.',
  ACCOUNT: 'Conta',
  ACCOUNT_WITH_COUNT: '{totalCount} conta',
  ACCOUNTS: 'Contas',
  ACCOUNTS_WITH_COUNT: '{totalCount} contas',
  ACCOUNT_MOVE_DESCRIPTION: 'Moveremos sua conta {accountName} para {destinationText} em {startDate}.',
  ACCOUNT_MOVE_DESCRIPTION_2:
    'Moveremos sua conta {accountName} para {sourceTerritoryId} para {targetText} em {startDate}.',
  ACCOUNT_MOVE_DIALOG_TITLE: 'Agendar mudança para {accountName}',
  ACCOUNT_MOVE_DIALOG_CONFIRM: 'Agendar mudança',
  ACCOUNT_MOVE_DIALOG_BODY:
    'Revise o território selecionado e faça as alterações necessárias. Em seguida, adicione uma data de início para quando esta mudança entrar em vigor.',
  SCHEDULE_ACCOUNT_MOVE: 'Agendar mudança de conta',
  ACCOUNT_MOVE_SCHEDULED: 'Mudança de conta agendada',
  ACCOUNT_EFFECTIVE_DATE_UPDATED: 'Datas de entrada em vigor da conta atualizadas',
  UPDATED_EFFECTED_DATES:
    'Atualizamos as datas de entrada em vigor para as mudanças da sua conta {accountName} agendadas.',
  ORIGINAL_TERRITORY: 'Território original',
  PREVIOUS_TERRITORY: 'Território anterior',
  GAP_DETECTED: 'Lacuna detectada',
  SCHEDULED_MOVE: 'Mudança agendada',
  SCHEDULED_MOVES_WITH_COUNT: '{totalCount} mudanças agendadas',
  ONE_SCHEDULED_MOVE: '1 mudança agendada',
  ACCOUNT_NAME: 'Nome da conta',
  UNASSIGNED_TERRITORY: 'Território não definido',
  DELETE_REDIRECT: 'Excluir mudança de conta agendada?',
  DELETE_REDIRECT_DIALOGUE_BODY:
    'Excluir essa mudança de conta agendada para {accountName} criará uma lacuna e deixará sua conta não definida. Se você optar por excluir, selecione uma opção para cobrir a lacuna:',
  DELETE_REDIRECT_DIALOGUE_SOLO_DELETE_BODY:
    'Se você optar por excluir, a conta permanecerá em seu território original.',
  DELETE_REDIRECT_TOAST_TITLE: 'Mudança de conta agendada excluída',
  DELETE_REDIRECT_TOAST_BODY_ORIGINAL:
    'Excluímos a mudança agendada da sua conta para {accountName}. Moveremos esta conta de volta ao território original em {startDate}.',
  DELETE_REDIRECT_TOAST_BODY_END: 'Manteremos esta conta em {prevTerritoryName} até {endDate}.',
  DELETE_REDIRECT_ERROR: 'Não foi possível excluir a mudança da conta.',
  CREATE_REDIRECT_ERROR: 'Não foi possível mover a conta.',
  UPDATE_REDIRECT_ERROR: 'Não foi possível atualizar a mudança da conta.',
  EFFECTIVE_DATE: 'Data de entrada em vigor',
  ADD_TERRITORY: 'Adicionar um território',
  ADD_THIS_TERRITORY: 'Adicionar este território',
  EMPTY_PANEL_TEXT: 'Adicionar um território da lista à esquerda',
  ALLOCATE_TOP_DOWN: 'Alocar (de cima para baixo)',
  TERRITORY: 'Território',
  TERRITORY_WITH_COUNT: '{totalCount} território',
  TERRITORIES: 'Territórios',
  TERRITORIES_WITH_COUNT: '{totalCount} territórios',
  CURRENT_TERRITORY: 'Território atual',
  TERRITORY_TOGGLE_TOOLTIP: 'Isso permite que você selecione seus territórios existentes.',
  PLAN: 'Plano',
  MANAGE: 'Gerenciar',
  EXISTING: 'Existente',
  TOP_DOWN_TARGET: 'Alvo de cima para baixo',
  QUOTA_ADJUSTMENTS: 'Ajustes de cota',
  QUOTA: 'Cota',
  ADD_NUMBER: 'Adicionar número',
  BASED_ON: 'Baseado em',
  PRESERVE_QUOTA_ADJUSTMENTS: 'Preservar ajustes de cota',
  TERRITORY_DEFINE_REFINE: 'Definição e atribuição de território',
  CREATE_NEW_HIERARCHY_ERROR: 'Não foi possível criar uma nova hierarquia.',
  CREATE_NEW_HIERARCHY_SUCCESS: 'Sua nova hierarquia foi criada.',
  EDIT_TERRITORY_GROUP: 'Editar grupo de território',
  DELETE_TERRITORY_GROUP: 'Excluir grupo de território',
  ADD_TERRITORY_GROUP: 'Adicionar grupo de território',
  TERRITORY_GROUPS_ERROR: 'Não é possível recuperar grupos de território do servidor.',
  TERRITORY_GROUP_ERROR: 'Não é possível recuperar informações de grupo de território do servidor.',
  COUNT_WITH_NUMBER: 'Contagem {number}',
  COUNT_WITH_FRACTION: 'Contagem {numerator} / {denominator}',
  AVERAGE: 'Média',
  AVERAGE_WITH_NUMBER: 'Média {number}',
  STATUS: 'Status',
  VIEW: 'Visualizar',
  VIEW_WITH_ELLIPSIS: 'Visualizar…',
  HIERARCHY_ERROR: 'Não é possível recuperar dados de hierarquia.',
  HIERARCHY_MOVE_ERROR: 'Não é possível mover hierarquia.',
  HIERARCHY_MOVE_SUCCESS: 'Hierarquia movida com sucesso.',
  TOP_DOWN_ALLOCATION_ERROR: 'Não é possível executar alocação.',
  CREATE_NEW_TERRITORY_GROUP_SUCCESS: 'Seu novo grupo de território foi criado.',
  CREATE_NEW_TERRITORY_GROUP_ERROR: 'Não foi possível criar um novo grupo de território.',
  NEW_BUSINESS_TARGET: 'Nova meta de negócios',
  BUSINESS_TARGET: 'Meta de negócios',
  PLANNED_TERRITORY_QUOTA: 'Cota de território planejada',
  UPDATED_QUOTA: 'Cota atualizada',
  UNDERALLOCATED_BY: 'Subalocado por {amount}',
  OVERALLOCATED_BY: 'Superalocado por {amount}',
  SELECT_BATTLE_CARD_TO_VIEW_TERRITORIES:
    'Selecione um cartão de batalha principal, tipo de grupo de território ou grupo de território para visualizar territórios',
  EMPTY_GRID: 'Nenhum dado no momento',
  NEW_BUSINESS_TARGET_MEASURE_PLACEHOLDER: 'Definir meta',
  BATTLECARD_MEASURE_PLACEHOLDER: 'Definir valor',
  COINSORT_PROCESS_SUCCESS: 'A classificação foi executada com sucesso.',
  ACTIVITIES_SORTED_INTO: '{activityCount} atividades foram classificadas em {territoryCount} territórios.',
  ACTIVITIES_UNASSIGNED: '{activityCount} atividades não foram definidas.',
  COINSORT_PROCESS_FAILURE: 'Não é possível executar o classificação de moedas.',
  COINSORT_FETCH_ERROR: 'Não é possível obter o progresso da classificação de moedas.',
  COINSORT_CANCEL_ERROR: 'Não é possível cancelar o processo de classificação de moedas.',
  COINSORT_CHECK_ERROR: 'Não é possível determinar se a classificação de moedas pode ser executada.',
  COINSORT_RUN_ERROR_TITLE: 'Falha na classificação de moedas',
  COINSORT_CANCEL_ERROR_DESC: 'A classificação das moedas não está em execução, então não há nada para cancelar.',
  COINSORT_GENERIC_ERROR_DESC:
    'Não foi possível executar a classificação de moedas porque tivemos alguns problemas. Atualize a página e tente novamente.',
  COINSORT_RUN_ERROR_DESC:
    'Não é possível executar a classificação de moedas no momento porque já existe uma instância de classificação de moedas em execução. Aguarde a conclusão da instância atual e tente novamente.',
  COINSORT_MISSING_TERRITORY_GROUP_ERROR_DESC:
    'Você ainda não definiu nenhum grupo de territórios, então não foi possível classificar suas atividades de vendas. Crie alguns grupos de território e tente novamente.',
  COINSORT_ACCESS_DENIED_ERROR_DESC:
    'Você não tem permissão para executar a classificação de moedas. Entre em contato com o administrador do Varicent Sales Planning para obter ajuda.',
  COINSORT_MISSING_ACTIVITY_ERROR_DESC:
    'Você não adicionou nenhum dado de atividade de vendas para este cartão de batalha, então não há nada para classificarmos. Adicione dados de atividade e tente novamente.',
  COINSORT_MISSING_TERRITORY_RULE_ERROR_DESC:
    'Você ainda não tem nenhuma regra de territórios, então não foi possível classificar suas atividades de vendas. Crie algumas regras de território e tente novamente.',
  DELETE_NEW_TERRITORY_GROUP_SUCCESS: 'Seu grupo de território foi excluído.',
  DELETE_NEW_TERRITORY_GROUP_ERROR: 'Não foi possível excluir seu grupo de territórios.',
  CONFIRM_DELETE: 'Tem certeza de que deseja excluir?',
  DELETE_GROUP_WARNING: 'A exclusão removerá este grupo de territórios.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_BODY:
    'Este grupo de territórios tem territórios. Mova os territórios para outro grupo de territórios antes da exclusão.',
  DELETE_GROUP_WITH_TERRITORIES_WARNING_HEADER: 'Falha ao excluir grupo de territórios',
  LOGOUT: 'Fazer logoff',
  TERRITORY_ID: 'ID do território',
  TERRITORY_ID_WITH_ID: 'ID do território {territoryID}',
  TERRITORY_NAME: 'Nome do território',
  TERRITORY_NAME_REQUIRED_MARK: 'Nome do território *',
  TERRITORY_GROUP_REQUIRED: 'O grupo de territórios é obrigatório',
  BALANCED: 'Equilibrado',
  BATTLE_CARD: 'Cartão de batalha',
  TERRITORY_GROUP_REQUIRED_MARK: 'Grupo de territórios *',
  ENTER_TERRITORY_ID_FOR_RULE: 'Insira a ID do território',
  ENTER_TERRITORY_NAME_FOR_RULE: 'Insira o nome do território',
  CARD_TYPE_TEAM_FUNCTION: 'Tipo de cartão/função da equipe:',
  DEFINE_TERRITORY_GROUP_TYPES: 'Definir tipos de grupo de territórios',
  DEFINE_TERRITORY_GROUP_TYPES_DESCRIPTION:
    'Selecione um ou mais tipos de grupo de territórios, com base em suas hierarquias enviadas, para definir como você deseja organizar seus territórios.',
  HIERARCHIES: 'Hierarquias',
  REFER_TO_AS: 'Referir-se a como',
  TERRITORY_GROUP_TYPE: 'Tipo de grupo de territórios',
  PRIMARY_TEAM: 'Equipe principal',
  PRIMARY_TEAM_WITH_CONSOLIDATE: 'Equipe principal (consolidar)',
  DIRECT_OVERLAY: 'Sobreposição (sem consolidação)',
  ROLL_UP: 'Acúmulo (consolidar)',
  COMMAND_CENTER: 'Centro de comando',
  ADMIN: 'Administração',
  AVAILABLE_WITH_COUNT: '{count} disponível',
  NUMBER_OF_ACCOUNTS: 'Número de contas',
  ADD_HIERARCHY: 'Adicionar hierarquia',
  USER_MANAGEMENT: 'Gerenciamento de usuários',
  COPIED_TO_CLIPBOARD: 'Copiado para a área de transferência',
  COPY_TO_CLIPBOARD: 'Copiar para a área de transferência',
  GENERATE_API_KEY: 'Gerar chave API',
  GENERATE_API_KEY_ERROR_MESSAGE: 'Não é possível gerar chave API',
  GENERATE_KEY: 'Gerar chave',
  GENERATE_API_KEY_DESCRIPTION_1: 'Use esta chave API para conectar sua conta Pipe à sua conta TQP.',
  GENERATE_API_KEY_DESCRIPTION_2:
    'TQP não armazena esta chave API. Depois de gerado, copie e armazene em um local seguro. Gerar uma nova chave invalidará a chave antiga.',
  API_KEY_GENERATED: 'Chave API gerada',
  API_KEY_COPIED: 'A chave API foi copiada para a área de transferência.',
  API_KEY_GENERATED_DESC_1: 'TQP não armazena esta chave API. Copie e armazene esta chave API em um local seguro.',
  API_KEY_GENERATED_DESC_2:
    'Ao clicar em Concluído, a chave não poderá ser recuperada. Gerar uma nova chave invalidará a chave antiga.',
  EMAIL_PLACEHOLDER: 'johnsmith@company.com',
  PASSWORD: 'Senha',
  PASSWORD_CHANGE_SUCCESS: 'Senha alterada com sucesso',
  PASSWORD_CHANGE_ERROR: 'Não foi possível atualizar sua senha. Tente novamente mais tarde',
  INVALID_INVITATION_ERROR: 'Link de convite inválido.',
  ADD_USER_TO_TENANT_SUCCESS: 'Você foi adicionado com sucesso à sua organização',
  ADD_USER_TO_TENANT_ERROR:
    'Não foi possível adicioná-lo à sua organização. Entre em contato com seu administrador para obter um novo convite.',
  GLOBAL_MEASURES: 'Medidas globais',
  PLAN_TARGETS: 'Planejar metas',
  EXPANDED_COMPLETION_RATE: 'Taxa de conclusão:',
  QUOTA_APPROVAL_WORKFLOW: 'Fluxo de trabalho de aprovação de cota',
  QUOTA_WORKFLOW: 'Fluxo de trabalho de cota',
  INITIATE_WORKFLOW_TEXT: 'Iniciar fluxo de trabalho',
  INITIATE_WORKFLOW_ERROR: 'Desculpe! Falha ao iniciar fluxo de trabalho.',
  CANCEL_WORKFLOW_TEXT: 'Cancelar fluxo de trabalho',
  CANCEL_WORKFLOW_ERROR: 'Desculpe! Falha ao cancelar fluxo de trabalho.',
  TERRITORY_GROUP: 'Grupo de territórios',
  AUTH_CHECK_FAILURE: 'Falha ao autenticar. Faça login novamente',
  MEASURE_VALUE_TYPE_ERROR: 'O valor deve ser um número.',
  ACTIVITY_FILE: 'Arquivo(s) de atividade',
  ACTIVITY_FILE_WITH_COLON: 'Arquivo(s) de atividade:',
  SELECT: 'Selecionar',
  REMOVE: 'Remover',
  REVOKE: 'Revogar',
  INVITE: 'Convidar',
  COMMENT: 'Comentário',
  COMMENT_ERROR: 'Não é possível atualizar o comentário.',
  DATE: 'Data',
  EMAIL: 'E-mail',
  NUMERIC: 'Numérico',
  QUOTA_ALLOCATION: 'Alocação de cotas:',
  TERRITORY_GROUPS: 'Grupos de territórios',
  UPDATE: 'Atualizar',
  ADD: 'Incluir',
  ACTION: 'Ação',
  ACTION_MENU_TOOLTIP:
    'Mostra uma lista de ações disponíveis com base em sua região geográfica ou seleções de território.',
  ADD_TO_SELECTION: 'Adicionar à seleção',
  REMOVE_FROM_SELECTION: 'Remover da seleção',
  UPDATE_TERRITORY_GROUP_SUCCESS: 'Seu grupo de território foi atualizado.',
  UPDATE_TERRITORY_GROUP_ERROR: 'Não foi possível atualizar seu grupo de territórios.',
  NO_HIERARCHIES: 'Nenhuma hierarquia',
  USER_MANAGEMENT_LOAD_USERS: 'Carregar usuários do arquivo...',
  USER_MANAGEMENT_ADMIN_INVITE: 'Convidar como administrador',
  USER_MANAGEMENT_RESEND_ADMIN_INVITE: 'Reenviar convite de administrador',
  USER_MANAGEMENT_CONTRIBUTOR_INVITE: 'Convidar como colaborador',
  USER_MANAGEMENT_RESEND_CONTRIBUTOR_INVITE: 'Reenviar convite do colaborador',
  USER_MANAGEMENT_MEMBER_REVOKED: 'Acesso de colaborador revogado para o membro.',
  USER_MANAGEMENT_ADMIN_REVOKED: 'A função do membro foi atualizada para colaborador.',
  USER_MANAGEMENT_MEMBER_REVOKED_ERROR: 'Houve um problema ao revogar a função.',
  USER_MANAGEMENT_MEMBER_DELETED: 'O membro foi excluído.',
  USER_MANAGEMENT_MEMBER_DELETED_ERROR: 'Ocorreu um problema ao excluir o membro.',
  USER_MANAGEMENT_ADMIN_ACCESS: 'Acesso de administrador concedido. Um e-mail foi enviado ao membro.',
  USER_MANAGEMENT_CONTRIBUTOR_ACCESS: 'Acesso de colaborador concedido. Um e-mail foi enviado ao membro.',
  USER_MANAGEMENT_ACCESS_ERROR: 'Houve um problema ao enviar o convite.',
  USER_MANAGEMENT_TABLE_EMPLOYEE_ID: 'Id do funcionário',
  USER_MANAGEMENT_TABLE_JOB_TITLE: 'Cargo',
  USER_MANAGEMENT_TABLE_ROLE_NAME: 'Nome da função',
  USER_MANAGEMENT_REVOKE_ADMIN: 'Revogar acesso de administrador',
  USER_MANAGEMENT_REVOKE_CONTRIBUTOR: 'Revogar acesso de colaborador',
  USER_MANAGEMENT_DIALOG_REVOKE_HEADLINE: 'Revogar acesso de colaborador?',
  USER_MANAGEMENT_DIALOG_REVOKE_BODY:
    'O perfil do usuário e as credenciais de login permanecerão no sistema. No entanto, eles não terão acesso a esta organização.',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_HEADLINE: 'Revogar acesso de administrador?',
  USER_MANAGEMENT_DIALOG_REVOKE_ADMIN_BODY:
    'O perfil do usuário permanecerá no sistema. Sua função será atualizada como colaborador sem acesso de administrador.',
  USER_MANAGEMENT_DIALOG_REMOVE_HEADLINE: 'Remover perfil do participante',
  USER_MANAGEMENT_DIALOG_REMOVE_BODY:
    'Concluir esta ação removerá imediatamente este perfil de usuário do sistema. Se este perfil tiver sido atribuído a um território, grupo ou cota, pode ocorrerá um estado de erro.',
  USER_MANAGEMENT_DIALOG_INVITE_HEADLINE: 'Convidar membro como colaborador',
  USER_MANAGEMENT_DIALOG_INVITE_BODY:
    'Isso enviará um e-mail de convite ao membro para ser um colaborador. Deseja concluir esta ação?',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_HEADLINE: 'Convidar membro como administrador',
  USER_MANAGEMENT_DIALOG_ADMIN_INVITE_BODY:
    'Isso enviará um e-mail de convite ao membro para ser um administrador. Deseja concluir esta ação?',
  SEND_INVITATION: 'Enviar convite',
  RESEND_INVITATION: 'Reenviar convite',
  ADD_DESCRIPTION_PLACEHOLDER: 'Adicionar descrição...',
  EDIT_PLANNING_CYCLE: 'Editar ciclo de planejamento',
  EDIT_PLANNING_CYCLE_SUCCESS_TITLE: 'Alterações do ciclo de planejamento salvas',
  EDIT_PLANNING_CYCLE_FAILURE_TITLE: 'Falha na atualização do ciclo de planejamento',
  EDIT_NEW_CYCLE_SUCCESS: 'Seu novo ciclo de planejamento foi atualizado.',
  EDIT_NEW_CYCLE_ERROR: 'Não é possível atualizar o ciclo de planejamento.',
  EDIT: 'Editar',
  EDIT_WITH_VALUE: 'Editar {value}',
  LOADING: 'Carregando...',
  STARTING: 'Iniciando...',
  STOPPING: 'Parar...',
  DELETE_BATTLECARD_SUCCESS: 'Seu cartão de batalha foi excluído.',
  DELETE_BATTLECARD_GENERIC_ERROR: 'Não foi possível excluir seu cartão de batalha.',
  DELETE_BATTLECARD_TERRITORY_RULE_ERROR:
    'Não é possível excluir este cartão de batalha agora. Exclua todos os territórios do cartão de batalha e tente novamente.',
  NO_RESULTS: 'Nenhum resultado',
  TERRITORY_OWNER: 'Proprietário do território:',
  OWNERSHIP_VALUE_RANGE_ERROR: 'O valor deve ser de 1 a 100.',
  PERCENTAGE_MEASURE_VALUE_RANGE_ERROR: 'O valor deve ser de 0 a 100.',
  MEASURES: 'Medidas',
  BUILT_IN_MEASURES: 'Medidas integradas',
  CUSTOM_MEASURES: 'Medidas personalizadas',
  DESCRIPTION: 'Descrição',
  NAME: 'Nome',
  NAME_WITH_COLON: 'Nome:',
  TYPE: 'Tipo',
  TYPE_REQUIRED: '* Tipo:',
  ATTRIBUTE_NAME: 'Nome do atributo',
  ADD_CUSTOM_ATTRIBUTE: 'Adicionar atributo personalizado',
  ATTRIBUTE_NAME_MESSAGE: 'Insira um nome de atributo',
  FORMAT: 'Formato',
  FORMAT_REQUIRED: '* Formato:',
  FORMULA: 'Fórmula',
  DATE_RANGE: 'Intervalo de datas',
  ADD_MEASURE: 'Adicionar medida',
  TERRITORY_OWNER_ERROR: 'Selecione um proprietário de território.',
  ASSIGNMENT_BUILDER_ADD_SUCCESS: 'A atribuição foi adicionada.',
  ASSIGNMENT_BUILDER_ADD_FAILURE: 'Não foi possível adicionar a atribuição.',
  ASSIGNMENT_BUILDER_UPDATE_SUCCESS: 'A atribuição foi atualizada.',
  ASSIGNMENT_BUILDER_UPDATE_FAILURE: 'Não foi possível atualizar a atribuição.',
  TERRITORY_GROUP_TYPE_INCLUDE_MESSAGE: 'Deve haver pelo menos um tipo de grupo de território.',
  UPDATE_USER_SUCCESS: 'Perfil do usuário atualizado com sucesso.',
  UPDATE_USER_ERROR: 'Ocorreu um erro ao atualizar o perfil do usuário.',
  ADD_TERRITORIES_TOOLTIP:
    'Adicione territórios para poder realizar a alocação de cima para baixo para este grupo de territórios.',
  ADD_A_CARD: 'Adicionar um cartão',
  FIVE_HUNDRED: 'Opa! Há um problema com nosso servidor. Tente novamente mais tarde!',
  FOUR_O_ONE: 'Desculpe! Você não tem autorização para visualizar a página.',
  FOUR_O_FOUR: 'Opa! A página que você está procurando foi removida ou o link está inativo.',
  INTERNAL_ERROR: 'Opa! Há um problema com nosso aplicativo. Tente novamente mais tarde!',
  UPSERT_TERRITORY_GROUP_OWNER_ERROR: 'Não foi possível atualizar o proprietário do grupo de territórios.',
  OWNER: 'Proprietário',
  OWNER_WITH_COLON: 'Proprietário:',
  CREATE_ORGANIZATION_TITLE: 'Crie sua organização',
  NAME_YOUR_ORGANIZATION: 'Nomeie sua organização',
  FIRST_NAME: 'Nome',
  LAST_NAME: 'Sobrenome',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME_PLACEHOLDER: 'Smith',
  ORGANIZATION_NAME_PLACEHOLDER: 'Mais de 8 caracteres',
  CREATE_ORGANIZATION: 'Criar organização',
  TERMS_OF_SERVICE: 'Termos de serviço',
  PRIVACY_POLICY: 'Política de privacidade',
  INVALID_EMAIL: 'E-mail inválido',
  ORGANIZATION_NAME_LENGTH_ERROR: 'O nome da organização deve ter pelo menos 2 caracteres.',
  ENTER_YOUR_ORGANIZATION_NAME: 'Insira o nome da sua organização',
  BY_CLICKING: 'Ao clicar em {actionText}, você concorda com nosso',
  AND_OUR: 'e nossa',
  QUOTA_COMPONENT: 'Componente da cota',
  QUOTA_COMPONENT_WITH_VALUE: '{value} componente da cota',
  QUOTA_COMPONENTS: 'Componentes da cota',
  QUOTA_COMPONENTS_WITH_VALUE: '{value} componentes da cota',
  QUOTA_COMPONENTS_UPDATE_SUCCESS: 'O componente da cota foi atualizado.',
  QUOTA_COMPONENTS_DELETED_SUCCESS: 'O componente da cota foi excluído.',
  QUOTA_COMPONENTS_UPDATE_ERROR: 'Não foi possível atualizar o componente da cota.',
  NO_QUOTA_COMPONENTS: 'Nenhum componente da cota',
  DELETE_QUOTA_COMPONENT: 'Excluir componente da cota',
  CONFIRM_DELETE_QUOTA_COMPONENT: 'Tem certeza de que deseja excluir o componente da quota {name}?',
  NO_ACTIVITY_FILES: 'Nenhum arquivo de atividade',
  NO_DATA: 'Nenhum dado',
  NO_FILE_SELECTED: 'Nenhum arquivo selecionado',
  INSIGHTS: 'Insights',
  CLEAR_SELECTION: 'Limpar seleção',
  CHOOSE_FILE_FOR_UPLOAD: 'Escolha um arquivo CSV para enviar (* Indica um cabeçalho obrigatório)',
  CHOOSE_A_FILE: 'Escolher um arquivo',
  BEGIN_UPLOAD_ERROR: 'Não foi possível iniciar o envio do arquivo selecionado.',
  ADD_USERS: 'Adicionar usuários',
  PROCESSING_MESSAGE_PENDING: 'O arquivo está sendo processado.',
  PROCESSING_MESSAGE_COMPLETED: 'O arquivo foi processado com sucesso.',
  PROCESSING_MESSAGE_ERROR: 'O arquivo não foi processado com sucesso.',
  PROCESSING_STATUS_PENDING: 'Pendente',
  PROCESSING_STATUS_ERROR: 'Erro',
  RETRIEVE_PROCESSING_STATUS_ERROR: 'Não foi possível recuperar o status de processamento do arquivo.',
  GET_FILE_HEADERS_ERROR: 'Não foi possível recuperar cabeçalhos do arquivo.',
  NOTIFY_SERVER_ERROR: 'Não foi possível notificar o servidor sobre o status do envio.',
  CHECK_REQUIRED_FILE_HEADERS: 'Verifique se os cabeçalhos de coluna necessários estão presentes em seu arquivo.',
  BEGIN_FILE_UPLOAD: 'Iniciar envio de arquivo',
  FILE_PROCESSING_SUCCESS: 'Sucesso no processamento de arquivos',
  FILE_PROCESSING_ERROR: 'Erro no processamento de arquivos',
  FILE_NAME_VALIDATION_ERROR:
    'Não é possível importar este arquivo porque o nome do arquivo contém caracteres não compatíveis',
  FILE_COLUMN_VALIDATION_ERROR:
    'Não é possível importar este arquivo porque o cabeçalho da coluna do arquivo contém caracteres não compatíveis',
  FILE_NAME: 'Nome do arquivo',
  SUMMARY: 'Resumo',
  IMPORT_DATA: 'Importar dados',
  SEARCH: 'Pesquisar',
  SUBMIT: 'Enviar',
  READONLY: 'Somente leitura',
  REJECT: 'Rejeitar',
  APPROVE: 'Aprovar',
  ADD_TERRITORY_ROW: 'Adicionar linha de território',
  LOAD_TERRITORIES: 'Carregar territórios',
  ADD_TERRITORIES: 'Adicionar territórios',
  ADD_CUSTOM_MEASURE_SUCCESS: 'A medida foi adicionada.',
  ADD_CUSTOM_MEASURE_FAILURE: 'Não foi possível adicionar a medida.',
  UPDATE_CUSTOM_MEASURE_SUCCESS: 'A medida foi atualizada.',
  UPDATE_CUSTOM_MEASURE_FAILURE: 'Não foi possível atualizar a medida.',
  UPDATE_MEASURE: 'Atualizar medida',
  DELETE_CUSTOM_MEASURE_SUCCESS: 'A medida foi excluída.',
  DELETE_CUSTOM_MEASURE_FAILURE: 'Não foi possível excluir a medida.',
  MEASURE_NAME_REQUIRED: 'Forneça um nome de coluna.',
  MEASURE_TYPE_REQUIRED: 'Selecione uma origem.',
  MEASURE_FORMAT_REQUIRED: 'Selecione um formato.',
  MEASURE_SOURCE_REQUIRED: 'Selecione um tipo de tabela de dados.',
  FIELD_ID_REQUIRED: 'Selecione uma tabela de dados.',
  FIELD_NAME_REQUIRED: 'Selecione uma coluna.',
  CREATE_NEW_ORGANIZATION_SUCCESS: 'Sua nova organização foi criada.',
  CREATE_NEW_ORGANIZATION_ERROR: 'Não foi possível criar uma nova organização.',
  TERRITORY_DEFINITIONS: 'Territórios e definições de atribuição',
  ASSIGNED: 'Atribuído',
  UNASSIGNED: 'Não definida',
  UNASSIGNED_LOWERCASE: 'não definida',
  UNABLE_TO_PROCESS_CONTRIBUTOR: 'Não foi possível processar sua solicitação.',
  EDIT_TERRITORY: 'Editar território',
  QUOTA_COMPONENT_VALIDATION_ERROR: 'Selecione pelo menos um componente de cota.',
  EDIT_HIERARCHY: 'Editar hierarquia',
  EDIT_HIERARCHY_ERROR: 'Não foi possível editar sua hierarquia.',
  EDIT_HIERARCHY_SUCCESS: 'A hierarquia foi editada',
  ENTER_HIERARCHY_NAME: 'Insira um nome para sua hierarquia',
  HIERARCHY_NAME: 'Nome da hierarquia',
  UNSAVED_CHANGES: 'Tem certeza de que deseja sair? Você tem alterações não salvas.',
  BATTLE_CARD_TITLE_VALIDATION_ERROR: 'Forneça um nome para seu cartão de batalha.',
  GET_ACTIVITIES_FOR_FILE_ERROR: 'Não foi possível recuperar atividades para arquivo.',
  GET_ACTIVITIES_FOR_TERRITORY_ERROR: 'Não foi possível recuperar atividades para território.',
  GET_ACTIVITIES_FOR_TERRITORY_GROUP_ERROR: 'Não foi possível recuperar atividades para grupo de território.',
  GET_UNASSIGNED_ACTIVITIES_ERROR: 'Não é possível recuperar atividades não definidas.',
  WORKFLOW_APPROVE_SUCCESS: 'o fluxo de trabalho foi aprovado!',
  WORKFLOW_REJECT_SUCCESS: 'o fluxo de trabalho foi rejeitado!',
  APPROVE_TERRITORY_GROUP_DIALOG_MESSAGE:
    'é responsável pela aprovação deste grupo de território e pode estar no processo de modificação de cotas. Tem certeza de que deseja aprovar?',
  REJECT_TERRITORY_GROUP_DIALOG_MESSAGE:
    'é responsável pela rejeição deste grupo de território e pode estar no processo de modificação de cotas. Tem certeza de que deseja rejeitar?',
  APPROVE_BATTLE_CARD_DIALOG_MESSAGE:
    'é responsável pela aprovação deste cartão de batalha e pode estar no processo de modificação de cotas. Tem certeza de que deseja aprovar?',
  REJECT_BATTLE_CARD_DIALOG_MESSAGE:
    'é responsável pela rejeição deste cartão de batalha e pode estar no processo de modificação de cotas. Tem certeza de que deseja rejeitar?',
  APPROVE_DIALOG_TITLE: 'Você está aprovando em nome de outro usuário',
  REJECT_DIALOG_TITLE: 'Você está rejeitando em nome de outro usuário',
  AVAILABLE_WITH_PLANNING_CYCLE_ID: 'está disponível com ID do ciclo de planejamento',
  YOUR_ORGANIZATION_URL_WILL_BE: 'A URL da sua organização será',
  PLANNING_CYCLE_NAME: 'Nome do ciclo de planejamento',
  NEXT: 'Próximo',
  SIGN_IN: 'Entrar',
  ARCHIVE: 'Arquivar',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_1: 'Você está prestes a arquivar este ciclo de planejamento:',
  PLANNING_CYCLE_ARCHIVE_MESSAGE_2: 'Ele será armazenado no arquivo por 30 dias.',
  VIEW_ALL_ACTIVITIES: 'Visualizar todas as atividades',
  ADD_A_CHILD: 'Adicionar um filho',
  KEY: 'Chave',
  ADDRESS1: 'Linha de endereço 1',
  ADDRESS2: 'Linha de endereço 2',
  CITY: 'Cidade',
  COUNTRY: 'País',
  INDUSTRY: 'Indústria',
  ZIP_OR_POSTAL: 'CEP ou código postal',
  STATE_OR_PROVINCE: 'Estado ou província',
  GEOGRAPHIC_REGION: 'Região geográfica',
  SELECTED_GEOGRAPHIC_REGIONS: 'Regiões geográficas selecionadas',
  SELECTED_TERRITORIES: 'Territórios selecionados',
  GEOGRAPHIC_REGION_TOGGLE_TOOLTIP:
    'Isso permite selecionar limites individuais com base no nível geográfico escolhido. Por exemplo, países, estados ou códigos postais.',
  SAVE_CHANGES: 'Alterações salvas',
  CONTACT_ADMIN_EMAIL: 'Entre em contato com seu administrador para alterar seu endereço de e-mail.',
  REQUIRED_FIELD: 'Campo obrigatório',
  DETAILS: 'Detalhes',
  HIERARCHY_EMPTY: 'Hierarquia vazia',
  CONFIRM_DELETE_HIERARCHY: 'Tem certeza de que deseja excluir {name}?',
  OPTIONS: 'Opções',
  DELETE_TERRITORY_GROUP_CONFIRM: 'Tem certeza de que deseja excluir este território?',
  DELETE_TERRITORY_SUCCESS: '{territoryId}: {territoryName} território foi excluído.',
  DELETE_TERRITORY_ERROR: 'Não é possível excluir território {territoryId}: {territoryName}.',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE:
    'Não foi possível excluir este território porque ele é usado por um cartão de batalha sobreposto. Remova as referências a este território do cartão de batalha sobreposto e tente novamente.',
  DELETE_TERRITORY_TEXT: 'Excluir território',
  DELETE_TERRITORY_TEXT_WITH_ID_AND_NAME: 'Excluir território {territoryId}: {territoryName}',
  DELETE_MULTIPLE_TERRITORIES_TEXT: 'Excluir territórios',
  TERRITORY_DELETE_DIALOG_BODY: 'Deseja excluir {count} território selecionado?',
  TERRITORIES_DELETE_DIALOG_BODY: 'Deseja excluir {count} territórios selecionados?',
  CURRENCIES: 'Moedas',
  CURRENCIES_WITH_COUNT: '{count} moedas',
  CURRENCY: 'Moeda',
  CURRENCY_WITH_COUNT: '{count} moeda',
  REPORTING_CURRENCY: 'Moeda do relatório',
  REPORTING_CURRENCY_WITH_VALUE: 'Moeda do relatório ({value})',
  LOCAL_CURRENCY: 'Moeda local',
  LOCAL_CURRENCY_WITH_COLON: 'Moeda local:',
  REPORTING_AND_LOCAL_CURRENCIES: 'Relatórios e moedas locais',
  AVAILABLE_CURRENCIES: 'Moedas disponíveis',
  REPORTING_CURRENCY_DEFAULT: 'Moeda do relatório (padrão)',
  REPORTING_CURRENCY_DESIGNATED: 'Moeda do relatório (designada)',
  CONSTANT_CURRENCY_EXCHANGE_RATE: 'Taxa de câmbio de moeda constante',
  ADD_CURRENCY: 'Adicionar moeda',
  VALUES_SHOWN_IN_REPORTING_CURRENCY: 'Valores mostrados na moeda do relatório ({value})',
  VALUES_SHOWN_IN_LOCAL_CURRENCY: 'Valores mostrados na moeda local ({value})',
  REPORTING_CURRENCY_IS: 'A moeda do relatório é {value}',
  LOCAL_CURRENCY_IS: 'A moeda local é {value}',
  EMPTY_HIERARCHY_MESSAGE: 'Selecione um item na hierarquia para visualizar seus detalhes.',
  ALL: 'Todas',
  SELECT_CURRENCY: 'Selecione uma moeda',
  SELECT_CURRENCY_ERROR_MESSAGE: 'Selecione uma moeda.',
  SELECT_CONVERSION_ERROR_MESSAGE: 'Insira uma conversão.',
  CURRENCY_IN_USE: 'Moeda em uso',
  DELETE_CURRENCY: 'Excluir moeda',
  ADD_TERRITORY_GROUP_TYPE: 'Adicionar tipo de grupo de territórios',
  KEY_EXISTS_ERROR: '{value} não está disponível porque já está em uso.',
  IS_AVAILABLE: 'está disponível.',
  NO_GRID_TERRITORIES: 'Não há territórios para mostrar.',
  NO_GRID_TERRITORIES_ADDED: 'Você ainda não adicionou nenhum território.',
  NO_TERRITORY: 'Nenhum território',
  EQUALS_SIGN: '=',
  INVERT_EXCHANGE_RATES: 'Inverter taxas de câmbio',
  CURRENCY_PAGE_SUCCESS: 'Moedas atualizadas com sucesso',
  CURRENCY_PAGE_ERROR: 'Não é possível atualizar moedas.',
  DELETE_REPORTING_CURRENCY: 'Excluir moeda do relatório',
  CONFIRM_DELETE_REPORTING_CURRENCY:
    'Tem certeza de que deseja excluir essa moeda do relatório? Todas as moedas locais associadas a esta moeda do relatório também serão excluídas.',
  DELETE_LOCAL_CURRENCY: 'Excluir moeda local',
  CONFIRM_DELETE_LOCAL_CURRENCY: 'Tem certeza de que deseja excluir essa moeda local?',
  DOCUMENTATION: 'Documentação',
  GET_CONVERSION_RATES_COUNT_ERROR: 'Não foi possível recuperar o número de moedas em uso.',
  UPLOAD_COLUMN_HEADERS_MESSAGE_PREFIX: 'Use os seguintes cabeçalhos de coluna em seu envio: {value}',
  UPDATE_MEASURE_VALUE_ERROR: 'Não é possível atualizar o valor da medida.',
  FILE_UPLOAD_HEADER_ERROR_SINGLE: 'O seguinte cabeçalho de coluna está ausente: {value}',
  FILE_UPLOAD_HEADER_ERROR_PLURAL: 'Os seguintes cabeçalhos de coluna estão ausentes: {value}',
  FILE_UPLOAD_HEADER_ERROR_UNKNOWN: 'Ocorreu um erro ao validar este arquivo.',
  SET_CONVERSION_RATE: 'Definir taxa de conversão',
  DELETE_HIERARCHY_WITH_NAME: 'Excluir hierarquia {name}?',
  DELETE_HIERARCHY_SUCCESS: 'Hierarquia {name} excluída com sucesso.',
  DELETE_HIERARCHY_ERROR: 'Não é possível excluir hierarquia {name}.',
  PRIOR_YEAR_SALES: 'Vendas do ano anterior',
  TOTAL: 'Total',
  TOTAL_WITH_LABEL: '{label} Total',
  TOTAL_WITH_VALUE: 'Total {value}',
  CANCEL_WORKFLOW_CONFIRMATION: 'Tem certeza de que deseja cancelar o fluxo de trabalho?',
  YES: 'Sim',
  NO: 'Não',
  LATER: 'Mais tarde',
  REPARENTING: 'Reassociando...',
  NOT_APPLICABLE_ABBREVIATION: 'N/A',
  CONTRIBUTOR_QUOTA_COMPONENT_SUBMIT_WARNING: 'Antes de enviar, revise {value}',
  CONTRIBUTOR_HIERARCHY_SUBMIT_WARNING: 'Antes de enviar, aprove todos os territórios filhos.',
  SHEETS: 'Planilhas',
  TABLES: 'Tabelas',
  UNPUBLISHED: 'Não publicado',
  PUBLISHED: 'Publicado',
  PUBLISHING_IN_PROGRESS: 'Publicação em andamento',
  IMPORT: 'Importar',
  DATE_CREATED: 'Data de criação',
  DATE_MODIFIED: 'Data de modificação',
  SELLERS: 'Vendedores',
  SELLER: 'Vendedor',
  MOVE: 'Mover',
  HYPHEN: '-',
  EXPAND: 'Expandir',
  ZOOM_IN: 'Ampliar',
  ZOOM_OUT: 'Reduzir',
  CLOSE: 'Fechar',
  LAST_MODIFIED: 'Última modificação: {value}',
  BATTLE_CARD_REFERENCED: 'Cartão de batalha mencionado',
  SELECT_BATTLE_CARD_REFERENCE: 'Selecione os cartões de batalha e níveis que fazem referência a esta planilha',
  BATTLE_CARD_NAME: 'Nome do cartão de batalha',
  SHEETS_REFERENCED: 'Planilhas mencionadas',
  REFERENCE_THIS_SHEET: 'Consulte esta planilha',
  PUBLISH: 'Publicar',
  PERMISSIONS: 'Permissões',
  APPLY_PER_USER_ROLE: 'Aplicar por função de usuário',
  USER: 'Usuário',
  QUOTA_SHEET_COLUMNS: 'Colunas da planilha de cotas',
  TERRITORY_SHEET_COLUMNS: 'Colunas da planilha de território',
  SELLER_SHEET_COLUMNS: 'Colunas da planilha do vendedor',
  COLUMNS_DESCRIPTION: 'Gerenciar campos integrados e adicionar campos personalizados',
  CREATE_FIELD: 'Criar campo',
  EDIT_FIELD: 'Editar campo',
  SHOW_TOTALS: 'Mostrar totais',
  REPARENT_TO_ROOT: 'Reassociar à raiz',
  ADD_HIERARCHY_UNDER_ROOT: 'Adicionar hierarquia à raiz',
  PIN_SET: 'Conjunto de pins',
  PIN_TOOLTIP: 'Visualizar e adicionar pins',
  ADD_PIN_SET: 'Adicionar conjunto de pins',
  PIN_SET_NAME: 'Nome do conjunto de pins',
  PIN_SET_ICON: 'Ícone do conjunto de pins',
  PIN_SET_COLOR: 'Cor do conjunto de pins',
  COLOR_OPTION: 'Opção de cor {value}',
  PIN_NAME_PLACEHOLDER: 'Insira o nome do pin',
  PIN_SET_DATA_PLACEHOLDER: 'Selecione uma fonte de dados para seu conjunto de pins',
  CREATE_PIN_SET_SUCCESS: 'Criamos seu conjunto de pins.',
  CREATE_PIN_SET_ERROR: 'Não foi possível criar seu conjunto de pins.',
  LOCATION_GROUP_UNIQUENESS_ERROR: 'Este nome de tabela de dados já existe',
  MISSING_REQUIRED_LOCATION_GROUP_NAME: 'Insira um nome de tabela de dados',
  MISSING_REQUIRED_PIN_SET_NAME: 'Insira um nome de conjunto de pins',
  PIN_SET_NAME_TOO_LONG: 'Você excedeu o limite máximo de caracteres ({maxCharacters})',
  MISSING_REQUIRED_LOCATION_GROUP: 'Selecione uma fonte de dados',
  MISSING_REQUIRED_ICON: 'Selecione um ícone',
  MISSING_REQUIRED_COLOR: 'Selecione uma cor',
  DATA_SOURCE_DUPLICATE_USAGE:
    'Um conjunto de pins no cartão de batalha já está usando esta fonte de dados. Selecione outra fonte de dados.',
  PIN_SET_NAME_UNIQUENESS_ERROR: 'Este nome de conjunto de pins já existe.',
  LOCATION_FILE_TYPE: 'Pins do mapa',
  EXISTING_PIN_SET_TEXT: 'Usar dados de conjunto de pins existentes',
  NEW_PIN_SET_TEXT: 'Importar novos dados do conjunto de pins',
  PREVIEW: 'Visualização',
  ADD_USER_ROLE: '+ Incluir',
  QUOTA_COMPONENTS_REFERENCED: 'Componentes de cota mencionados',
  QUOTA_COMPONENTS_REFERENCED_CHECKBOX_SELECTION_WARNING: 'Mencione pelo menos um componente de cota',
  COLUMN_NAME: 'Nome da coluna',
  SOURCE: 'Fonte',
  DATA_TABLE_TYPE_WITH_COLON: 'Tipo de tabela de dados:',
  DATA_TABLE_NAME: 'Nome da tabela de dados*',
  DATA_TABLE_PLACEHOLDER: 'Ex. Prefeituras dos EUA 2022',
  DATA_TABLE: 'Tabela de dados',
  COLUMN: 'Coluna',
  GROUP_BY: 'Agrupar por:',
  APPLIED_FILTERS: 'Filtros aplicados:',
  RESET: 'Redefinir',
  DELETE_FIELD_FAILED: 'Não foi possível excluir o campo',
  CREATE_FIELD_SUCCESS: 'Campo criado com sucesso',
  UPDATE_FIELD_SUCCESS: 'Campo atualizado com sucesso',
  DELETE_FIELD_SUCCESS: 'Campo excluído com sucesso',
  UPDATE_QUOTA_SHEET_SUCCESS: 'Planilha de cotas atualizada com sucesso',
  UPDATE_QUOTA_SHEET_FAILED: 'Não foi possível atualizar a planilha de cotas',
  COLUMN_REORDER_FAILED: 'Não foi possível reordenar os campos de coluna',
  COLUMN_REORDER_SUCCESS: 'Campos de coluna reordenados com sucesso',
  DISABLED_CHECKBOX_TOOLTIP: 'Esta propriedade não está habilitada para este tipo de campo.',
  DISABLED_PLANNING_CYCLE_FIELD_TOOLTIP: 'Este campo não é editável após a criação do ciclo de planejamento.',
  VISIBLE: 'Visível',
  EDITABLE: 'Editável',
  CANCEL_LASSO: 'Cancelar laço',
  LASSO_TOOLTIP: 'Ferramenta Laço',
  LASSO_DROPDOWN_TOOLTIP: 'Filtre sua seleção de laços para incluir regiões geográficas atribuídas ou não definidas.',
  SELECT_TOOLTIP: 'Ferramenta de seleção',
  UNDO: 'Desfazer',
  ABOVE_AVERAGE: '{value} percentil acima da média de todos os territórios usando a mesma métrica.',
  BELOW_AVERAGE: '{value} percentil abaixo da média de todos os territórios usando a mesma métrica.',
  ON_AVERAGE: 'Esse território está na média de todos os territórios usando a mesma métrica.',
  GAP: 'Intervalo',
  CREATE_NEW_TERRITORY_ERROR: 'Não foi possível criar território.',
  CREATE_NEW_TERRITORY_SUCCESS: 'Novo território criado.',
  CREATE_NEW_TERRITORY_DIALOG_TITLE: 'Criar um novo território',
  EFFECTIVE_DATING: 'Data de entrada em vigor',
  EXCEL: 'Excel',
  USER_PROFILE: 'Perfil',
  PROFILE_PAGE_PERSONAL_INFORMATION: 'Informações pessoais',
  PROFILE_PICTURE: 'Foto do perfil',
  PROFILE_PICTURE_SUBTITLE: 'Adicione uma foto para personalizar sua conta',
  USE_LASSO_TOOL: "Use a ferramenta 'Lasso' ",
  DRAW_NEW_TERRITORY: ' para desenhar um novo território',
  PASSWORD_STRENGTH: 'Senha forte: ',
  PASSWORD_STRENGTH_INSTRUCTION:
    'Use pelo menos 8 caracteres. Não use uma senha de outro site ou algo óbvio demais, como o nome do seu animal de estimação.',
  PASSWORD_INSTRUCTION: 'Escolha uma senha forte e não a reutilize para outras contas.',
  CHANGE_PASSWORD: 'Alterar senha',
  CONFIRM_EXISTING_PASSWORD_LABEL: 'Insira novamente a senha',
  NEW_PASSWORD_LABEL: 'Nova senha',
  CONFIRM_NEW_PASSWORD_LABEL: 'Confirmar nova senha',
  SET_BALANCING_METRIC: 'Esta é uma métrica de balanceamento',
  MAKE_THIS_DEFAULT: 'Tornar isso o padrão',
  BALANCING_MODAL_DELETE_HEADER: 'Remover este território?',
  REBALANCING_COINSORT_HEADER: 'Executar a classificação de moedas?',
  REBALANCING_COINSORT_BODY:
    'Você queria executar a classificação de moedas agora? Os territórios serão classificados com as novas atualizações de regras.',
  BALANCING_MODAL_DELETE_BODY: 'Remover este território também cancelará suas alterações não salvas.',
  BALANCING_MODAL_BATTLECARD_BODY: 'Fechar este cartão de batalha também cancelará suas alterações não salvas.',
  REBALANCING_SUCCESS: 'Os territórios foram reequilibrados com sucesso.',
  UPLOADING: 'Enviando...',
  UPLOAD: 'Enviar',
  DONE: 'Concluído',
  DATA_FILE_DRAG_DROP_TITLE: 'Solte o arquivo ou clique para enviar',
  DATA_FILE_DRAG_DROP_TEXT: 'Envie um arquivo de texto do seu dispositivo local. O formato aceito é: .csv',
  FILE_PUBLISH_ERROR: 'Não foi possível publicar seu arquivo',
  FILE_HAS_BEEN_PUBLISH_SUCCESS: '{name} foi publicado com sucesso',
  FILE_HAS_BEEN_PUBLISH_ERROR: 'falha ao publicar {name}',
  SYSTEM_TIMESTAMP: 'Carimbo de data/hora do sistema',
  DATA_FILE_UPLOAD_SEQUENCE_ERROR: 'Desculpe! Falha ao enviar arquivo: {message}',
  REMOVE_REBALANCING_TERRITORY: 'Remover este território',
  REMOVE_TERRITORY: 'Remover território',
  ADDITIONAL_REBALANCING_TERRITORY_ERROR: 'Desculpe! Você só pode selecionar dois territórios por vez.',
  TERRITORY_RULE: 'Regra do território',
  UPDATE_TERRITORY_SUCCESS: 'O território foi atualizado com sucesso.',
  UPDATE_TERRITORY_ERROR: 'Não foi possível atualizar território.',
  MENU: 'Menu',
  HELP: 'Ajuda',
  WARNING: 'Aviso',
  OPEN: 'Aberto',
  BUILT_IN_MEASURES_CANNOT_EDIT: 'Medidas integradas não podem ser editadas.',
  BEFORE_BALANCING_METRIC_DEFAULT:
    'Essa métrica será usada por padrão. Por exemplo, ao reequilibrar territórios e você tem várias métricas de balanceamento, um recurso que usa uma métrica de balanceamento por vez usará essa métrica por padrão.',
  DEFAULT_BALANCING_METRIC_WARNING_MESSAGE: 'Selecione outra métrica de balanceamento se desejar alterar o padrão.',
  AUTOMATCH: 'Correspondência automática',
  GET_S3_FILE_ERROR: 'Não foi possível recuperar o conteúdo do arquivo',
  SELECT_A_HIERARCHY: 'Selecionar uma hierarquia',
  SELECT_TERRITORY: 'Selecionar território',
  EDIT_HIERARCHY_NONE: 'Selecione uma hierarquia para editar',
  IMPORT_FROM_CSV: 'Importar do arquivo .csv',
  IMPORT_FROM_SYMON: 'Importar do Pipe',
  MAP_DATA_ERROR: 'Ocorreu um erro ao carregar os dados do mapa',
  CANNOT_REBALANCE_CUSTOMER:
    'Você não pode mover um cliente que pertença a vários territórios ou tenha atividades não definidas em qualquer componente de cota.',
  CANNOT_REBALANCE_GEOGRAPHY:
    'Você não pode mover uma geografia que pertença a vários territórios ou tenha atividades não definidas em qualquer componente de cota.',
  CANNOT_REBALANCE_MISSING_GEO_HIERARCHY:
    'Não é possível mover a geografia porque está faltando uma hierarquia. Verifique se as hierarquias enviadas correspondem às presentes nos arquivos de atividade.',
  TERRITORY_GRID_ERROR:
    'Os territórios não podem ser carregados na planilha. Clique em Atualizar para carregar os territórios novamente.',
  ENTER_TERRITORY_GROUP_NAME: 'Insira um nome para o seu grupo de território',
  SYMON_AI_SOURCE: 'Fonte Pipe',
  NO_RESULTS_FOUND: 'Nenhum resultado encontrado',
  GET_SYMON_PIPENAMES_ERROR: 'Não é possível recuperar pipenames.',
  GET_SYMON_EXPORT_NODES_ERROR: 'Não é possível recuperar nós de exportação.',
  MOVE_TERRITORY: 'Mover território',
  MOVE_TERRITORY_DESCRIPTION: 'Escolha o grupo de território para o qual deseja mover o território selecionado:',
  MOVE_TERRITORY_ERROR: 'Não é possível mover territórios.',
  MOVE_TERRITORY_DISABLED:
    'Você não pode mover os territórios selecionados porque eles pertencem a diferentes tipos de grupos de territórios.',
  CARD_TYPE: 'Tipo de cartão: ',
  BATTLECARD_TYPE_OVERLAY: 'Sobreposição',
  BATTLECARD_TYPE_PRIMARY: 'Primário',
  BATTLECARD_TYPE_ROLL_UP: 'Acúmulo',
  CREATE_CHILD_UNDER: 'Criar filho com menos de {value}',
  ROOT: 'raiz',
  INTEGRATIONS: 'Integrações',
  SYMON: 'Symon',
  VIEW_SOURCES: 'Exibir origens',
  MANAGE_TABLES: 'Gerenciar tabelas',
  BUILD_AND_VISUALIZE: 'Construir e visualizar',
  DASHBOARDS: 'Painéis',
  CONFIRM: 'Confirmar',
  SYMON_CREDENTIALS_SAVE_SUCCESS: 'Credenciais Pipe salvas com sucesso',
  SYMON_CREDENTIALS_DELETE_SUCCESS: 'Credenciais Pipe excluídas com sucesso',
  SYMON_CREDENTIALS_SAVE_FAILED: 'Falha ao salvar credenciais Pipe',
  SYMON_CREDENTIALS_BEING_VALIDATED: 'Suas credenciais de API estão sendo validadas.',
  SYMON_CREDENTIALS_VALID: 'Validamos sua chave API e a conexão do pipe está pronta.',
  SYMON_CREDENTIALS_INVALID: 'Não foi possível verificar, verifique se suas credenciais estão corretas.',
  VALIDATE_API_KEY: 'Validar chave API',
  DELETE_API_KEY: 'Excluir chave API',
  SYMON_DOMAIN: 'Domínio Pipe',
  SAVED_SYMON_DOMAIN_INVALID_TITLE: 'Seu domínio Pipe salvo é inválido.',
  SAVED_SYMON_DOMAIN_INVALID_DESCRIPTION: 'Atualize sua chave para uma do domínio: ',
  SYSTEM_API_KEY: 'Chave API do sistema',
  CONFIGURE_FOR_PLANNING: 'Configurar para planejamento',
  GET_SYMON_ACCOUNT_ERROR: 'Não foi possível recuperar a conta de pipe.',
  SYMON_DOMAIN_REQUIRED: 'O domínio do Pipe é obrigatório.',
  SYSTEM_API_KEY_REQUIRED: 'A chave API do sistema é obrigatória',
  SYMON_API_URL_INVALID: 'URL de Pipe inválido',
  SYMON_EXPORT_NODE_REQUIRED: 'O nó de exportação do Pipe é obrigatório.',
  SYMON_PIPE_NAME_REQUIRED: 'A fonte do Pipe é obrigatória',
  DESTINATION_TABLE_REQUIRED: 'O nome da tabela de destino é obrigatório',
  NEW_TERRITORY: 'Novo território',
  FORWARD_SLASH: '/',
  INPUT_TEXT: 'Texto de entrada',
  TABLE_CONTROLLER_PUBLISHED_STATUS_FAILED_DEFAULT_ERROR:
    'Não foi possível publicar seu arquivo. Verifique se o arquivo importado tem campos que correspondem às colunas apropriadas.',
  ACTION_REQUIRED_REVIEW_ERROR: 'Ação necessária: Revisar erro',
  ACTION_REQUIRED_MATCH_FIELDS: 'Ação necessária: Campos de correspondência',
  MATCH_FIELDS_TITLE: 'Campos de correspondência',
  MATCH_FIELDS_TEXT_RUN_THIS_CONFIGURATION:
    'Selecione as colunas necessárias para corresponder aos seus campos de dados. Faça corresponder todas as colunas necessárias aos seus campos de dados antes de executar esta configuração.',
  MATCH_FIELDS_TEXT_PUBLISH_THIS_FILE:
    'Selecione as colunas necessárias para corresponder aos seus campos de dados. Faça corresponder todas as colunas necessárias aos seus campos de dados antes de publicar este arquivo.',
  MEMBERS_SELECTED_WITH_COUNT: '{count} membros selecionados',
  DELETE_MEMBER_WITH_VALUE: 'Excluir {count} membro',
  DELETE_MEMBERS_WITH_VALUE: 'Excluir {count} membros',
  DESELECT_ALL: 'Desfazer todas as seleções',
  DELETE_HIERARCHIES_ERROR: 'Não é possível excluir suas hierarquias.',
  CONFIGURATIONS: 'Configurações',
  SYMON_AI_EXPORT_NODE: 'Nó de exportação',
  CREATE_CONFIGURATION: 'Criar configuração',
  RUN_CONFIGURATION_AFTER_CREATING: 'Execute esta configuração depois de criar',
  NO_SYMON_PIPES: 'Sem pipes',
  CHECK_FOR_CHANGES: 'Verifique se há alterações',
  FILE_TYPE: 'Tipo de arquivo',
  FILE_REQUIREMENT_TEXT: 'Os arquivos {fileType} exigem as seguintes colunas para importação:',
  DESTINATION: 'Destino',
  DESTINATION_TABLE: 'Tabela de destino',
  NO_SYMON_EXPORT_NODE_ERROR_MESSAGE:
    'Não há nós de exportação disponíveis para esta fonte. Selecione uma outra fonte de Pipe para criar a configuração.',
  NO_SYMON_AI_SOURCE_ERROR_MESSAGE: 'Para selecionar uma fonte de Pipe, primeiro configure um pipe na plataforma.',
  TEXT: 'Texto',
  ACTIVITY: 'Atividade',
  DATA_MAPPING_PANEL_TEXT:
    'Seus dados ainda não estão no sistema. Esta tabela é apenas uma visualização. Para importar seus dados e criar uma nova tabela, clique em Executar.',
  DATA_MAPPING_FIELDS_ERROR: 'Verifique se você associou todos os campos às colunas apropriadas.',
  CONFIGURATION_CREATION_SUCCESS: 'Configuração criada com sucesso',
  CONFIGURATION_CREATION_FAIL: 'Falha ao criar configuração',
  UNDER_BY: 'Abaixo de {value}',
  OVER_BY: 'Acima de {value}',
  RUN_THIS_CONFIGURATION: 'Executar esta configuração',
  SYMON_PIPE_CONFIGURATIONS_ERROR: 'Não foi possível recuperar as configurações do pipe.',
  GETTING_PREVIEW_DATA: 'Obtendo dados de visualização de configuração do pipe...',
  UPDATE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuração atualizada com sucesso.',
  UPDATE_SYMON_PIPE_CONFIGURATION_ERROR: 'Não foi possível atualizar as configurações do pipe.',
  DELETE_SYMON_PIPE_CONFIGURATION_SUCCESS: 'Configuração excluída com sucesso.',
  DELETE_SYMON_PIPE_CONFIGURATION_ERROR: 'Falha ao excluir a configuração.',
  CONFIGURATION: 'Configuração',
  MEMBERS_REPARENTED_SUCCESS: '{count} membros foram reassociados com sucesso',
  SYMON_FILE_IMPORT_IN_PROGRESS: 'Estamos importando seu arquivo de dados.',
  SYMON_FILE_IMPORT_ERROR: 'Não foi possível importar seu arquivo de dados',
  NOT_STARTED: 'Não iniciado',
  RUNNING: 'Em execução',
  FILE_SUCCESSFULLY_PUBLISHED: 'Seu arquivo foi publicado com sucesso.',
  MAKE_A_COPY: 'Fazer uma cópia',
  PLANNING_CYCLE_CLONE_FAILURE: 'Não é possível clonar o ciclo de planejamento',
  PLANNING_CYCLE_CLONE_REQUEST_SUCCESS: 'Clone do ciclo de planejamento solicitado com sucesso',
  SELECT_AN_EXISTING_CONFIGURATION: 'Selecione uma configuração existente',
  IMPORT_CONFIG_MESSAGE:
    'Importaremos seu arquivo de dados para a tabela de destino. Você poderá visualizá-lo na página Dados.',
  CREATE_NEW_CONFIGURATION: 'Criar nova configuração',
  RUN_EXISTING_CONFIGURATION: 'Executar configuração existente',
  FILE_PREVIEW: 'Visualização de arquivo',
  REVIEW_IMPORT: 'Revisar importação',
  COMMENTS: 'Comentários',
  ALL_GROUPS: 'Todos os grupos',
  ALL_TERRITORIES: 'Todos os territórios',
  NO_COMMENTS_TO_SHOW: 'Nenhum comentário para mostrar',
  ADD_COMMENTS_MESSAGE: 'Adicione comentários para fornecer feedback sobre o planejamento do território',
  ADD_COMMENTS: 'Adicionar comentários',
  NEW_COMMENTS_PLACEHOLDER: 'Use @menções para marcar uma ID de território ou grupo',
  REPLY: 'Responder',
  MAP_SELECTION_STATS_ERROR: 'Não é possível obter estatísticas para seleção',
  ENTER_TABLE_NAME: 'Insira o nome da tabela',
  CHOOSE_THE_SOURCE_AND_EXPORT_NODE: 'Escolha o nó de origem e exportação do qual extrair seu arquivo de dados.',
  SYMON_AI_SOURCE_AND_EXPORT_NODE: 'Nó de origem e exportação do Pipe',
  SYMON_REVIEW_DIALOG_CONFIGURATION_TEXT: 'Salvaremos esta configuração para você na página Configurações.',
  SYMON_REVIEW_DIALOG_EXISTING_CONFIGURATION_TEXT:
    'Importaremos sua configuração existente novamente. Isso criará uma nova versão da sua tabela de dados {name}.',
  TERRITORY_RULES: 'Regras do território',
  MAP: 'Visualizar mapa',
  GRID: 'Visualizar grade',
  URL_ERROR_MESSAGE_GENERIC: 'Insira uma URL válida',
  INCLUSIONS: 'Inclusões',
  EXCLUSIONS: 'Exclusões',
  VIEW_TERRITORIES: 'Exibir territórios',
  VIEW_QUOTAS: 'Exibir cotas',
  NO_ACTIVITIES_TOOLTIP: 'Você ainda não adicionou nenhum dado de atividade para este cartão de batalha.',
  COINSORT_COMPLETE_TOOLTIP: 'Execute a classificação de moedas para obter as estatísticas mais precisas',
  COINSORT_RUNNING_TOOLTIP: 'Estamos classificando suas atividades em territórios. Volte em breve.',
  HIDDEN: 'Oculto',
  NON_MAPPABLE_TOOLTIP: 'Este território contém regras complexas e não é capaz de renderizar no seu mapa',
  CREATE_TERRITORY_TOOLTIP_GEO: 'Selecione limites não definidos para criar um novo território.',
  IMPORT_QUOTAS: 'Importar cotas',
  REQUIRED: 'Obrigatório *',
  OPTIONAL: 'Opcional',
  ADD_RULE: 'Adicionar regra',
  HIERARCHY_MEMBER_DELETE_SUCCESS: 'Membro da hierarquia excluído com sucesso.',
  YOUR_FILE_IS_MISSING: 'Seu arquivo não tem pelo menos {count} coluna(s) obrigatória(s).',
  DOWNLOAD_QUOTA_FILE_TEXT: 'Baixar modelo de arquivo de cota',
  DOWNLOAD_SELLER_ASSIGNMENT_FILE_TEXT: 'Baixar modelo de arquivo de atribuição de vendedor',
  DOWNLOAD_ACTIVITY_FILE_TEXT: 'Baixar modelo de arquivo de atividade',
  DOWNLOAD_LOCATION_TEMPLATE_CSV: 'Baixar modelo de arquivo CSV',
  CONFLICTING_RULES: 'Regras conflitantes',
  CONFLICTING_RULES_GRID_TOOLTIP: 'Este território contém regras que entram em conflito com outro território',
  REMOVE_CONFLICT_BUTTON: 'Remover conflitos',
  REMOVE_CONFLICT_DIALOG_TITLE: 'Remover conflitos de regras de território?',
  NO_TERRITORY_RULES:
    'Os territórios a seguir não contêm regras de território, portanto, não há nada para mostrar no mapa: {territories}',
  HIDE_GRID: 'Ocultar grade',
  SHOW_GRID: 'Mostrar grade',
  CHANGE_COLOR: 'Alterar cor',
  UPLOAD_HIERARCHY: 'Enviar hierarquia',
  UPLOAD_HIERARCHY_NONE: 'Selecione uma hierarquia para enviar',
  UPLOAD_TO: 'Enviar para {name}',
  SET_SEASONALITY: 'Definir sazonalidade',
  APPLY: 'Aplicar',
  FORMULA_EDITOR_TOOLTIP_MESSAGE:
    'Selecione operadores e fontes de dados para criar sua fórmula ou digite-a nesta caixa de texto.',
  CALCULATED_FIELD_EXAMPLE: 'Exemplo: (Renda-Despesas)*0,4',
  OPERATORS: 'Operadores',
  CANNOT_VIEW_TERRITORY_GROUP: 'Você só pode visualizar grupos de território que você possui',
  START_WORKFLOW: 'Iniciar fluxo de trabalho',
  STOP_WORKFLOW: 'Parar fluxo de trabalho',
  START_TERRITORY_WORKFLOW_HEADER: 'Iniciar fluxo de trabalho do território?',
  START_TERRITORY_WORKFLOW_BODY:
    'Você quis iniciar este fluxo de trabalho do território? Isso notificará todos os colaboradores de que o fluxo de trabalho foi iniciado. Os colaboradores poderão visualizar e deixar comentários sobre os territórios que possuem.',
  STOP_TERRITORY_WORKFLOW_HEADER: 'Parar fluxo de trabalho do território?',
  STOP_TERRITORY_WORKFLOW_BODY:
    'Você quis parar este fluxo de trabalho do território? Isso notificará todos os colaboradores de que o fluxo de trabalho foi parado. Os colaboradores não poderão mais acessar o ciclo de planejamento.',
  RETRIEVE_TERRITORY_WORKFLOW_ERROR:
    'Estamos com problemas para carregar suas opções de fluxo de trabalho. Para iniciar ou parar seu fluxo de trabalho, atualize a página.',
  START_TERRITORY_WORKFLOW_ERROR:
    'Estamos tendo problemas para iniciar este fluxo de trabalho agora. Atualize a página e tente novamente.',
  STOP_TERRITORY_WORKFLOW_ERROR:
    'Estamos tendo problemas para parar este fluxo de trabalho agora. Atualize a página e tente novamente.',
  ENTER_COMMENT: 'Insira um comentário',
  COMMENT_PANEL_LOADING_ERROR:
    'Estamos com problemas para carregar esses comentários no momento. Tente atualizar a página.',
  POST_COMMENT_ERROR: 'Estamos com problemas para publicar este comentário. Tente novamente.',
  SELLER_ASSIGNMENT: 'Atribuição do vendedor',
  ADD_MORE: 'Adicionar mais',
  GET_QUOTA_WORKFLOW_STATUS_ERROR: 'Não é possível recuperar o status do fluxo de trabalho da cota do servidor.',
  SEASONALITY_INSTRUCTION:
    'Insira pesos de sazonalidade personalizados ou escolha uma sazonalidade existente para começar. O valor total alocado deve ser igual a 100%.',
  SET_PC_DURATION_PERIODICITY_WARNING: 'Aviso de duração e periodicidade do plano',
  SET_PC_DURATION_PERIODICITY_WARNING_DETAIL_UPDATED:
    'Revise a duração do seu plano e o ciclo de intervalo de periodicidade. Você não poderá alterá-las depois de criar seu ciclo de planejamento.',
  IMPORT_SELLER_ASSIGNMENTS: 'Importar atribuições do vendedor',
  MULTIPLE: 'Vários',
  MISSING_CLOSED_PARENTHESIS_ERROR:
    'Falta um parênteses fechado nesta fórmula. Certifique-se de associar todos os parênteses abertos e fechados.',
  INVALID_DATA_SOURCE_ERROR:
    'Esta fórmula contém uma fonte de dados não reconhecida. Verifique suas fontes de dados para ver se a ortografia está correta.',
  OWNERSHIP: 'Propriedade',
  ADD_SELLER: 'Adicionar vendedor',
  EXPORT_TO_CSV: 'Exportar para arquivo .csv',
  EXPORTING_DATA: 'Exportando dados...',
  EXPORTING_DATA_FAILED: 'Falha ao exportar dados',
  TERRITORY_WORKFLOW_NOT_STARTED: 'Seu administrador não iniciou um fluxo de trabalho de planejamento de território.',
  QUOTA_WORKFLOW_NOT_STARTED: 'Seu administrador não iniciou um fluxo de trabalho de planejamento de cota.',
  DELETE_SELLER_ASSIGNMENT: 'Excluir atribuição?',
  DELETE_SELLER_ASSIGNMENT_CONFIRMATION_MESSAGE:
    'Você quer excluir esta atribuição de vendedor? A exclusão não pode ser desfeita',
  EDIT_ASSIGNMENT: 'Editar atribuição',
  FORMULA_REQUIRED: 'Forneça uma fórmula válida.',
  DELETE_SELLER_ASSIGNMENT_SUCCESS: 'Atribuição do vendedor excluída com sucesso.',
  DELETE_SELLER_ASSIGNMENT_ERROR: 'Não é possível excluir a atribuição do vendedor.',
  DELETE_SELLER_FROM_MULTIPLE_TERRITORIES_ERROR: 'Nem todos os vendedores foram excluídos com sucesso.',
  REMOVE_SELLER_TITLE: 'Vendedor removido',
  REMOVE_SELLERS_TITLE: 'Vendedores removidos',
  REMOVE_SELLER_ERROR_TITLE: 'Falha ao remover vendedores',
  PERCENTAGE: 'Porcentagem',
  KEEP: 'Manter',
  ADD_GEO_HIERARCHY_TITLE: 'Adicionar dados de hierarquia geográficas',
  ADD_GEO_HIERARCHY_DESC:
    'Selecione em uma lista de países compatíveis para adicionar dados de hierarquia de geografias padrão ao seu ciclo de planejamento.',
  GEO_GRID_DESC:
    'Em seguida, selecione o nível geográfico que deseja usar para planejar seus territórios, por exemplo, país, estado ou CEP.',
  NO_IN_USE_GEO_DESC: 'Adicione dados de hierarquia geográfica antes de criar territórios no mapa.',
  ADD_GEO_HIERARCHY_DROP_DOWN_LABEL: 'Selecione países',
  EXTERNAL_DOCS_LINK: 'Clique aqui para saber mais sobre os níveis geográficos',
  RAMP: 'Rampa',
  RENEW: 'Renovar',
  GEO_HIERARCHY_UPDATED_SUCCESS: 'Hierarquias geográficas atualizadas',
  GEO_HIERARCHY_UPDATED_ERROR: 'Ocorreu um erro ao atualizar as hierarquias geográficas',
  GEO_HIERARCHY_GET_ERROR: 'Ocorreu um erro ao obter suas hierarquias geográficas',
  PLAN_TERRITORIES: 'Planejar territórios',
  PLAN_QUOTAS: 'Planejar cotas',
  LOOKUP: 'Pesquisa',
  SCHEDULE_NAME: 'Nome da programação',
  ENTER_NAME: 'Digite o nome',
  MONTH: 'Mês {value}',
  SEASONALITY_SUM_MESSAGE: 'Seu agendamento mensal de sazonalidade deve totalizar 100%.',
  RAMP_VALUE_MESSAGE:
    'Um mês em seu agendamento de rampa excede 100%. Ajuste seu agendamento de rampa para que todos os meses fiquem entre 0% e 100%.',
  PERCENTAGE_VALUE_MESSAGE: 'Apenas valores numéricos maiores ou iguais a 0 são aceitos.',
  NO_END_DATE: 'Nenhuma data de término',
  NO_START_DATE: 'Nenhuma data de início',
  SCHEDULE_NAME_EXIST_ERROR: 'O nome do agendamento já existe.',
  SCHEDULE_NAME_EMPTY_ERROR: 'Insira um nome para o agendamento.',
  ENTER_AMOUNT: 'Inserir valor',
  RAMP_SCHEDULES: 'Agendamentos de rampa',
  SEASONALITY_SCHEDULES: 'Agendamentos sazonais',
  VIEW_RAMP_DATA: 'Exibir dados da rampa',
  VIEW_SEASONALITY_DATA: 'Exibir dados de sazonalidade',
  GET_LOOKUP_TABLE_SCHDULES_ERROR: 'Não é possível recuperar agendamentos',
  UPSERT_LOOKUP_ROW_ERROR: 'Não é possível adicionar o novo agendamento',
  SYMON_ERROR_MESSAGE_2: 'Atualize a página e tente novamente.',
  REFRESH: 'Atualizar',
  SYMON_IMPORT_THIRD_PARTY_HEADER_OVERRIDE: 'Conectores de dados ({num})',
  RAMP_TABLE_MESSAGE: 'Seu agendamento de rampa mensal deve estar entre 0 e 100.',
  SELLERS_ADDED: 'Vendedores adicionados',
  FAILED_TO_ADD_SELLER: 'Falha ao adicionar vendedores',
  FAILED_TO_ADD_SELLER_MESSAGE:
    'Não foi possível adicionar vendedores ao seu território. Atualize a página e tente novamente.',
  SYMON_UPLOAD_START_MESSAGE: 'Estamos enviando seu arquivo.',
  SYMON_IMPORT_START_MESSAGE: 'Iniciamos sua importação. Você pode acessá-lo na guia Exibir fontes.',
  SYMON_UPLOAD_ERROR: 'Não foi possível enviar seu arquivo.',
  IMPORT_ERRORS: 'Erros de importação',
  VIEW_DETAILS: 'Exibir detalhes',
  COPY_SUMMARY: 'Copiar resumo',
  ERROR_SUMMARY_COPIED_TO_CLIPBOARD: 'O resumo de erros foi copiado para a área de transferência',
  PLEASE_SELECT_A_START_DATE: 'Selecione uma data de início.',
  PLEASE_SELECT_AN_END_DATE: 'Selecione uma data de término.',
  SEASONALITY_TOOLTIP_MESSAGE:
    'É aqui que você aplica seu agendamento de sazonalidade. Use esses agendamentos para dividir a cota em todo o ciclo de planejamento com base nas tendências sazonais.',
  PLANNED_QUOTA_TOOLTIP_MESSAGE:
    'Este é o valor final da cota planejada do seu ciclo de planejamento ativo. Ele inclui a meta de cota alocada (de cima para baixo ou importada) mais quaisquer ajustes de cota.',
  ASSIGNMENT_PERCENTAGE_TOOLTIP_MESSAGE:
    'Esta é a porcentagem da cota do território atribuída aos vendedores durante suas datas de entrada em vigor.',
  RAMP_TOOLTIP_MESSAGE:
    'É aqui que você aplica seu agendamento de rampa. Use esses agendamentos para integrar novos vendedores e aumentar a produtividade deles.',
  HISTORICAL_MEASURE_DATE_RANGE_TOOLTIP_MESSAGE:
    'Selecione uma data inicial e final para incluir apenas dados de atividade dentro desse intervalo de datas. Se você não selecionar uma data, usaremos como padrão as datas de início e término do ciclo de planejamento.',
  MAP_DISABLED_TOOLTIP_MESSAGE:
    'Para usar o mapa, adicione a hierarquia de geografias ao seu cartão de batalha como um tipo de grupo de território',
  NEW_FIELD: 'Novo campo',
  EXISTING_FIELD: 'Campo existente',
  ADD_EXISTING_FIELD: 'Adicionar campo existente',
  SELECT_MEASURE_NAME_REQUIRED: 'Selecione um nome da coluna.',
  SELECT_COLUMN_NAME: 'Selecione um nome da coluna',
  NO_EXISTING_MEASURES: 'Nenhuma medida existente válida',
  REMOVE_SELLER_ASSIGNMENT: 'Remover atribuição de vendedor',
  ACTIVATE: 'Ativar',
  ACTIVATE_WITH_NAME: 'Ativar {name}?',
  ACTIVATION_STARTED: 'Ativação iniciada',
  ACTIVATE_PLANNING_CYCLE: 'Ativar ciclo de planejamento',
  ACTIVATE_PLANNING_CYCLE_CONFIRMATION:
    'Você está pronto para ativar seu ciclo de planejamento {name}? Isso criará uma nova versão de gerenciamento do seu ciclo de planejamento, com base na versão final do seu plano. Depois de ativar a versão do plano, ela ainda estará disponível, mas deverá ser usada apenas para referência.',
  ACTIVATE_PLANNING_CYCLE_MANAGE_VERSION:
    'Sua versão de gerenciamento permite o planejamento contínuo durante todo o ciclo de planejamento. Use-a para se adaptar às mudanças de mercado ou rotatividade de funcionários e mantenha a versão do seu plano preservada para referência.',
  ACTIVATE_PLANNING_CYCLE_BEFORE_MESSAGE:
    'Antes de ativar este ciclo de planejamento, certifique-se de que todas as partes interessadas necessárias tenham aprovado.',
  ACTIVATE_PLANNING_CYCLE_WARNING:
    'Você só pode ativar seu ciclo de planejamento uma vez e a ativação não pode ser desfeita.',
  ACTIVATION_STARTED_MESSAGE:
    'Estamos ativando a versão de gerenciamento do seu ciclo de planejamento {name}. Isso pode levar alguns minutos. Não faça alterações em seu ciclo de planejamento até que este processo seja concluído.',
  CREATE_QUOTA_SHEET: 'Criar planilha de cota',
  QUOTA_SHEET_CREATED: 'Planilha de cotas criada com sucesso',
  CREATE_QUOTA_SHEET_ERROR: 'Não foi possível criar uma planilha de cota.',
  QUOTA_BLANK_NAME_ERROR: 'O nome da planilha de cota não pode ficar em branco.',
  EDIT_SHEET_NAME: 'Editar nome da planilha',
  SHEET_RENAME_SUCCESS: 'Planilha renomeada com sucesso',
  REVISED_TERRITORY_QUOTA: 'Cota de território revisada',
  ASSIGNED_SELLER_QUOTA: 'Cota de vendedor atribuída',
  CHANGE_HISTORY_EXPORT_STARTED: 'Exportação iniciada',
  CHANGE_HISTORY_EXPORT_ERROR: 'Falha na exportação',
  CHANGE_HISTORY_EXPORT_SUCCESS: 'Trilha de auditoria exportada',
  EXPORT_CHANGE_HISTORY: 'Exportar trilha de auditoria',
  CHANGE_HISTORY_TOAST:
    'Estamos exportando sua trilha de auditoria para seu ciclo de planejamento {planningCycleName}.',
  CHANGE_HISTORY_EXPORT_SUCCESS_DESCRIPTION:
    'Exportamos sua trilha de auditoria para seu ciclo de planejamento {planningCycleName}.',
  ACTIVATION_FAILED: 'Falha na ativação',
  ACTIVATION_FAILED_MESSAGE:
    'Não foi possível ativar seu ciclo de planejamento {name}. Atualize a página e tente novamente.',
  ACTIVATE_PLANNING_CYCLE_COMPLETED: 'Ativação concluída',
  ACTIVATION_COMPLETED_MESSAGE:
    'Ativamos seu ciclo de planejamento {name}. Agora você está gerenciando seu ciclo de planejamento.',
  EQUALS: 'Igual',
  NOT_EQUAL: 'Diferente',
  LESS_THAN: 'Menor do que',
  LESS_THAN_OR_EQUALS: 'Menor ou igual a',
  GREATER_THAN: 'Maior do que',
  GREATER_THAN_OR_EQUAL: 'Maior ou igual a',
  CONTAINS: 'Contém',
  NOT_CONTAINS: 'Não contém',
  STARTS_WITH: 'Começa com',
  ENDS_WITH: 'Termina com',
  COPY_WITH_NAME: 'Copiar {name}?',
  COPY_PC_MESSAGE:
    'Este ciclo de planejamento está ativo e existe uma versão de gerenciamento. Quando você copia este ciclo de planejamento, copiamos os dados da versão original do seu plano. Isso significa que as alterações feitas na versão de gerenciamento não são copiadas. Seu novo ciclo de planejamento incluirá apenas uma versão do plano, até que você opte por ativá-lo. Deseja continuar?',
  DELETE_HIERARCHY_CONFIRMATION_MESSAGE:
    'Você queria excluir esta hierarquia? Isso excluirá a hierarquia e todos os seus membros. Isso também quebrará quaisquer regras de território que façam referência à hierarquia. A exclusão não pode ser desfeita.',
  DELETE_HIERARCHY_MEMBER_CONFIRMATION_MESSAGE:
    'Você queria excluir este membro da hierarquia? Isso excluirá o membro da hierarquia e todos os seus filhos. Isso também quebrará quaisquer regras de território que façam referência ao membro da hierarquia. A exclusão não pode ser desfeita.',
  DELETE_HIERARCHY_MEMBERS_CONFIRMATION_MESSAGE:
    'Você queria excluir estes membros da hierarquia? Isso excluirá os membros da hierarquia e todos os seus filhos. Isso também quebrará quaisquer regras de território que façam referência aos membros da hierarquia. A exclusão não pode ser desfeita.',
  DELETE_HIERARCHY_CUSTOM_ATTRIBUTE: 'Isso excluirá valores de atributo para todos os membros.',
  QUOTA_GRID_ERROR:
    'Não foi possível gerar dados de grade. Execute a classificação de moedas primeiro para o cartão de batalha e tente novamente.',
  ADD_QUOTA_ADJUSTMENT: 'Adicionar ajuste de cota',
  EXCLUDE_COLUMN: 'Excluir coluna',
  DELETE_MULTIPLE_TERRITORIES_DIALOG_TITLE: 'Excluir territórios?',
  DELETE_BC_TERRITORIES_CONFIRM:
    'Você quis excluir {count} territórios neste cartão de batalha? A exclusão não pode ser desfeita.',
  DELETE_BC_TERRITORY_CONFIRM:
    'Você quis excluir {count} território neste cartão de batalha? A exclusão não pode ser desfeita.',
  DELETE_ALL_BATTLECARD_TERRITORIES_CONFIRMATION_MESSAGE:
    'Você quis excluir todos os territórios neste cartão de batalha? A exclusão não pode ser desfeita.',
  DELETE_BC_TERRITORIES_WITH_EXCEPTION:
    'Você quis excluir todos os territórios deste cartão de batalha, exceto {count} territórios que não foram selecionados? A exclusão não pode ser desfeita.',
  DELETE_BC_TERRITORY_WITH_EXCEPTION:
    'Você quis excluir todos os territórios deste cartão de batalha, exceto {count} território que não foi selecionado? A exclusão não pode ser desfeita.',
  DELETE_TG_TERRITORIES_CONFIRM:
    'Você quis excluir {count} territórios desse grupo de territórios? A exclusão não pode ser desfeita.',
  DELETE_TG_TERRITORY_CONFIRM:
    'Você quis excluir {count} território desse grupo de territórios? A exclusão não pode ser desfeita.',
  DELETE_ALL_TERRITORY_GROUP_TERRITORIES_CONFIRMATION_MESSAGE:
    'Você quis excluir todos os territórios desse grupo de territórios? A exclusão não pode ser desfeita.',
  DELETE_TG_TERRITORIES_WITH_EXCEPTION:
    'Você quis excluir todos os territórios neste grupo de territórios, exceto {count} territórios que não foram selecionados? A exclusão não pode ser desfeita.',
  DELETE_TG_TERRITORY_WITH_EXCEPTION:
    'Você quis excluir todos os territórios neste grupo de territórios, exceto {count} território que não foi selecionado? A exclusão não pode ser desfeita.',
  HOW_IT_WORKS: 'Como funciona:',
  START_OPTIMIZATION: 'Iniciar otimização',
  EXPORT_CURRENT_TERRITORY_RULES: 'Exportar regras de território atuais',
  CONSTRAINTS: 'Restrições',
  PARAMETERS_AND_TERRITORY_RULES: 'Parâmetros e regras de território',
  OPTIMIZE_RESULTS: 'Otimizar resultados',
  OPTIMIZE_TERRITORIES: 'Otimizar territórios',
  OPTIMIZATION_SETUP: 'Configuração de otimização',
  OPTIMIZE_TERRITORIES_FOR_TG: 'Otimizar territórios para {territoryGroup}',
  TERRITORY_OPTIMIZATION: 'Otimização do território',
  TERRITORY_OPTIMIZATION_TITLE: 'Otimização — A solução definitiva para aprimorar seu plano de vendas!',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP1:
    'Você terá a opção de definir parâmetros e restrições para executar a ferramenta de otimização.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_STEP2:
    'Em seguida, você pode selecionar entre três cenários diferentes de territórios que melhor se alinham aos seus objetivos de negócios. E, caso não esteja satisfeito, você pode exportar suas regras de território atuais para reverter os resultados e garantir controle total sobre seus territórios.',
  FAIL_BULK_DELETE_TERRITORY_TITLE: 'Falha ao excluir territórios',
  FAIL_BULK_DELETE_TERRITORIES: 'Falha ao excluir {count} territórios.',
  FAIL_BULK_DELETE_TERRITORY: 'Falha ao excluir {count} território.',
  START_BULK_DELETE_TERRITORY_TITLE: 'Os territórios estão sendo excluídos',
  START_BULK_DELETE_TERRITORY_GENERIC: 'Estamos excluindo territórios selecionados.',
  TERRITORY_DEFINITION_RADIO_LABEL: 'Você quer que seus territórios sejam definidos por contas ou geografias?',
  RUN_TIME_SELECT_LABEL: 'Selecione um tempo de execução com base no nível desejado de precisão.',
  METRIC_OPTIMIZATION_SELECT_LABEL: 'Selecione um atributo ou métrica para otimizar seus territórios.',
  NUMBER_OF_TERRITORIES: 'Número de territórios',
  GEOGRAPHIC_BASED_RULES: 'Regras com base geográfica',
  CONSTRAINTS_PAGE_DESCRIPTION: 'Você pode definir restrições em suas contas e nos territórios resultantes.',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_ACCOUNTS: 'Não mova contas se todas as condições a seguir forem verdadeiras',
  IF_ALL_COMBINATOR_DESCRIPTION_TEXT_TERRITORIES:
    'Otimize territórios para que todas as condições a seguir sejam verdadeiras',
  CURRENT_STATE: 'Estado atual',
  MOST_BALANCED: 'Mais equilibrado',
  MOST_CONTIGUOUS: 'Mais contíguo',
  OPTIMIZE: 'Otimizar',
  DISRUPTION: 'Interrupção',
  TERRITORY_MAP: 'Mapa territorial',
  OPTIMIZATION_METRICS: 'Métricas de otimização',
  OPTIMIZING_TERRITORIES: 'Estamos otimizando seus territórios.',
  OPTIMIZING_TERRITORIES_DESCRIPTION: 'Você será notificado quando a otimização estiver concluída.',
  OPTIMIZATION_FAILED: 'Falha na otimização',
  OPTIMIZATION_FAILED_DESCRIPTION:
    'Não foi possível otimizar seus territórios com base nos parâmetros e restrições selecionados. Atualize seus parâmetros ou restrições e tente novamente.',
  OPTIMIZATION_SUCCESS: 'Seus territórios estão otimizados.',
  OPTIMIZED_TERRITORIES: 'Otimizamos seus territórios para o seu tipo de grupo de território {territoryGroup}.',
  BACK_TO_CONSTRAINTS: 'Voltar às restrições',
  VIEW_RESULTS: 'Exibir resultados',
  OPTIMIZATION_RESULTS: 'Resultados de otimização para {territoryGroup}',
  MIN_NUMBER_OF_ACCOUNTS: 'Número mínimo de contas',
  MAX_NUMBER_OF_ACCOUNTS: 'Número máximo de contas',
  DISRUPTION_ACCOUNTS_REASSIGNED: 'Interrupção (contas reatribuídas)',
  CURRENT_STATE_DESCRIPTION: 'Sua configuração original de territórios e contas',
  MOST_BALANCED_DESCRIPTION: 'Uma distribuição igual do atributo selecionado entre territórios',
  MOST_CONTIGUOUS_DESCRIPTION: 'Territórios geograficamente contíguos que compartilham uma fronteira comum',
  UNSELECT: 'Desfazer a seleção',
  OPTIMIZATION_RESULTS_PAGE_HEADER_DESCRIPTION:
    'Selecione um cenário para publicar nas regras do seu território. Você pode visualizar um cenário para visualizar um detalhamento dos territórios e métricas de otimização.',
  PUBLISH_OPTIMIZATION_DESCRIPTION:
    'Você está prestes a publicar alterações nas regras do seu território para o tipo de grupo do seu território {territoryGroup}. Isso classificará suas contas em seus territórios com base na execução de otimização. Tem certeza de que deseja continuar?',
  EXIT_OPTIMIZATION: 'Otimização de saída',
  PUBLISHED_OPTIMIZATION_TITLE: 'Você otimizou seu grupo de território {territoryGroup}!',
  VIEW_OPTIMIZATION_SUMMARY_TEXT:
    'Enquanto atualizamos as regras do seu território, aqui está um resumo dos seus novos territórios.',
  SCORE_BREAKDOWN: 'Detalhamento da pontuação',
  OPTIMIZATION_PUBLISH_SUBTITLE: 'Seus territórios agora estão equilibrados com base na métrica selecionada',
  TERRITORY_QUOTA: 'Cota do território',
  SELLER_QUOTA: 'Cota do vendedor',
  ACCOUNT_QUOTA: 'Cota da conta',
  TERRITORY_BALANCING: 'Equilíbrio territorial',
  ASSIGNED_SELLERS: 'Atribuímos {count} vendedores a {territory}',
  LOADING_SHEETS_DATA: 'Carregando dados de planilhas...',
  BOOK_OF_BUSINESS: 'Livro de negócios',
  YEARLY: 'Anualmente',
  SEMI_ANNUAL: 'Semestralmente',
  QUARTERLY: 'Trimestralmente',
  MONTHLY: 'Mensalmente',
  SORTING_COINSORT: 'Classificando territórios ({currentNumberOfRulesProcessed} de {currentNumberOfRules})...',
  INPUT: 'Entrada',
  HISTORICAL: 'Histórico',
  LOADED: 'Carregado',
  ALLOCATION: 'Alocação',
  CALCULATED: 'Calculado',
  BATTLE_CARD_OWNER: 'Proprietário do cartão de batalha',
  NAME_IS_A_REQUIRED_FIELD: 'O nome deve ser informado',
  IN_PROGRESS: 'Em andamento',
  APPROVED: 'Aprovado',
  SUBMITTED: 'Enviado',
  REJECTED: 'Recusado',
  APPROVER: 'Aprovador',
  ACCOUNT_KEY: 'Chave da conta',
  CREATED_WITH_DATE: 'Criado: {date}',
  VISUALIZE: 'Visualizar',
  VISUALIZATION_SETTINGS_DIALOG_EXPLANATION: 'Escolha os tipos de regras de território que você deseja ver no mapa.',
  VISUALIZATION_SETTINGS_DIALOG_WARNING: 'Algumas ações não estão disponíveis ao visualizar regras complexas.',
  CREATE_USER_PROFILE: 'Criar perfil de usuário',
  SOMETHING_WENT_WRONG: 'Algo deu errado',
  BULK_INVITE_ERROR_MESSAGE: 'Não foi possível enviar nenhum convite. Tente novamente',
  INVITE_EXCEEDS_BATCH_LIMIT: 'Não é possível convidar mais de 100 usuários por vez.',
  USER_PROFILE_ADDED: 'Perfil de usuário adicionado',
  USER_PROFILE_CREATION_SUCCESS: 'Adicionamos {name} aos seus usuários.',
  ACCESS_GRANTED_TITLE: '{systemRoleName} acesso concedido',
  INVITATION_SENT: 'Um convite foi enviado.',
  INVITATIONS_SENT: 'Os convites foram enviados a {count} usuários.',
  ENTER_EMPLOYEE_ID: 'Insira uma ID de funcionário.',
  ENTER_FIRST_NAME: 'Insira um nome.',
  ENTER_LAST_NAME: 'Insira um sobrenome.',
  ENTER_EMAIL: 'Insira um e-mail.',
  ROLE_REQUIRED: 'Função *',
  CONTRIBUTOR_INVITE_CALLOUT: 'Este usuário receberá acesso de colaborador. Um convite será enviado.',
  ADMIN_INVITE_CALLOUT: 'Este usuário receberá acesso de administrador. Um convite será enviado.',
  PLANNING_CYCLE_START_DATE_PROGRESS_HEADING: 'Salvar ciclo de planejamento em andamento',
  HIDE_ACCOUNTS: 'Ocultar contas',
  PINS_ACTIVATED_TOAST_TITLE: 'Pins ativados',
  PINS_ACTIVATED_TOAST_BODY:
    'Os pins agrupados foram ativados. Agora você pode interagir com seus pins neste modo de seleção.',
  DELETE_REDIRECT_START_RADIO: 'Ajustar a data de início da próxima mudança de conta agendada:',
  DELETE_REDIRECT_END_RADIO: 'Ajustar a data final da mudança da conta anterior:',
  DELETE_REDIRECT_ORIGINAL_RADIO: 'Mover a conta de volta para o território original:',
  QUOTA_TARGET_TOP_DOWN: 'Meta de cota (de cima para baixo)',
  ACCOUNT_TOGGLE_TOOLTIP: 'Isso permite que você selecione suas contas existentes.',
  ALLOCATE_QUOTAS_BASED_ON: 'Alocar cotas com base em',
  PRESERVE_SET_SEASONALITY: 'Preservar a sazonalidade do conjunto',
  CREATE_TERRITORY: 'Criar território',
  REASSIGN_GEOGRAPHIC_REGION: 'Reatribuir região geográfica',
  TERRITORY_REQUIRED_MARK: 'Território *',
  TERRITORY_NAME_WITH_COLON: 'Nome do território:',
  TERRITORY_NAME_WITH_VALUE: 'Nome do território {value}',
  ACCOUNT_OVERRIDES: 'substituições de conta',
  DEFAULT: 'Padrão',
  DEFAULT_WITH_BRACKETS: '(Padrão)',
  GEOGRAPHY_RULES_ONLY: 'Somente regras geográficas',
  COMPLEX_RULES: 'Regras complexas',
  CUSTOM_HIERARCHY_RULES: 'regras de hierarquia personalizadas',
  DYNAMIC_HIERARCHY_PLACEHOLDER: '[hierarchy]',
  VISUALIZATION_SETTINGS: 'Visualização de regra',
  AND: 'E',
  OR: 'OU',
  GEOGRAPHY: 'Geografia',
  TOTAL_NUMBER_OF_ACCOUNTS: 'Número total de contas',
  NUMBER_OF_OVERRIDE_ACCOUNTS: 'Número de contas substituídas',
  INCLUDE_OVERRIDE_ACCOUNTS: 'Incluir contas substituídas',
  USERS: 'Usuários',
  TERRIORY_GROUP_LEVEL: 'Nível do grupo territorial',
  QUOTA_COMPONENTS_ONE_OR_MANY_WITH_COLON: 'Componente(s) de cota:',
  QUOTA_BREAKDOWN_BY: 'Detalhamento da cota por',
  QUOTA_BREAKDOWN_VALIDATION_ERROR: 'Selecione apenas até 2 hierarquias.',
  QUOTA_BREAKDOWN_BY_TOOLTIP:
    'Isso permite que você visualize um detalhamento das cotas de seu território com base nas hierarquias selecionadas e ajuste-as de acordo.',
  QUOTA_BREAKDOWN_BY_PLACEHOLDER_TEXT: 'Selecione até 2 hierarquias',
  HIERARCHY_BASED_TARGET_SETTING: 'Configuração de destino baseada em hierarquia',
  HIERARCHY_BASED_TARGET_SETTING_TOOLTIP:
    'Selecione uma hierarquia a ser usada para definir metas de cota específicas. Você só pode selecionar uma hierarquia com {maxMemberCount} ou menos membros',
  ADD_NEW_QC: 'Adicionar novo componente de quota',
  ACTIONS: 'Ações',
  USER_MANAGEMENT_DROPDOWN_TOOLTIP: 'Selecionar usuários com o mesmo tipo de função para enviar convites.',
  USER_MANAGEMENT_ADMINS_INVITE: 'Convidar como administradores',
  USER_SELECTION_FRACTION: '{selectionCount} / {totalCount} selecionado',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE_BODY:
    'Tem certeza de que deseja enviar convites a {selectionCount} usuários? Estes usuários receberão acesso de colaborador.',
  USER_MANAGEMENT_ADMINS_INVITE_BODY:
    'Tem certeza de que deseja enviar convites a {selectionCount} usuários? Estes usuários receberão acesso de administradores.',
  USER_MANAGEMENT_CONTRIBUTORS_INVITE: 'Convidar como colaboradores',
  ROLE_PERMISSIONS_TABLE_VIEW_HEADER_TOOLTIP:
    'Habilitar permissões de exibição dará a essa função acesso apenas à exibição do ciclo de planejamento.',
  ROLE_PERMISSIONS_TABLE_EDIT_HEADER_TOOLTIP:
    'Habilitar permissões de edição dará a essa função acesso apenas à edição do ciclo de planejamento. Para a guia Cota no Plano, certifique-se de que o fluxo de trabalho da cota esteja ativado.',
  ROLE_PERMISSIONS_TABLE_VIEW_CHECKBOX_TOOLTIP: 'O acesso à visualização só pode ser concedido no nível mais alto',
  ROLE_PERMISSIONS_TABLE_EDIT_CHECKBOX_TOOLTIP:
    'Selecionar permissões de edição também ativará as permissões de visualização.',
  TERRITORY_SHEET_GRID_EDIT_UNALLOWED_TOOLTIP: 'Você não tem permissão para editar isso.',
  EXPAND_ALL: 'Expandir tudo',
  COLLAPSE_ALL: 'Recolher tudo',
  ROLE_PERMISSIONS_TABLE_QUOTA_SHEET_INFO:
    'As permissões da planilha de cota podem ser modificadas na guia de dados no centro de comando',
  ROLES: 'Funções',
  ROLE_PERMISSIONS_UPSERT_ERROR: 'Não foi possível salvar suas permissões. Tente novamente',
  ROLE_PERMISSIONS_UPSERT_SUCCESS: 'Atualizamos com sucesso as permissões para a função {role}',
  ROLE_PERMISSIONS_GET_ERROR: 'Não foi possível carregar suas permissões.',
  CHANGE_REQUESTS: 'Solicitações de alteração',
  REVIEW_PENDING_CHANGE_REQUESTS: 'Revisar solicitações de alteração pendentes.',
  SUBMIT_CHANGE_REQUEST: 'Enviar solicitação de alteração',
  VIEW_CHANGE_REQUEST_LOG: 'Exibir log de solicitação de alteração',
  REQUEST_ID: 'ID da solicitação',
  REQUESTED_BY: 'Solicitado por',
  DATE_REQUESTED: 'Data da solicitação',
  DATE_REVIEWED: 'Data da revisão',
  APPROVE_CHANGE_REQUEST_DIALOG_BODY:
    'Você está pronto para aprovar esta solicitação de alteração? As alterações aprovadas serão exibidas na bandeja de dados.',
  REJECT_CHANGE_REQUEST_DIALOG_BODY:
    'Tem certeza de que deseja rejeitar esta solicitação de alteração? As alterações rejeitadas serão descartadas.',
  ADD_A_COMMENT: 'Adicionar um comentário',
  APPROVE_CHANGE_REQUEST: 'Aprovar solicitação de alteração',
  REJECT_CHANGE_REQUEST: 'Rejeitar solicitação de alteração',
  CONTRIBUTOR_ROLES: 'Funções de colaborador',
  ADMINISTRATOR_ACCESS_DESCRIPTION: 'Esta função de usuário tem acesso a todas as funcionalidades',
  QUOTA_COMPONENT_WITH_COLON: 'Componente de cota:',
  SELECTION_INSIGHTS: 'Insights de seleção',
  CLEAR_ALL: 'Limpar tudo',
  CLEAR: 'Limpar',
  FILE_UPLOAD_VALIDATION_ERROR:
    'O arquivo selecionado, "{file}", não tem uma extensão de arquivo .csv. Selecione um outro arquivo ou adicione a extensão de arquivo .csv ao seu arquivo',
  STALE_DATA_MESSAGE:
    'Você está visualizando dados obsoletos. Execute a classificação para atualizar para seus territórios mais recentes.',
  ASSIGNED_TOOLTIP: 'Incluir regiões geográficas atribuídas',
  ASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir contas atribuídas',
  ALL_TOOLTIP: 'Incluir todas as regiões geográficas',
  ALL_TOOLTIP_ACCOUNTS: 'Incluir todas as contas',
  UNASSIGNED_TOOLTIP: 'Incluir regiões geográficas não atribuídas',
  UNASSIGNED_TOOLTIP_ACCOUNTS: 'Incluir contas não atribuídas',
  WILL_START: '{territoryName} começará',
  WILL_END: '{territoryName} terminará',
  LEVEL_WITH_VALUE: 'Nível {value}',
  MULTIPLE_ACCOUNTS_TITLE: 'Várias contas',
  MULTIPLE_ACCOUNTS_BODY:
    'Esta região geográfica contém várias contas. Laçar esse cluster e visualizar os detalhes das contas em seu cartão de insights.',
  UNASSIGN: 'Cancelar definição',
  REASSIGN: 'Reatribuir',
  WORKFLOW_APPROVE_FAILURE: 'Não foi possível aprovar o fluxo de trabalho {actionWorkflowName}',
  WORKFLOW_REJECT_FAILURE: 'Não foi possível rejeitar o fluxo de trabalho {actionWorkflowName}',
  AVAILABLE_TERRITORY_ID: '"{territoryId}" está disponível no grupo territorial "{territoryGroup}".',
  UNAVAILABLE_TERRITORY_ID: '"{territoryId}" não está disponível porque já está em uso.',
  UNAVAILABLE_WITH_PLANNING_CYCLE_ID:
    '"{name}" não está disponível porque a ID do ciclo de planejamento "{slugName}" já está em uso.',
  ARCHIVE_WITH_TITLE: 'Arquivar {title}',
  PLANNING_CYCLE_ARCHIVE_SUCCESS: '{title} agora está arquivado.',
  PLANNING_CYCLE_ARCHIVE_FAILURE: 'Não foi possível arquivar {title}',
  GEO_REGION: 'Região geográfica',
  UPDATE_HIERARCHY_ERROR: 'Não foi possível atualizar {rootName} "{name}".',
  ADDED_HIERARCHY: 'Adicionado {rootName} "{name}".',
  DELETE_SUCCESS_WITH_NAMES_QUOTED: '{nameOne} "{nameTwo}" excluído com sucesso.',
  HIERARCHY_DELETE_ERROR_WITH_NAMES: 'Não foi possível excluir {rootName} "{name}".',
  DELETE_TERRITORY_IN_USE_BY_OVERLAY_ERROR_MESSAGE_GENERIC:
    'Falha ao excluir territórios selecionados porque algumas regras são usadas por um cartão de batalha de apoio. Remova as referências aos territórios selecionados do cartão de batalha de apoio e tente novamente.',
  DELETE_HIERARCHY_MEMBER_WITH_VALUE: 'Excluir membro da hierarquia {value}?',
  DELETE_HIERARCHY_MEMBERS_WITH_VALUE: 'Excluir membros da hierarquia {value}?',
  MIXED_CLUSTER_TOOLTIP: 'Este cluster contém contas de vários territórios',
  MIXED_CLUSTER_WITH_UNASSIGNED_TOOLTIP: 'Este cluster contém contas de vários territórios ou contas não atribuídas',
  OVERASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas que pertencem implicitamente a 2 ou mais territórios',
  UNASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas de contas não atribuídas',
  ASSIGNED_CLUSTER_TOOLTIP: 'Este cluster contém contas de {territoryId} ({territoryName})',
  TERRITORIES_AND_COUNT: 'Territórios ({count})',
  TERRITORY_ID_AND_NAME: '{territoryId} ({territoryName})',
  PRIOR_YEAR_ACTUAL: 'Antes do ano atual: ',
  TOTAL_WITH_FRACTION: 'Total {numerator} / {denominator}',
  PIPE_CONFIGURATION_DELETE_PROMPT:
    'Isso excluirá permanentemente a sua configuração do pipe "{pipeName}, {exportName}" . Não é possível desfazer esta ação. Confirmar?',
  SHOW_CHANGES: 'Mostrar alterações',
  SHOW_IMPACTED_ROWS_ONLY: 'Mostrar apenas linhas impactadas',
  REVIEW_CHANGES: 'Revisar alterações',
  SELECT_SELLER: 'Selecionar vendedor',
  SELECT_TERRITORY_GROUP: 'Selecionar grupo territorial',
  MOVE_ACCOUNTS: 'Mover conta(s)',
  MOVE_ACCOUNTS_DIALOG_BODY: 'Escolha um território para o qual mover as conta(s) selecionadas:',
  OVERRIDE_ACCOUNTS_TITLE: 'Substituir contas?',
  OVERRIDE_REASSIGN_BODY_1: 'Mover as contas selecionadas adicionará regras de substituição ao seu território.',
  COLLAPSE: 'Recolher',
  LAST_OPENED: 'Aberto pela última vez',
  PUBLISHED_TOOLTIP: 'Cenário publicado: {name}',
  UNPUBLISHED_TOOLTIP: 'Cenário não publicado: {name}',
  SCENARIO_PUBLISHED: 'Cenário publicado',
  SCENARIO_PUBLISHED_MESSAGE: 'Publicamos seu cenário {name}.',
  SCENARIO_PUBLISHED_FAILURE: 'Falha ao publicar cenário',
  SCENARIO_DELETE_PROMPT:
    'Isso excluirá permanentemente seu cenário {name}. Não é possível desfazer esta ação. Tem certeza de que deseja excluir?',
  SCENARIO_PUBLISHED_ERROR: '{name} está publicado atualmente',
  SCENARIO_PUBLISHED_ERROR_DESCRIPTION:
    'Seu cenário {name} não pode ser excluído porque está publicado no momento. Publique outro cenário e tente novamente.',
  SCENARIO_DELETED: 'Cenário excluído',
  SCENARIO_DELETED_FAILURE: 'Falha ao excluir cenário',
  LASSO_DROPDOWN_TOOLTIP_ACCOUNTS: 'Filtre sua seleção de laços para incluir contas atribuídas ou não atribuídas.',
  MAP_UNDO_BODY:
    'Você está prestes a desfazer sua alteração mais recente. Você não poderá refazer após a conclusão de desfazer. Tem certeza de que deseja continuar?',
  MAP_UNDO_TOAST_ERROR_TITLE: 'Não é possível desfazer',
  MAP_UNDO_TOAST_ERROR_BODY: 'Algo deu errado. Não foi possível desfazer sua alteração de regra.',
  MAP_UNDO_TOAST_SUCCESS: 'Desfazer foi concluído com sucesso.',
  MODERATE_DEVIATION: 'moderado',
  SIGNIFICANT_DEVIATION: 'significativo',
  MINOR_DEVIATION: 'insignificante',
  DUPLICATE_OVERRIDE_INCLUSION_KEY: 'A chave de inclusão de hierarquia já existe em outra regra de substituição',
  EMPTY_MAP_GRID_TITLE: 'Não é possível carregar visualizações',
  EMPTY_MAP_TGT_GRID_MESSAGE:
    'Você pode não ter territórios dentro do seu tipo de grupo territorial geográfico. Entre em contato com o administrador.',
  EMPTY_MAP_TG_GRID_MESSAGE:
    'Você pode não ter territórios dentro do seu grupo territorial. Entre em contato com o administrador.',
  PASSWORD_LAST_CHANGED: 'Última alteração: {date}',
  MOVE_TERRITORY_SUCCESS_NAMED:
    '{territoryId} território foi movido com sucesso para o grupo territorial {territoryGroup}',
  MOVE_TERRITORY_SUCCESS_MULTIPLE:
    'Vários territórios foram movidos com sucesso para o grupo de território {territoryGroup}',
  BATTLECARD_TYPE_OVERLAY_ICON: 'Ícone Cartão de batalha de apoio',
  BATTLECARD_TYPE_PRIMARY_ICON: 'Ícone Cartão de batalha principal',
  MEMBER_SELECTED_WITH_COUNT: '{count} membro selecionado',
  UPDATED_HIERARCHY: 'Atualizado {rootName} "{name}".',
  COMPLETED: 'Completo',
  FAILED: 'Falhou',
  CREATE_TERRITORY_TOOLTIP_ACCOUNT: 'Selecione contas não definidas para criar um novo território.',
  ADD_OVERRIDE: 'Adicionar substituição',
  OVERRIDE_RULE_TOOLTIP_ENABLED:
    'As substituições serão movidas para este território e removidas de suas regras de território atuais.',
  BASE_RULE: 'Regra básica',
  OVERRIDE: 'Substituir',
  CONFLICTING_RULE: 'Regra conflitante',
  LOCKED: 'Bloqueado',
  REMOVE_CONFLICT_ACTION_TOOLTIP: 'Remova o conflito excluindo a região geográfica do território selecionado',
  REMOVE_CONFLICT_DIALOG_TEXT:
    'Você está prestes a remover os seguintes conflitos de regra de território de {territory}:',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE: 'Adicionar uma fonte de dados ou valor antes de {operator}',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_BEFORE_FULL:
    'Adicionar uma fonte de dados ou valor antes do operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER_FULL:
    'Adicionar uma fonte de dados ou valor após o operador {operator} ({text}).',
  FORMULA_EDITOR_VALUE_EXPECTED_ERROR_AFTER: 'Adicionar uma fonte de dados ou valor após o {operator}',
  NO_RAMP: 'Sem rampa',
  YOUR_MONTH_SEASONALITY_PLEASE_ADJUST:
    'Seu agendamento mensal de sazonalidade total é {value}%. Ajuste seu agendamento para que a soma de todos os meses seja igual a 100%.',
  SYMON_CONNECTION_UPDATED_TITLE: 'Conexão atualizada',
  SYMON_CONNECTION_UPDATED_BODY: 'Seu {connectionType} foi validado',
  SYMON_CONNECTION_FAILURE_TITLE: 'Credenciais inválidas',
  SYMON_CONNECTION_FAILURE_BODY:
    'Não foi possível validar sua conexão {connectionType}. Revise suas credenciais e tente novamente.',
  ROLE_PERMISSIONS: 'Permissões da função',
  SELECT_A_TERRITORY: 'Selecionar um território',
  ACTIVATE_SCENARIO_MANAGE_VERSION:
    'Sua versão de gerenciamento permite o planejamento contínuo durante todo o ciclo de planejamento. Use-a para se adaptar às mudanças de mercado ou rotatividade de funcionários e mantenha seu cenário publicado preservado para referência.',
  ACTIVATE_SCENARIO_BEFORE_MESSAGE:
    'Antes de ativar este cenário, certifique-se de que todas as partes interessadas necessárias tenham aprovado.',
  SCENARIO_ACTIVATION_COMPLETED_MESSAGE:
    'Ativamos seu cenário {name}. Agora você está gerenciando seu ciclo de planejamento.',
  CONTINUE_OPTIMIZATION: 'Continuar otimização',
  FAIL_OPTIMIZE_TERRITORIES: 'Falha ao otimizar territórios',
  TERRITORY_OPTIMIZATION_DESCRIPTION_WARNING:
    'Depois de publicar os resultados da otimização, as regras do seu território atual serão permanentemente modificadas.',
  TERRITORY_OPTIMIZATION_DESCRIPTION_EXPORT:
    'Você tem a opção de reverter as alterações exportando suas regras de território atuais.',
  REMOVE_RULE_HAS_OVERLAY_DEPENDENCY:
    'Algumas regras estão em uso por uma equipe de apoio. Remova da equipe de apoio e tente novamente.',
  REMOVE_RULE_HAS_ONGOING_TERRITORY_OPTIMIZATION:
    'O grupo de regras está sendo otimizado no momento. Tente novamente após a conclusão da otimização do território.',
  FAIL_BULK_DELETE_TERRITORY_GENERIC: 'Falha ao excluir territórios selecionados.',
  CURRENT_SCENARIO: 'Cenário atual',
  BATTLE_CARD_1: 'Cartão de batalha 1:',
  BATTLE_CARD_2: 'Cartão de batalha 2:',
  SCENARIO_PLANNING_BC_PICKER_LABEL: 'Cartão de batalha:',
  WITH: 'Com',
  COMPARE: 'Comparar',
  COMPARE_SELECTION: '{scenarioName} / {quotaComponentName} / ',
  SCENARIO_NAME: 'Nome do cenário',
  SEE_ALL_SCENARIOS: 'Ver todos os cenários',
  SCENARIO_RENAMED: 'Cenário renomeado',
  SCENARIO_RENAMED_MESSAGE: 'Renomeamos seu cenário para {name}.',
  SCENARIO_NAME_EXISTS: 'O nome do cenário "{name}" já existe.',
  SCENARIO_RENAMED_FAILURE: 'Falha ao renomear cenário',
  RENAME_SCENARIO: 'Renomear cenário',
  CHARACTER_COUNT_READOUT: '{currentLength}/{maxLength} caracteres',
  CREATE_SCENARIO: 'Criar cenário',
  DELETING_SCENARIO: 'Excluindo cenário',
  SCENARIO_LIMIT_REACHED: 'Limite do cenário atingido',
  SCENARIO_COPY_FAILED: 'Falha ao copiar cenário',
  SCENARIO_CREATE_FAILED: 'Falha ao criar cenário',
  SCENARIO_CREATED: 'Cenário criado',
  SCENARIO_CREATED_MESSAGE: 'Criamos seu cenário {name}.',
  COPY_SCENARIO: 'Copiar cenário',
  COPY_SCENARIO_NAME: '{name} (cópia)',
  SCENARIO_COPY_STARTED: 'Cópia do cenário iniciada',
  SCENARIO_COPIED: 'Cenário copiado',
  SCENARIO_COPIED_MESSAGE: 'Copiamos seu cenário {name}.',
  SCENARIO_DELETED_MESSAGE: 'Excluímos seu cenário {name}.',
  BLANK_SCENARIO: 'Cenário em branco',
  NO_RECENT_SCENARIOS: 'Não há outros cenários abertos recentemente.',
  NAME_REQUIRED: 'Nome *',
  OPEN_RECENT: 'Aberto recentemente',
  MANAGE_SCENARIOS: 'Gerenciar cenários',
  ACTIVATE_SCENARIO: 'Ativar cenário',
  SCENARIO_WITH_COLON: 'Cenário:',
  COMPARE_MODE: 'Modo de comparação',
  RENAME: 'Renomear',
  LAUNCH_SCENARIO: 'Iniciar cenário',
  COMPARE_MODE_ACROSS_DM_DIALOG_BODY:
    'Visualize, edite e compare mapas de dois cartões de batalha de qualquer cenário dentro do seu ciclo de planejamento.',
  COMPARE_MODE_WITHIN_DM_DIALOG_BODY:
    'Visualize, edite e compare mapas de dois cartões de batalha do seu ciclo de planejamento.',
  COMPARE_MODE_TOOLTIP_BODY: 'As alterações feitas no modo Comparar serão aplicadas aos seus cartões de batalha.',
  COMPARE_MODE_ERROR_MISSING_REQUIRED:
    'Selecione um cenário, componente de cota e cartão de batalha para cada mapa que você deseja comparar.',
  ACCOUNT_MOVES: 'Mudanças da conta',
  PUBLISH_CHANGES_TO: 'Publicar alterações em {value}',
  PUBLISH_OPTIMIZATION_DESCRIPTION_EMPHASIS:
    'A publicação modificará permanentemente suas regras de território atuais.',
  PUBLISH_OPTIMIZATION_DESCRIPTION_WARNING:
    'Você pode reverter as alterações exportando suas regras de território atuais. Tem certeza de que deseja continuar?',
  OPTIMIZATION_PUBLISH_SUBTITLE_GENERAL: 'Seus territórios agora estão equilibrados com base na métrica selecionada.',
  ACCOUNT_LIST: 'Lista de contas',
  TERRITORY_GROUP_TYPE_OWNER: 'Proprietário do tipo de grupo territorial',
  TERRITORY_GROUP_OWNER: 'Proprietário do grupo territorial',
  TERRITORY_GROUP_AND_GROUP_TYPE_OWNER: 'Proprietário do tipo de grupo territorial e grupo territorial',
  ADMINISTRATOR: 'Administrador',
  CONTRIBUTOR: 'Colaborador',
  NONE: 'Nenhum',
  MEMBERS_DELETE_SUCCESS_WITH_COUNT: '{count} membros foram excluídos com sucesso.',
  MEMBERS_WITH_COUNT: '{count} membros',
  SEND: 'Enviar',
  DELETE_VALUE: 'Excluir {value}',
  DELETE_SELLER_FROM_TERRITORY_SUCCESS_MESSAGE: 'Removemos {sellerCount} vendedor de {terCount} território.',
  DELETE_SELLER_FROM_TERRITORIES_SUCCESS_MESSAGE: 'Removemos {sellerCount} vendedor de {terCount} territórios.',
  DELETE_SELLERS_FROM_TERRITORY_SUCCESS_MESSAGE: 'Removemos {sellerCount} vendedores de {terCount} território.',
  DELETE_SELLERS_FROM_TERRITORIES_SUCCESS_MESSAGE: 'Removemos {sellerCount} vendedores de {terCount} territórios.',
  ITEMS_PLUS_OTHERS: '{itemsList} (+{otherCount} outros)',
  REMOVE_CONFLICT: 'Removemos com sucesso {count} conflito de {ruleId}',
  REMOVE_CONFLICTS: 'Removemos com sucesso {count} conflitos de {ruleId}',
  UPDATE_PC_SUCCESS: 'Salvamos as alterações no seu ciclo de planejamento {planningCycleName}.',
  TERRITORIES_DELETED_TOAST_TITLE: 'Territórios excluídos',
  TERRITORIES_DELETED_TOAST_MESSAGE: 'Excluímos {count} territórios.',
  TERRITORIES_DELETED_TOAST_MESSAGE_GENERIC: 'Excluímos todos os territórios selecionados.',
  ACCOUNT_FILTER: 'Filtro de conta',
  FILTERS: 'Filtros',
  SHOW_SELECTED_TERRITORY_ACCOUNTS: 'Mostrar contas para o território selecionado',
  SHOW_ALL_ACCOUNTS: 'Mostrar todas as contas',
  BASE_OVERRIDE_ACCOUNTS: 'Contas base e de substituição',
  OVERRIDE_ACCOUNTS_ONLY: 'Contas substituídas apenas',
  FILTER_ACCOUNTS_TERRITORY_HEADER: 'Filtrar contas por território',
  FILTER_ACCOUNTS_TYPE_HEADER: 'Filtrar contas por tipo',
  EMBEDDED_MAP_FALLBACK_HEADER: 'Opa! Algo deu errado.',
  EMBEDDED_MAP_FALLBACK_BODY_ERROR: 'Tente novamente mais tarde.',
  EMBEDDED_MAP_FALLBACK_BODY_NO_ACCESS: 'Parece que você não tem acesso. Entre em contato com o administrador.',
  CONSTRAINT_FIELD_ERROR: 'Preencha o valor da restrição',
  PLEASE_SELECT_A_METRIC: 'Selecione uma métrica',
  ACCOUNT_ADDITIONS: 'Adições de contas',
  ACCOUNT_REMOVALS: 'Remoções de contas',
  EXISTING_ACCOUNTS: 'Contas existentes',
  ZIP_CODE: 'CEP',
  LETTER_GRADE: 'Grau da carta',
  SIZE: 'Tamanho',
  ACCOUNT_ID: 'ID da conta',
  TARGET_SETTING: 'Configuração de destino',
  TARGET_SETTING_DESCRIPTION:
    'Selecione uma hierarquia personalizada para definir metas de cota para membros individuais da hierarquia',
  PC_START_DATE_POST_COINSORT_FAILURE: 'Não foi possível atualizar a data de início do PC.',
  PC_START_DATE_POST_COINSORT_FAILURE_MESSAGE:
    'Não foi possível atualizar a data de início {planningCycleName}. Tente novamente depois de analisar seus territórios e executar com sucesso uma classificação de moedas.',
  FAILED_TO_LASSO_PINS: 'Falha nos pins de laço. Tente novamente.',
  EXIT: 'Sair',
  UNABLE_TO_RETRIEVE_OPTIMIZATION_STATUS: 'Não foi possível recuperar o status de otimização',
  CANNOT_START_OPTIMIZATION_USER_MESSAGE:
    'Não foi possível iniciar a otimização, pois já existe uma iniciada por outro usuário.',
  RESTART_OPTIMIZATION: 'Reiniciar otimização',
  RESTART_OPTIMIZATION_DESCRIPTION:
    'Seu resultado de otimização atual será descartado. Tem certeza de que deseja reiniciar a otimização?',
  UNLOCK_TERRITORY: 'Desbloquear território',
  LOCK_TERRITORY: 'Bloquear território',
  SHOW_TERRITORY: 'Mostrar território',
  LOCK_ALL_TERRITORIES: 'Bloquear todos os territórios',
  UNLOCK_ALL_TERRITORIES: 'Desbloquear todos os territórios',
  HIDE_TERRITORY: 'Ocultar território',
  SHOW_ALL_TERRITORIES: 'Mostrar todos os territórios',
  HIDE_ALL_TERRITORIES: 'Ocultar todos os territórios',
  EMPTY_TENANT_LIST_MESSAGE: 'Entre em contato com seu administrador de locatários para obter um convite.',
  UNASSIGN_GEO_TITLE: 'Cancelar atribuição do território',
  UNASSIGN_GEO_BODY:
    'Tem certeza de que deseja cancelar a atribuição de {hierarchyCount} regiões geográficas de {ruleNamesTruncated}?',
  UNASSIGN_GEO_SUCCESS_TOAST: 'A atribuição de regiões geográficas foi cancelada com sucesso.',
  UNASSIGN_GEO_ERROR_TOAST: 'Não foi possível cancelar a atribuição de regiões geográficas.',
  UNASSIGN_ACCOUNT_TITLE: 'Cancelar atribuição de substituições',
  UNASSIGN_ACCOUNT_BODY:
    'Tem certeza de que deseja cancelar a atribuição de {hierarchyCount} conta(s) de {ruleNamesTruncated}?',
  UNASSIGN_ACCOUNT_SUCCESS_TOAST: 'O cancelamento de atribuição das substituições foi feito com sucesso.',
  UNASSIGN_ACCOUNT_ERROR_TOAST: 'Não foi possível cancelar a atribuição de substituições.',
  UNASSIGN_ACCOUNT_TOOLTIP: 'Isso permitirá que você cancele a atribuição de contas de substituição selecionadas.',
  REASSIGN_TITLE: 'Reatribuir ao território existente',
  MAP_ACTION_MENU_REASSIGN_GEO: 'Reatribuir regiões geográficas',
  REASSIGN_GEO_BODY: 'Selecione um território onde deseja reatribuir as {count} regiões geográficas',
  REASSIGN_GEO_SUCCESS_TOAST: 'As regiões geográficas foram reatribuídas com sucesso.',
  REASSIGN_GEO_ERROR_TOAST: 'Não foi possível reatribuir as regiões geográficas.',
  MAP_ACTION_MENU_REASSIGN_ACCOUNT: 'Reatribuir conta(s)',
  REASSIGN_ACCOUNT_BODY: 'Selecione um território onde deseja reatribuir {count} conta(s)',
  REASSIGN_ACCOUNT_SUCCESS_TOAST: 'Conta(s) foi (foram) reatribuída(s) com sucesso.',
  REASSIGN_ACCOUNT_ERROR_TOAST: 'Não foi possível reatribuir conta(s).',
  COMPLEX_RULES_STATUS: 'Regras complexas {status}',
  ON: 'LIGADO',
  OFF: 'DESLIGADO',
  FUNCTIONALITY: 'Funcionalidade',
  MAPS: 'Mapas',
  HIERARCHY: 'Hierarquia',
  CUSTOM: 'Personalizado',
  DATA_SOURCE: 'Fonte de dados',
  FORMAT_CODE: 'Código de formato',
  INSERT_CALCULATION: 'Inserir cálculo',
  WELCOME_BACK: 'Bem-vindo de volta!',
  CHOOSE_A_TENANT: 'Escolha um locatário para usar o Planejamento de vendas.',
  SELECT_TENANT: 'Selecionar locatário',
  IMPERSONATE_USER: 'Representar usuário',
  USER_EMAIL_TO_IMPERSONATE: 'E-mail do usuário para representar',
  IMPERSONATE: 'Representar',
  EXIT_IMPERSONATION: 'Sair da representação',
  FAILED_TO_GENERATE_IMPERSONATION_TOKEN: 'Falha ao gerar token de representação. Tente novamente.',
  CUSTOM_HIERARCHY_FILTER_TITLE: 'Filtro de hierarquia personalizado',
  CUSTOM_HIERARCHY_FILTER_DYNAMIC_TITLE: 'Filtrar {hierarchyName}',
  CUSTOM_HIERARCHY_FILTER_KIND_LABEL: 'Tipo de filtro',
  CUSTOM_HIERARCHY_FILTER_IDS_LABEL: 'Selecionar hierarquias',
  CUSTOM_HIERARCHY_FILTER_IDS_EMPTY_PLACEHOLDER: 'Nenhuma hierarquia em uso',
  COLLECTION_FILTER_CONTAINS_ANY: 'Contém qualquer',
  COLLECTION_FILTER_NOT_CONTAINS_ANY: 'Não contém',
  COLLECTION_FILTER_EQUALS_TO: 'É igual a',
  COLLECTION_FILTER_NOT_EQUALS_TO: 'Não é igual a',
  TOO_MANY_SELLERS:
    'Você só pode adicionar {maxSellers} vendedores a um território. \nExcluir um vendedor antes de adicionar um novo.',
  REMOVE_GEO_HIER_HAS_DEPENDENCY:
    'Não é possível remover regiões geográficas, pois elas estão em uso por territórios: {territoryIds}',
  START_FILE_PROCESS_API_KEY_NOT_FOUND:
    'Chave API não encontrada para o usuário {emailAddress}. O usuário deve gerar uma chave API no menu Perfil',
  QUOTA_FILE_UPLOAD_NOTES: 'Algumas observações sobre o uso do modelo gerado:',
  QUOTA_FILE_UPLOAD_WARNING_1: 'Antes de fazer o download, você deve primeiro executar a classificação de moedas.',
  QUOTA_FILE_UPLOAD_WARNING_2:
    'Este modelo é apenas para o componente de cota selecionado. Você deve importar cotas para cada componente de cota no cartão de batalha.',
  QUOTA_FILE_UPLOAD_WARNING_3:
    'Alterar o modelo causará erros de integridade de dados. Atualize apenas a coluna Valor com metas de cota.',
  ACCOUNT_TOOLTIP_MULTIPLE_TERRITORIES_MESSAGE: 'Esta conta pertence a {geoCount} territórios',
  REMOVE_DEFAULT_BALANCING_METRIC:
    'Não é possível excluir essa medida, pois ela requer uma medida de balanceamento padrão.',
  REMOVE_SHEET_MEASURE_FORMULA_HAS_DEPENDENCY: 'Não é possível excluir essa medida, pois ela é usada em {measureName}.',
  OTHER_MEASURES: 'outras medidas',
  MEASURE_NAME_VALIDATION_MESSAGE: 'Os nomes dos campos não podem conter os seguintes caracteres: .,',
  ACCOUNT_RULE_GRID_ERROR: 'Não foi possível carregar a lista de contas.',
  ALLOCATE_QUOTAS: 'Alocar cotas',
  ALLOCATING_TOP_DOWN: 'Alocando de cima para baixo',
  ALLOCATING_TOP_DOWN_LOADING_MESSAGE: 'Aguarde enquanto alocamos suas metas para seu {jobName}',
  SINGLE_ACCOUNT_TERRITORY: 'Território de conta única',
  SINGLE_ACCOUNT_TERRITORY_DISABLE_SAVE_TOOLTIP_TEXT:
    'Não é possível atribuir mais de uma conta a um território de conta única',
  COPILOT: 'Copiloto',
  ASSISTANT_HEADER_TITLE: 'Copiloto de planejamento de vendas',
  ASSISTANT_YOU: 'Você',
  ASSISTANT_HEADER_CLEAR: 'Limpar bate-papo',
  ASSISTANT_HEADER_HIDE: 'Ocultar',
  ASSISTANT_INPUT_PLACEHOLDER: 'Com o que posso ajudá-lo?',
  ASSISTANT_EMPTY_TITLE: 'Como posso ajudá-lo hoje?',
  ASSISTANT_EMPTY_SUBTITLE: 'Pergunte-me qualquer coisa sobre planejamento de vendas',
  ASSISTANT_PROMPT_GENERAL: 'Perguntas gerais',
  ASSISTANT_PROMPT_GENERAL_EXAMPLE: 'por exemplo, onde uma conta, vendedor, território está alinhado?',
  ASSISTANT_PROMPT_GENERAL_INPUT: 'Onde [Account] está alinhado?',
  ASSISTANT_PROMPT_INSIGHTS: 'Insights e análise',
  ASSISTANT_PROMPT_INSIGHTS_EXAMPLE: 'por exemplo, comparar as principais métricas entre 2 mapas',
  ASSISTANT_PROMPT_INSIGHTS_INPUT:
    'Compare as principais métricas entre os mapas [Battle card name] e [Battle card name]',
  ASSISTANT_PROMPT_ACTIONS_EXAMPLE: 'por exemplo, mover uma conta para outro território',
  ASSISTANT_PROMPT_ACTIONS_INPUT: 'Mover [Account] para [Territory]',
  ASSISTANT_PROMPT_HISTORICAL: 'Pesquisa histórica',
  ASSISTANT_PROMPT_HISTORICAL_EXAMPLE: 'por exemplo, quem foi o último usuário a fazer alterações?',
  FILE_UPLOAD_INVALID_FIRST_ROW_ERROR:
    'Verifique se a primeira linha de dados no arquivo carregado começa com "#". Em caso afirmativo, você pode evitar esse erro movendo a primeira coluna para uma posição diferente. Em caso negativo, entre em contato com o suporte.',
  ASSISTANT_PROMPT_HISTORICAL_INPUT: 'Quem foi o último usuário a fazer alterações em [Territory]',
  STATUS_MENU_COINSORT_IN_PROGRESS: 'Executando a classificação de moedas para {battleCardName}',
  STATUS_MENU_COINSORT_COMPLETED: 'A classificação de moedas foi executada para {battleCardName}',
  STATUS_MENU_COINSORT_FAILED: 'Falha ao executar a classificação de moedas para {battleCardName}',
  COINSORT_COMPLETED_TOAST_MESSAGE: 'Executamos a classificação de moedas para seu cartão de batalha {battleCardName}.',
  ALL_DONE: 'Tudo pronto!',
  TASKS_IN_PROGRESS: '{numOfTasks} tarefas em andamento',
  COIN_SORTING_COMPLETED: 'Classificação de moedas concluída',
  COIN_SORTING_FAILED: 'Classificação de moedas falhou',
  VIEW_STATUS: 'Exibir status',
  COIN_SORTING_IN_PROGRESS: 'Classificação de moedas em andamento',
  RUN_COIN_SORT: 'Executar a classificação de moedas',
  STARTED_BY: 'Iniciado por: {userName}',
  DISABLE_ADD_QUOTA_ADJUSTMENT_TOOLTIP: 'Adicionar cota planejada antes de adicionar ajuste de cota de território',
  STARTED: 'Iniciado:',
  JUST_NOW: 'agora',
  A_MINUTE_AGO: 'um minuto atrás',
  ONE_HOUR_AGO: '1 hora atrás',
  HOURS_AGO: '{numHours} horas atrás',
  MINUTES_AGO: '{numMinutes} minutos atrás',
  ASCENDING: 'Ordem ascendente',
  DESCENDING: 'Ordem descendente',
  START_DATE_REQUIRED_MARK_QUOTA_MOVE: 'Data de início efetiva *',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_1: 'Adicionar um território de destino, data de início e alocação de cota para ',
  ACCOUNT_MOVE_WITH_QUOTA_DIALOG_BODY_2: 'Isso será aplicado em todos os componentes de cota',
  TERRITORY_REQUIRED_MARK_V2: 'Território de destino *',
  QUOTA_REALLOCATION_MARK: 'Método de realocação de cota *',
  MOVE_PRORATED_QUOTA: 'Mover cota proporcional',
  SEASONALITY_DISABLE_TOOLTIP_TEXT:
    'A sazonalidade não pode ser modificada, pois as cotas são definidas no nível da hierarquia e não no nível do território.',
  TERRITORY_EFFECTIVE_DATE_DISABLE_TOOLTIP_TEXT:
    'A data de entrada em vigor para este território não pode ser modificada, pois há redirecionamentos de conta associados a este território.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_ACCOUNT_VALUE:
    'O método de realocação de cotas não estará disponível se os componentes da cota tiverem zero ou nenhum valor de cota da conta.',
  MOVE_ACCOUNT_DIALOG_DISABLE_TOOLTIP_TEXT_FOR_NO_OVERLAP_REDIRECT_DATE:
    'O método de realocação de cotas não estará disponível se o período de redirecionamento não se sobrepuser às datas de entrada em vigor das regras de território no destino desejado.',
  TERRITORY_EFFECTIVE_DATE_FOR_TERRITORY_QUOTA_DRILL_IN:
    'Data de entrada em vigor do território: {effectiveDate} - {endDate}',
  ADJUSTMENT_TYPE: 'Tipo de ajuste',
  ACCOUNT_MOVED_FROM: 'Conta movida de',
  ACCOUNT_MOVED_TO: 'Conta movida para',
  ALIGNMENT_EFFECTIVE_DATE: 'Data em vigor do alinhamento'
};

export default ptBR;

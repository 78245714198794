import React, { FC } from 'react';

import { SelectWindow, ChevronDown } from '@carbon/icons-react';
// eslint-disable-next-line no-restricted-imports
import { Menu } from '@varicent/components';

import TextButton from 'components/Buttons/TextButton/TextButton';
import { MenuItemWithTooltip } from 'components/menu/MenuItemWithTooltip';
import Popover, { PopoverProps } from 'components/Popover/Popover';

import { MapSelectionTarget } from 'app/models';

import { formatMessage } from 'utils/messages/utils';

const TARGET_OPTIONS = [
  {
    target: MapSelectionTarget.territories,
    text: formatMessage('TERRITORY'),
    activeText: formatMessage('TERRITORY'),
    tooltipText: formatMessage('TERRITORY_TOGGLE_TOOLTIP')
  },
  {
    target: MapSelectionTarget.polygons,
    text: formatMessage('GEOGRAPHIC_REGION'),
    activeText: formatMessage('GEO_REGION'),
    tooltipText: formatMessage('GEOGRAPHIC_REGION_TOGGLE_TOOLTIP')
  },
  {
    target: MapSelectionTarget.accounts,
    text: formatMessage('ACCOUNT'),
    activeText: formatMessage('ACCOUNT'),
    tooltipText: formatMessage('ACCOUNT_TOGGLE_TOOLTIP')
  }
];

const SelectionTargetDropdownV2: FC<{
  value: MapSelectionTarget;
  isSmallVariant: boolean;
  onChange: (value: MapSelectionTarget) => void;
}> = ({ value: selectedValue, isSmallVariant, onChange }) => {
  const selectedTargetText = TARGET_OPTIONS.find((option) => option.target === selectedValue).activeText;

  return (
    <Popover
      modifiers={popoverModifiers}
      placement="bottom-start"
      content={
        <Menu>
          {TARGET_OPTIONS.map((option) => (
            <MenuItemWithTooltip
              key={option.target}
              data-testid={`target-toggle-${option.target}`}
              active={selectedValue === option.target}
              text={option.text}
              onClick={() => onChange(option.target)}
              tooltip={{ target: option.text, content: option.tooltipText, placement: 'right' }}
            />
          ))}
        </Menu>
      }
    >
      <TextButton
        testId={'selection-target-menu-button'}
        type="button"
        text={selectedTargetText}
        icon={isSmallVariant ? null : <SelectWindow />}
        rightIcon={<ChevronDown />}
        large={false}
        minimal
      />
    </Popover>
  );
};

const popoverModifiers: PopoverProps['modifiers'] = {
  arrow: {
    enabled: false
  }
};

export default SelectionTargetDropdownV2;

import gql from 'graphql-tag';

export const UPSERT_CUSTOM_HIERARCHY = gql`
  mutation UpsertCustomHierarchy(
    $hierarchyId: Int
    $planningCycleId: Int!
    $rootId: Int
    $name: String!
    $key: String!
    $parentKey: String
    $path: String
    $version: Int!
    $customProperties: AWSJSON
  ) {
    upsertCustomHierarchy(
      customHierarchy: {
        hierarchyId: $hierarchyId
        rootId: $rootId
        planningCycleId: $planningCycleId
        name: $name
        key: $key
        parentKey: $parentKey
        path: $path
        version: $version
        customProperties: $customProperties
      }
    ) {
      customProperties
      hierarchyId
      key
      name
      parentKey
      path
      planningCycleId
      version
    }
  }
`;

export const UPSERT_CUSTOMER_ACCOUNT_HIERARCHY = gql`
  mutation UpsertCustomerAccountHierarchy(
    $address1: String
    $address2: String
    $city: String
    $country: String
    $customProperties: AWSJSON
    $customerAccountNumber: String
    $hierarchyId: Int
    $industry: String
    $key: String
    $name: String!
    $path: String
    $parentKey: String
    $planningCycleId: Int!
    $zipPostal: String
    $stateProvince: String
    $version: Int!
  ) {
    upsertCustomerAccountHierarchy(
      input: {
        address1: $address1
        address2: $address2
        city: $city
        country: $country
        customProperties: $customProperties
        customerAccountNumber: $customerAccountNumber
        hierarchyId: $hierarchyId
        industry: $industry
        key: $key
        name: $name
        path: $path
        parentKey: $parentKey
        planningCycleId: $planningCycleId
        zipPostal: $zipPostal
        stateProvince: $stateProvince
        version: $version
      }
    ) {
      address1
      address2
      city
      country
      customProperties
      customerAccountNumber
      hierarchyId
      industry
      key
      name
      parentKey
      path
      planningCycleId
      zipPostal
      stateProvince
      version
    }
  }
`;

export const UPSERT_GEOGRAPHIC_HIERARCHY = gql`
  mutation UpsertGeographicTerritoryHierarchy(
    $customProperties: AWSJSON
    $geographicTerritoryId: String!
    $hierarchyId: Int
    $name: String!
    $key: String!
    $parentKey: String
    $path: String
    $planningCycleId: Int!
    $version: Int!
  ) {
    upsertGeographicTerritoryHierarchy(
      geographicTerritoryHierarchy: {
        geographicTerritoryId: $geographicTerritoryId
        hierarchyId: $hierarchyId
        planningCycleId: $planningCycleId
        name: $name
        key: $key
        parentKey: $parentKey
        path: $path
        version: $version
        customProperties: $customProperties
      }
    ) {
      hierarchyId
      key
      name
      parentKey
    }
  }
`;

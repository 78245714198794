import gql from 'graphql-tag';

export const GET_ALL_HIERARCHIES = gql`
  query GetAllHierarchies($planningCycleId: Int!, $depth: Int, $searchHierarchyInput: SearchHierarchyInput) {
    getAllHierarchies(
      planningCycleId: $planningCycleId
      depth: $depth
      searchHierarchyInput: $searchHierarchyInput
      version: 1
    ) {
      hierarchies {
        hierarchyId
        key
        name
        parentKey
        path
        mappedPath
        address1
        address2
        city
        country
        industry
        stateProvince
        zipPostal
        customProperties
        hasChildren
      }
      numOfMembers
    }
  }
`;
